
import { Options, Vue } from 'vue-class-component'
@Options({
    name: 'Footer'
})
export default class Footer extends Vue {
    routeSocialMediaBlank(url: string): void {
        window.open(url, '_blank');
    }
}


import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
@Options({
    name: 'MetaverseView',
    components: {
        FullText
    }
})

export default class MetaverseView extends Vue {
    displayMaximizableVideo: boolean = false;
    routeLink(): void {
        window.open("https://forms.wix.com/f/7048671631832514612", "_blank");
    }

    playVideo(): void {
        this.displayMaximizableVideo = true;
    }
}

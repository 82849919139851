
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import { AdvertDto } from '@/models/dto/advertDto';
import Axios from '@/services/axios/axios';
import { catchError, from, map, of } from 'rxjs';
import { ResponseModel } from '@/models/responseModel/responseModel';
import axios, { AxiosResponse } from 'axios';
import {
    userRequestListNotFoundMessage,
    userRequestListLoadedMessage
} from '@/utilities/messages'
import { UserService } from '@/services/userService';
import { Inject } from 'vue-property-decorator';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { UserPagination } from '@/models/entity/userPagination';
import { UserPaginationModel } from '@/models/model/userPaginationModel';
import AdminLawyerConfirm from '@/components/admin/AdminLawyerConfirm.vue';
import { Experience } from '@/models/static/Experience';
import Experiences from '@/data/static/Experiences.json';
@Options({
    name: 'LawyerListView',
    components: {
        FullText,
        AdminLawyerConfirm
    }
})

export default class LawyerListView extends Vue {
    userFilterModels: UserPaginationModel<UserDetailDto> = null;
    userFilter: UserPagination = null;
    selectedUser:UserDetailDto;
    experiences:Experience[] = Experiences;
    selectedLawyer:UserDetailDto = null;
    adminLawyerConfirmModal: boolean = false;
    isConfirmLawyer:boolean = false;
    explanationVisible: boolean = false;

    //lazyLoad
    lazyAdvertDtos: Array<AdvertDto> = [];
    lazyPageIndex: number = 1;
    lazyPageSize: number;
    isLoading: boolean = false;
    isAdded: boolean = true;
    totalCount: number = 0;
    isLoaded:boolean = false;
    imgBaseUrl:string = process.env.VUE_APP_IMG_PROFILE_PATH;

    //pagination
    page: number = 1;
    first: number = 0

    //error
    errorResponseModel: ResponseModel = null;
    userRequestListNotFoundMessage: string = userRequestListNotFoundMessage
    userRequestListLoadedMessage: string = userRequestListLoadedMessage

    //inject
    @Inject('userService') userService!: UserService;
    columns = [
        // { field: 'id', header: 'Id' },
        { field: 'ad_soyad', header: 'Ad Soyad' },
        { field: 'telefon', header: 'Telefon' },
        // { field: 'dogum_tarihi', header: 'Doğum Tarihi' },
        { field: 'baro_sicil_no', header: 'Baro Sicil No' },
        { field: 'kayitli_oldugu_baro', header: 'Kayıt Olduğu Baro' },
        { field: 'uzmanlik_alani', header: 'Uzmanlık Alanı' },
        // { field: 'profil_aciklamasi', header: 'Profil Açıklaması' },
        // { field: 'profil_resmi', header: 'Profil Resmi' },
        // { field: 'kayit_tarihi', header: 'Kayıt Tarihi' },
        { field: 'il', header: 'İl' },
        { field: 'ilce', header: 'İlçe' },
        { field: 'mail', header: 'Mail' },
        // { field: 'tecrube', header: 'Tecrübe' },
    ];

    created(): void {
        this.getUserDtos(1);
    }

    getRouteName(): string {
        return (this.$route.name.toString())
    }

    getDate(date: Date): Date {
        const moment = require('moment');
        let now = moment(date).format('DD MMMM YYYY');
        return (now);
    }

    possiblePage(): boolean {
        if (this.lazyPageIndex <= this.lazyPageSize && this.totalCount > 0)
            return (true);
        return (false);
    }

    onPage(event: any) {
        this.page = event.page;
        this.getUserDtos(event.page + 1)
    }

    experienceText(year:number): string{
        let result:string = null;
        this.experiences.forEach(res=>
        {
            if (Number(res.maxYear) >= year && Number(res.minYear) <= year)
                result = res.text;
        })
        return (result)
    }

    async getUserDtos(index: number) {
        this.userFilter = {
            page: index
        }
        Axios.post<UserPaginationModel<UserDetailDto>>('admin/userlist', this.userFilter)
            .pipe(
                catchError(errorResponse => {
                    this.errorResponseModel = errorResponse;
                    return of(null);
                }),
                map((response: AxiosResponse<UserPaginationModel<UserDetailDto>>) => response)
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.userFilterModels = response.data;
                    this.totalCount = response.data.totalCount === undefined ? 0 : Number(response.data.totalCount);
                    this.lazyPageSize = Math.ceil(Number(this.totalCount) / 20);
                }
            });
    }

    openAdminLawyerConfirmModal(data:UserDetailDto,isConfirmLawyer:boolean): void {
        this.selectedLawyer = data;
        this.isConfirmLawyer = isConfirmLawyer;
        this.adminLawyerConfirmModal = true;
    }
    closeAdminLawyerConfirmModal(): void {
        this.adminLawyerConfirmModal = false;
    }
    setExplanationVisible(data: string) {
        this.explanationVisible = true;
    }
}


import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import PanelHomeCard from '@/components/panel/PanelHomeCard.vue';
import { ActivePageNameService } from '@/services/activePageService';
import { catchError, from, map, of } from 'rxjs';
import { IncomingreqsDto } from '@/models/dto/incomingreqsDto';
import Axios from '@/services/axios';
import { ListResponseModel } from '@/models/listResponseModel';
import IncomingAdvertCard from '@/components/advert/IncomingAdvertCard.vue';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { AdvertIncoming } from '@/models/entity/advertIncoming';
import DataNotFound from '@/components/DataNotFound.vue';
@Options({
    name: 'IncomingAdvertView',
    components: {
        FullText,
        PanelHomeCard,
        IncomingAdvertCard,
        DataNotFound,
    }
})

export default class IncomingAdvertView extends Vue {
    incomingreqsDtos: IncomingreqsDto[] = []
    accountConfirmation: boolean = true
    activePageNameService: ActivePageNameService;

    //error
    errorResponseModel: ResponseModel = null;
    advertIncoming: AdvertIncoming = null;

    created() {
        this.advertIncoming = {
            advertId: Number(this.$route.params.id)
        }
        this.getIncomingreqsDtos();
    }
    getRouteName(): string {
        return (this.$route.name.toString())
    }
    getIncomingreqsDtos() {
        Axios.post<ListResponseModel<IncomingreqsDto>>('advert/incomingreqs', this.advertIncoming)
            .pipe(
                catchError(errorResponse => {
                    this.errorResponseModel = errorResponse;
                    return of();
                })
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.incomingreqsDtos = response.data.data;
                    this.errorResponseModel = null;
                }
            });
    }
    notFoundIncomingreqs(): boolean {
        if (this.errorResponseModel != null && this.errorResponseModel.success == false && this.errorResponseModel.message === 'ilan bulunamadi')
            return (true);
        return (false);
    }
    incomingreqsDtoLoad() {
        // console.log("loaded");
    }
}

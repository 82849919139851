
import { Options, Vue } from 'vue-class-component';
import CityCodes from "@/data/static/CityCodes.json";
import { CityCode } from '@/models/static/CityCode';
import { AdvertAddDto } from '@/models/dto/advertAddDto';
import { JobType } from '@/models/static/JobType';
import JobTypesData from '@/data/static/JobTypesData.json';
import { AdvertFilter } from '@/models/entity/advertFilter';
import { Component, Emit } from 'vue-property-decorator';
import ChipSearch from '../ChipSearch.vue';
@Options({
    name: 'AdvertSerach',
    components:
    {
        ChipSearch
    }
})

export default class AdvertSerach extends Vue {
    cityList: Array<CityCode> = CityCodes.CitySelect;
    stateList: Array<CityCode> = []
    CitySelect: Array<CityCode> = [];
    StateSelect: Array<CityCode> = [];
    selectedCity: CityCode = null;
    selectedState: CityCode = null;
    advertDetail: string;
    advertBudgetMin: number = null;
    advertBudgetMax: number = null;
    jobDate: Date = null;
    jobEndDate: Date = null;
    selectedJobType: JobType = null;
    jobTypes: JobType[] = JobTypesData
    advertAddDto: AdvertAddDto = null;
    advertAddResult: any = null;
    advertFilter: AdvertFilter = null;

    created() {
        let unique: Array<CityCode> = this.cityList.filter((city, index, self) => {
            return self.findIndex(c => c.City === city.City) === index;
        });
        this.CitySelect = unique;
        this.startDefaultSearch();
        this.getLocalStorageAdvertFilter();
        this.getAdvertFilter();
    }

    getLocalStorageAdvertFilter() {
        let adverFilterString = localStorage.getItem('saveAdvertFilterData');
        let localStorageAdvertFilter: AdvertFilter = JSON.parse(adverFilterString);
        this.advertFilter = localStorageAdvertFilter;
    }

    citySelected() {
        this.stateList.splice(0);
        this.StateSelect.splice(0);
        this.stateList = this.cityList.filter(
            (element: any) => element.City === this.selectedCity.City
        );
        this.stateList.forEach((element: any) => {
            this.StateSelect.push(element);
        });
    }
    stateSelected() {
        this.stateList = this.cityList.filter(
            (element: any) => element.State === this.selectedState.State
        );
    }

    @Emit('advert-search')
    search() {
        this.$emit('advert-search');
        this.advertFilter = {}

        this.advertFilter.page = 1;
        this.advertFilter.butce_start = this.advertBudgetMin == null ? "" : String(this.advertBudgetMin);
        this.advertFilter.butce_end = this.advertBudgetMax == null ? "" : String(this.advertBudgetMax);
        this.advertFilter.il = this.selectedCity == null ? "" : this.selectedCity.City;
        this.advertFilter.ilce = this.selectedState == null ? "" : this.selectedState.State;
        this.advertFilter.is_turu = this.selectedJobType == null ? "" : this.selectedJobType.text

        localStorage.setItem('saveAdvertFilterData', JSON.stringify(this.advertFilter));
    }

    @Emit('advert-clear')
    clear() {
        this.$emit('advert-clear');
        this.defaultAfvertFilter();
    }
    defaultAfvertFilter() {
        localStorage.removeItem('saveAdvertFilterData')
        this.advertFilter = {}
        this.advertFilter.page = 1;
        this.advertFilter.butce_start = null;
        this.advertFilter.butce_end = null;
        this.advertFilter.il = null;
        this.advertFilter.ilce = null;
        this.advertFilter.is_turu = null;

        this.selectedCity = null;
        this.selectedState = null;
        this.selectedJobType = null;
        this.advertBudgetMin = null;
        this.advertBudgetMax = null;
        this.search();
    }

    getAdvertFilter() {
        let adverFilterString = localStorage.getItem('saveAdvertFilterData');
        let localStorageAdvertFilter: AdvertFilter = JSON.parse(adverFilterString);

        this.selectedCity = this.cityList.find(s => s.City == localStorageAdvertFilter.il);
        this.selectedState = this.cityList.find(s => s.State == localStorageAdvertFilter.ilce);
        if (this.selectedCity != null || this.selectedCity !== undefined)
            this.citySelected();
        this.selectedJobType = this.jobTypes.find(s => s.text == localStorageAdvertFilter.is_turu);
        this.advertBudgetMin = Number(localStorageAdvertFilter.butce_start) == 0 ? null : Number(localStorageAdvertFilter.butce_start);
        this.advertBudgetMax = Number(localStorageAdvertFilter.butce_end) == 0 ? null : Number(localStorageAdvertFilter.butce_end);
    }
    startDefaultSearch() {
        let saveUserFilterDataLocalStorage = localStorage.getItem('saveAdvertFilterData');
        if (saveUserFilterDataLocalStorage == null || saveUserFilterDataLocalStorage === undefined)
            this.defaultAfvertFilter()
    }
}

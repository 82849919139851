import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        (!_ctx.pageNotFoundVisibility)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_Header)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_router_view, { route: _ctx.currentRoute }, null, 8, ["route"])
        ]),
        (!_ctx.pageNotFoundVisibility)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Footer)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}

import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'HomeCard',
    props: ['textx', 'title', 'icon']
})
export default class HomeCard extends Vue {
    textx!: string;
    title!: string;
    icon!: string;
    
    getImgUrl(path: string) {
        var images = require.context('../assets/icons', false, /\.svg$/)
        return images('./' + path + ".svg")
    }
}

import axios from "axios";
import { catchError, from, map, of } from "rxjs";

export function getAssetsImage(name: string): string {
    const images = require.context("@/assets/images", false);
    return images('./' + name);
}

export function getAssetsIcon(name: string): string {
    const images = require.context("@/assets/icons", false);
    return images('./' + name);
}

export function getUrlImageFromAxios(baseUrl: string, path: string) {
    let imgUrl = baseUrl + path;

    return from(axios.get(imgUrl, {
        responseType: 'arraybuffer'
    })).pipe(
        map(response => URL.createObjectURL(new Blob([response.data]))),
        catchError(error => {
            return of(null);
        })
    );
}
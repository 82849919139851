
import { Options, Vue } from 'vue-class-component';
import { TimeLine } from '@/models/static/TimeLine';
import TimeLineData from '@/data/static/TimeLineData.json';
import FullText from '@/components/FullText.vue';
import { getAssetsImage } from '@/utilities/getImg';
@Options({
  name: 'HowWorkView',
  components:
  {
    FullText
  }
})

export default class HowWorkView extends Vue {
  events: TimeLine[] = TimeLineData;

  getImgUrl(name: string): string {
    return (getAssetsImage(name));
  }
}


import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec261030"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-3 mt-3 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      label: "Facebook ile giriş yap",
      type: "button",
      icon: "eva eva-facebook",
      iconPos: "left",
      class: "w-full p-button-outlined p-button-raidus"
    }),
    _createVNode(_component_Toast)
  ]))
}
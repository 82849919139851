
import { Options, Vue } from 'vue-class-component'
import PanelHeader from '@/components/panel/PanelHeader.vue'
import PanelSideBar from '@/components/panel/PanelSideBar.vue'
import NotFoundView from '@/views/NotFoundView.vue'
@Options({
    name: 'Panel',
    components: {
        PanelHeader,
        PanelSideBar,
        NotFoundView,
    }
})

export default class Panel extends Vue {

    routeName!: string;
    pageNotFoundVisibility: boolean = false;
    sizeWidth: number = window.innerWidth;
    sizeHeight: number = window.innerHeight;
    routerHeight:number = this.sizeHeight - 65.6;

    panelSideBarVisible:boolean = true;

    get currentRoute() {
        this.getRouteName();
        return this.$route;
    }

    getRouteName(): void {
        console.log(this.$route.name);
        if (this.$route.name === "bad-not-found" || this.$route.name === "not-found")
            this.pageNotFoundVisibility = true
        else
            this.pageNotFoundVisibility = false
    }

    mounted(): void {
        window.addEventListener('resize', this.handleResize);
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(): void {
        this.sizeWidth = window.innerWidth;
        this.sizeHeight = window.innerHeight;
        this.isVisiblePanelSidebar();
        // console.log('Window was resized');
    }

    isVisiblePanelSidebar()
    {
        this.sizeWidth < this.sizeHeight ? this.panelSideBarVisible = false : this.panelSideBarVisible = true;
    }
}

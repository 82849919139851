
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import Axios from '@/services/axios/axios';
import { catchError, map, of } from 'rxjs';
import { ResponseModel } from '@/models/responseModel/responseModel';
import {
    blogRequestListLoadedMessage,
    blogRequestListNotFoundMessage
} from '@/utilities/messages'
import { UserService } from '@/services/userService';
import { Inject, Ref } from 'vue-property-decorator';
import { BlogFilter } from '@/models/entity/blogFilter';
import { Blog } from '@/models/dto/blog';
import { BlogFilterModel } from '@/models/model/blogFiletModel';
import AdminBlogConfirm from '@/components/admin/AdminBlogConfirm.vue';
import * as dotenv from 'dotenv'
import { AxiosResponse } from 'axios';
@Options({
    name: 'BlogListView',
    components: {
        FullText,
        AdminBlogConfirm
    }
})

export default class BlogListView extends Vue {
    blogFilterModels: BlogFilterModel<Blog> = null;
    blogFilter: BlogFilter = null;
    selectedBlog: Blog = null;
    adminBlogConfirmModal: boolean = false;
    isConfirmAdvert: boolean = false;
    explanationVisible: boolean = false;

    //lazyLoad
    lazyAdvertDtos: Array<Blog> = [];
    lazyPageIndex: number = 1;
    lazyPageSize: number;
    isLoading: boolean = false;
    isAdded: boolean = true;
    totalCount: number = 0;
    imgBaseUrl: string = process.env.VUE_APP_IMG_BLOG_PATH;

    //pagination
    page: number = 1;
    first: number = 0

    //error
    errorResponseModel: ResponseModel = null;
    blogRequestListNotFoundMessage: string = blogRequestListNotFoundMessage
    blogRequestListLoadedMessage: string = blogRequestListLoadedMessage

    //inject
    @Inject('userService') userService!: UserService;
    columns = [
        // { field: 'id', header: 'Id' },
        { field: 'baslik', header: 'Başlık' },
        { field: 'url', header: 'Url' },
        // { field: 'resim', header: 'Resim' },
        // { field: 'body', header: 'Id' },
        { field: 'tag', header: 'Tag' },
        // { field: 'tarih', header: 'Tarih' },
        // { field: 'aciklama', header: 'Açıklama' },
    ];

    created() {
        this.getAdverts(1);
    }

    getDate(date: Date): Date {
        const moment = require('moment');
        let now = moment(date).format('DD MMMM YYYY');
        return (now);
    }

    getRouteName(): string {
        return (this.$route.name.toString())
    }

    possiblePage(): boolean {
        if (this.lazyPageIndex <= this.lazyPageSize && this.totalCount > 0)
            return (true);
        return (false);
    }

    onPage(event: any) {
        this.page = event.page;
        this.getAdverts(event.page + 1)
    }

    async getAdverts(index: number) {
        this.blogFilter =
        {
            page: index,
            tag: ""
        }
        Axios.post<BlogFilterModel<Blog>>("blog/filter", this.blogFilter)
            .pipe(
                catchError(errorResponse => {
                    this.errorResponseModel = errorResponse;
                    return of();
                }),
                map((response: AxiosResponse<BlogFilterModel<Blog>>) => response)
            )
            .subscribe(response => {
                if (response.data.success == true)
                    this.blogFilterModels = response.data;
            });
    }

    openAdminBlogConfirmModal(data: Blog): void {
        this.selectedBlog = data;
        this.adminBlogConfirmModal = true;
    }
    closeAdminBlogConfirmModal(): void {
        this.adminBlogConfirmModal = false;
    }

    routeBlogAdd(): void {
        this.$router.push({ path: "/admin/blog/ekle" })
    }
    routeBlogEdit(data: Blog) {
        this.$router.push({ path: "/admin/blog/guncelle/" + data.url });
    }
    setExplanationVisible(data: string) {
        this.explanationVisible = true;
    }
}


import { AdvertDto } from '@/models/dto/advertDto';
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import CityCodes from "@/data/static/CityCodes.json";
import { CityCode } from '@/models/static/CityCode';
import Axios from '@/services/axios';
import { AdvertAddDto } from '@/models/dto/advertAddDto';
import moment from 'moment';
import { catchError, map, of } from 'rxjs';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { AdvertAddResult } from '@/models/entity/advertAddResult';
import { TurkishChar } from '@/utilities/TurkishChar';
import { JobType } from '@/models/static/JobType';
import JobTypesData from '@/data/static/JobTypesData.json';
import { InsertModel } from '@/models/model/insertModel';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { Ref } from 'vue';
import { Blog } from '@/models/dto/blog';
import { BlogAddDto } from '@/models/dto/blogAddDto';
import { blogHeaderMessage, blogRequestErrorAddMessage, blogRequestErrorUpdateMessage, blogRequestSuccessAddMessage, blogRequestSuccessUpdateMessage, profileHeaderMessage, profileRequestSuccessImageMessage } from '@/utilities/messages';

const MAX_FILE_SIZE = 1024 * 1024 * 5;
@Options({
    name: 'BlogEditView',
    components:
    {
        FullText,
    }
})
export default class BlogEditView extends Vue {
    formData: FormData;
    blog: Blog = null;
    titleValue: string = null;
    tagValue: string = null;
    explanationValue: string = null;
    editorValue: string = null;
    file: File | null = null;

    //error
    fileError: string = '';
    titleValueError: string = '';
    tagValueError: string = '';
    editorValueError: string = '';
    explanationValueError: string = '';

    //file
    maxFileSize = MAX_FILE_SIZE;
    fileStatusText: string = "Blog Fotoğrafı Seç";
    fileStatusColor: string = "";

    blogAddDto: BlogAddDto = null;
    blogResult: SingleResponseModel<InsertModel> = null;

    //disabled
    sendDisabled: boolean;
    isBlogCreate: boolean = false;
    isBlogEdit: boolean = false;
    blogUrl: string = null;

    created() {
        if (this.$route.name === "Blog Ekle") {
            this.isBlogCreate = true;
        }
        else if (this.$route.name === "Blog Düzenle") {
            this.isBlogEdit = true;
            this.blogUrl = String(this.$route.params.url);
            this.getBlog(this.blogUrl);
        }
    }
    getRouteName(): string {
        return (this.$route.name.toString())
    }

    addBlog() {
        const formData = new FormData();
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const extension = this.file.name.split('.').pop()?.toLowerCase();
        let filex!: File;
        if (extension && allowedExtensions.includes(extension))
            filex = new File([this.file], String(this.file.name), { type: `image/${extension}` });
        this.blogAddDto = {
            baslik: this.titleValue,
            aciklama: this.explanationValue,
            body: this.editorValue,
            tag: this.tagValue,
            blog_image: filex
        }
        formData.append('blog_image', filex);
        Object.entries(this.blogAddDto).forEach(([key, value]) => {
            if (key !== 'blog_image') {
                formData.append(key, value);
            }
        });
        Axios.post<SingleResponseModel<InsertModel>>("blog/add", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .pipe(
                catchError((errorResponse: ResponseModel) => {
                    if (errorResponse.success == false)
                        this.$toast.add({ severity: 'error', summary: blogHeaderMessage, detail:blogRequestErrorAddMessage , life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.blogResult = response.data;
                    this.$toast.add({ severity: 'success', summary: blogHeaderMessage, detail:blogRequestSuccessAddMessage , life: 4000 });
                    this.sendDisabled = false;
                }
            });
        let intervalId = setInterval(() => {
            if (this.sendDisabled == false) {
                this.resetForm();
                clearInterval(intervalId);
            }
        }, 1000);
    }

    updateBlog() {
        this.blogAddDto = {
            baslik: this.titleValue,
            aciklama: this.explanationValue,
            body: this.editorValue,
            tag: this.tagValue,
        }
        Axios.put<SingleResponseModel<InsertModel>>("blog/updateblog/" + this.blog.id, this.blogAddDto)
            .pipe(
                catchError((errorResponse: ResponseModel) => {
                    if (errorResponse.success == false)
                        this.$toast.add({ severity: 'error', summary: blogHeaderMessage, detail: blogRequestErrorUpdateMessage, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.blogResult = response.data;
                    this.$toast.add({ severity: 'success', summary: blogHeaderMessage, detail: blogRequestSuccessUpdateMessage, life: 4000 });
                    this.blogResult.success = false;
                    this.sendDisabled = false;
                }
            });
        let intervalId = setInterval(() => {
            if (this.blogResult.success == false) {
                this.resetForm();
                clearInterval(intervalId);
            }
        }, 1000);
    }

    getBlog(url: string) {
        Axios.get<SingleResponseModel<Blog>>('blog/getbyurl/' + url)
            .pipe(
                catchError(error => {
                    return of();
                })
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.blog = response.data.data;
                    this.titleValue = this.blog.baslik;
                    this.tagValue = this.blog.tag;
                    this.explanationValue = this.blog.aciklama;
                    this.editorValue = this.blog.body;
                }
            })
    }

    resetForm() {
        let formRef = this.$refs.form as HTMLFormElement;
        if (formRef) {
            formRef.reset();
            this.editorValue = null;
            this.titleValue = null;
            this.tagValue = null;
            this.file = null;
            delete this.file;
        }
    }

    onSubmit() {
        if (!this.validateForm())
            return;
        if (this.isBlogCreate)
            this.addBlog();
        else if (this.isBlogEdit)
            this.updateBlog();
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.titleValue) {
            this.titleValueError = 'Başlık boş!';
            isValid = false;
        }
        else if (this.titleValue.length < 6) {
            this.titleValueError = 'Başlık alanı en az 5 karakter olmalı!';
            isValid = false;
        }
        else if (this.titleValue.length > 100) {
            this.titleValueError = 'Başlık alanı en fazla 100 karakter olmalı!';
            isValid = false;
        } else {
            this.titleValueError = '';
        }

        if (!this.tagValue) {
            this.tagValueError = 'Tag boş!';
            isValid = false;
        }
        else if (this.tagValue.length < 6) {
            this.tagValueError = 'Tag alanı en az 5 karakter olmalı!';
            isValid = false;
        }
        else if (this.tagValue.length > 50) {
            this.tagValueError = 'Tag alanı en fazla 50 karakter olmalı!';
            isValid = false;
        } else {
            this.tagValueError = '';
        }

        if (!this.explanationValue) {
            this.explanationValueError = 'Açıklama boş!';
            isValid = false;
        }
        else if (this.titleValue.length < 6) {
            this.explanationValueError = 'Açıklama alanı en az 5 karakter olmalı!';
            isValid = false;
        }
        else if (this.titleValue.length > 250) {
            this.explanationValueError = 'Açıklama alanı en fazla 250 karakter olmalı!';
            isValid = false;
        } else {
            this.explanationValueError = '';
        }

        if (!this.editorValue) {
            this.editorValueError = 'Editör Alanı boş!';
            isValid = false;
        }
        else if (this.titleValue.length < 6) {
            this.editorValueError = 'Editör alanı en az 5 karakter olmalı!';
            isValid = false;
        }
        else if (this.titleValue.length > 60000) {
            this.editorValueError = 'Editör alanı en fazla 60000 karakter olmalı!';
            isValid = false;
        } else {
            this.editorValueError = '';
        }

        if (!this.file) {
            this.fileError = 'Resim boş!';
            isValid = false;
        } else {
            this.fileError = '';
        }
        return (isValid);
    }

    onFileSelected(event: Event) {
        const target = event.target as HTMLInputElement;
        let file: File = (target.files as FileList)[0];
        if (file && file.size <= this.maxFileSize) {
            this.file = file;
            this.fileStatusText = "Hazır";
            this.fileStatusColor = "background-color: #29CC6A !important;";
        } else {
            this.fileStatusText = "Onaylanmadı";
            this.fileStatusColor = "background-color: #FC5555 !important;";
            this.$toast.add({
                severity: 'error',
                summary: 'Blog Fotoğrafı',
                detail: 'Boyutu ' + `${this.maxFileSize / 1024 / 1024} MB'dan fazla olamaz.`,
                life: 3000
            });
            console.error(`File size should be less than ${this.maxFileSize / 1024 / 1024} MB`);
        }
    }

    uploadFile() {
        if (!this.file) {
            (this.$refs.fileInput as HTMLInputElement).click();
            return;
        }
        if (!this.isBlogEdit)
            return;
        const formData = new FormData();
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const extension = this.file.name.split('.').pop()?.toLowerCase();
        let file!: File;
        if (extension && allowedExtensions.includes(extension))
            file = new File([this.file], String(this.file.name), { type: `image/${extension}` });
        formData.append('blog_image', file);
        formData.append('id', String(this.blog.id));
        Axios.put("blog/updateimage", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .pipe(
                catchError((error: ResponseModel) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: profileHeaderMessage,
                        detail: 'Blog Resim Başarısız',
                        life: 3000
                    });
                    console.log("error : " + error.message + " " + error.success);
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.$toast.add({ severity: 'success', summary: profileHeaderMessage, detail: profileRequestSuccessImageMessage, life: 4000 });
                    this.fileStatusText = "Blog Resmi Yüklendi";
                    this.fileStatusColor = "";
                    delete this.file;
                }
            });
    }
}

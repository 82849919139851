import jwt_decode from "jwt-decode";
import {Token} from "@/models/entity/token";

export class JwtControllerService {
    isActive(getToken: string): boolean {
        const expirationDate = this.getTokenExpirationDate(getToken);
        return expirationDate !== null && expirationDate.valueOf() > new Date().valueOf();
    }

    isTokenValid(token: string): boolean {
        return jwtControllerService.isActive(token);
    }

    decodeToken(getToken: string) {
        return jwt_decode(getToken);
    }

    getTokenExpirationDate(getToken: string) {
        let decoded:any = jwt_decode(getToken);
        if (!decoded.exp) {
          return null;
        }
        
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }
}

export const jwtControllerService = new JwtControllerService();
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container-lg" }
const _hoisted_2 = {
  key: 0,
  class: "container-lg"
}
const _hoisted_3 = {
  key: 1,
  class: "container-lg"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 1,
  class: "row"
}
const _hoisted_8 = { class: "mb-5 col-md-12 text-center" }
const _hoisted_9 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_10 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_text = _resolveComponent("full-text")!
  const _component_panel_home_card = _resolveComponent("panel-home-card")!
  const _component_advert_card = _resolveComponent("advert-card")!
  const _component_DeferredContent = _resolveComponent("DeferredContent")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_data_not_found = _resolveComponent("data-not-found")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_text, {
        title: _ctx.getRouteName(),
        titleSize: 24
      }, null, 8, ["title"])
    ]),
    (_ctx.accountConfirmation == false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_panel_home_card, {
            subTitle: "Hesabın Onay Sürecinde",
            endText: "Baro kayıt bilgilerin kontrol aşamasında, onay sonrası platformumuzu kullanabilirsin :)",
            link: _ctx.linkAccountNotConf,
            bgColor: "#1E578D"
          }, null, 8, ["link"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.accountConfirmation == true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_panel_home_card, {
              subTitle: "Avukatweb’e Hoşgeldin",
              endText: "Tüm hukuki taleplerinize yönelik size sınırsız bir evren sunan  Avukatweb’e hoşgeldiniz. ",
              link: _ctx.linkAccountConf,
              bgColor: "#0070D9"
            }, null, 8, ["link"])
          ]),
          (_ctx.notFoundFilterAdverts() == false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_DeferredContent, { onLoad: _ctx.advertDtoLoad }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lazyAdvertDtos, (advert) => {
                        return (_openBlock(), _createBlock(_component_advert_card, {
                          key: advert.id,
                          advertDto: advert
                        }, null, 8, ["advertDto"]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }, 8, ["onLoad"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.possiblePage())
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (!_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("i", {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.lazyLoadAdvert())),
                          class: "eva eva-loader-outline m-5",
                          style: {"font-size":"2rem"}
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_ProgressSpinner)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.notFoundFilterAdverts() == true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_data_not_found, { infoText: "Malesef ilan\n                                            bulamadık" })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
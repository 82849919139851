
import { AdvertDto } from '@/models/dto/advertDto';
import { ref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import AdvertEdit from '@/components/advert/AdvertEdit.vue';
import AdvertDetail from './AdvertDetail.vue';
import AdvertConfirm from './AdvertConfirm.vue';
import { AuthService } from '@/services/authService';
import { Emit, Inject } from 'vue-property-decorator';
import LawyerProfileDetail from '../lawyer/LavyerProfileDetail.vue';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { UserService } from '@/services/userService';
import { abbreviateNumber } from '@/utilities/abbreviateNumber';

@Options({
    name: 'AdvertCard',
    props: ['advertDto'],
    components:
    {
        AdvertEdit,
        AdvertDetail,
        AdvertConfirm,
        LawyerProfileDetail,
    }
})
export default class AdvertCard extends Vue {
    advertDto!: AdvertDto
    userDetailDto: UserDetailDto = null
    advertPossibleTextColor: string
    advertPossibleBgColor: string
    advertPossibleText: string = "null"
    advertEditModal: boolean = false;
    advertConfirmModal: boolean = false;
    advertDetailModal: boolean = false;
    lawyerProfileDetailModal: boolean = false;
    advertCofirm!: number;
    advertEmploye!: number;
    jobTakerId: number;
    currentId: number;

    //services
    @Inject('authService') authService!: AuthService;
    @Inject('userService') userService!: UserService;

    mounted(): void {
        this.jobTakerId = this.advertDto.isi_alan_id
        this.advertCofirm = this.advertDto.ilan_onay
        this.advertEmploye = this.advertDto.is_verme
        this.advertPossibleText = this.getAdvertPossible(this.advertDto.isi_alan_id)
    }

    created(): void {
        this.getUserDetail();
    }

    getRouteName(): string {
        return (this.$route.name.toString())
    }

    advertCurrentVisible(): boolean {
        this.currentId = this.authService.getCurrentUserId()
        // if (currentId == this.advertDto.ilan_veren)
        //     return (false);
        return (true);
    }

    getDate(date: Date): Date {
        const moment = require('moment');
        let now = moment(date).format('DD MM YYYY');
        return (now);
    }

    getUserDetail() {
        let userId = this.advertDto.isi_alan_id;
        this.userService.getUser(userId)
            .subscribe(response => {
                this.userDetailDto = response.data.data;
            })
    }

    getAdvertPossible(jobTakerId: number): string {
        if (jobTakerId == null) {
            this.advertPossibleBgColor = "rgba(41, 204, 106, 0.1);";
            this.advertPossibleTextColor = "#29CC6A";
            return ("Başvuruya Açık");
        }
        else if (jobTakerId != null) {
            this.advertPossibleBgColor = "#E6F5FF;";
            this.advertPossibleTextColor = "#0099FF";
            return ("İlan Yapılıyor");
        }
    }
    openAdvertEditModal(): void {
        this.advertEditModal = true;
    }
    openAdvertDetailModal(): void {
        this.advertDetailModal = true;
    }
    closeAdvertDetailModal(): void {
        this.advertDetailModal = true;
    }
    openAdvertConfirmModal(): void {
        this.advertConfirmModal = true;
    }
    closeAdvertConfirmModal(): void {
        this.advertConfirmModal = false;
    }
    openLawyerDetailModal(): void {
        this.lawyerProfileDetailModal = true;
    }
    incomingAdverts(): void {
        this.$router.push({ path: '/gelenistekler/' + this.advertDto.id })
    }

    abbreviateNumberx(butce: number):string {
        return (abbreviateNumber(butce));
    }
}

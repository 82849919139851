import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_Text = _resolveComponent("full-Text")!
  const _component_mobile_app = _resolveComponent("mobile-app")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_Text, {
        title: "Tevkil",
        textx: "Avukatweb üyelerine sağladığı olanaklar dahilinde avukatların, Avukatlık Kanunu’na uygun şekilde birbirlerine tevkil vermek suretiyle adliye veya farklı kurumlarda gündelik hukuki ihtiyaçlarını karşılamalarına kolaylık sağlar. Platform olarak Avukatweb, hiçbir şekilde avukatların birbiri ile olan tevkil koşullarına (ücret, işin niteliği vb.) karışmamakta, sadece avukatların Avukatweb aracılığı ile işleri konusunda birbirlerini hızlı ve kolay şekilde bulmalarına yardımcı olmaktadır."
      })
    ]),
    _createVNode(_component_mobile_app)
  ]))
}
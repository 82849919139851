
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import { AdvertDto } from '@/models/dto/advertDto';
import Axios from '@/services/axios/axios';
import { AdvertPaginationModel } from '@/models/model/advertPaginationModel';
import { AdvertPagination } from '@/models/entity/advertPagination';
import { catchError, map, of } from 'rxjs';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { AxiosResponse } from 'axios';
import {
    advertRequestListNotFoundMessage,
    advertRequestListLoadedMessage
} from '@/utilities/messages'
import { UserService } from '@/services/userService';
import { Inject } from 'vue-property-decorator';
import AdminAdvertConfirm from '@/components/admin/AdminAdvertConfirm.vue';
import { AdvertListDto } from '@/models/dto/advertListDto';
@Options({
    name: 'AdvertListView',
    components: {
        FullText,
        AdminAdvertConfirm
    }
})

export default class AdvertListView extends Vue {
    advertFilterModels: AdvertPaginationModel<AdvertListDto> = null;
    advertFilter: AdvertPagination = null;
    selectedAdvert:AdvertListDto = null;
    adminAdvertConfirmModal: boolean = false;
    isConfirmAdvert:boolean = false;

    //lazyLoad
    lazyAdvertDtos: Array<AdvertListDto> = [];
    lazyPageIndex: number = 1;
    lazyPageSize: number;
    isLoading: boolean = false;
    isAdded: boolean = true;
    totalCount: number = 0;

    //pagination
    page: number = 1;
    first: number = 0

    //error
    errorResponseModel: ResponseModel = null;
    advertRequestListNotFoundMessage: string = advertRequestListNotFoundMessage
    advertRequestListLoadedMessage: string = advertRequestListLoadedMessage

    //inject
    @Inject('userService') userService!: UserService;
    columns = [
        // { field: 'id', header: 'Id' },
        { field: 'is_turu', header: 'İş Türü' },//*
        { field: 'detay', header: 'Detay' },//*
        { field: 'butce', header: 'Bütçe' },//*
        { field: 'il', header: 'İl' },//*
        { field: 'ilce', header: 'İlçe' },//*
        // { field: 'tarih', header: 'Tarih' },//*
        // { field: 'ilan_veren', header: 'İlan Veren Id' },//*
        // { field: 'ilan_onay', header: 'İlan Onay' },
        // { field: 'is_verme', header: 'İs Verme' },
        // { field: 'source', header: 'Kaynak' },
    ];

    created(): void {
        this.getAdverts(1);
    }

    getDate(date: Date): Date {
        const moment = require('moment');
        let now = moment(date).format('DD MMMM YYYY');
        return (now);
    }

    getRouteName(): string {
        return (this.$route.name.toString())
    }

    possiblePage(): boolean {
        if (this.lazyPageIndex <= this.lazyPageSize && this.totalCount > 0)
            return (true);
        return (false);
    }

    onPage(event: any) {
        this.page = event.page;
        this.getAdverts(event.page + 1)
    }

    async getAdverts(index: number) {
        this.advertFilter = {
            page: index
        }
        Axios.post<AdvertPaginationModel<AdvertListDto>>('admin/advertlist', this.advertFilter)
            .pipe(
                catchError(errorResponse => {
                    this.errorResponseModel = errorResponse;
                    return of(null);
                }),
                map((response: AxiosResponse<AdvertPaginationModel<AdvertListDto>>) => response)
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.advertFilterModels = response.data;
                    this.totalCount = response.data.totalCount === undefined ? 0 : Number(response.data.totalCount);
                    this.lazyPageSize = Math.ceil(Number(this.totalCount) / 20);
                }
            });
    }

    openAdminAdvertConfirmModal(data:AdvertListDto,isConfirmAdvert:boolean): void {
        this.selectedAdvert = data;
        this.isConfirmAdvert = isConfirmAdvert;
        this.adminAdvertConfirmModal = true;

    }
    closeAdminAdvertConfirmModal(): void {
        this.adminAdvertConfirmModal = false;
    }
}

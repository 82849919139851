
import { Options, Vue } from 'vue-class-component';
import { ActivePageNameService } from '@/services/activePageService'
import { LocalStorageService, provideLocalStorageService, useLocalStorageService } from '@/services/localStorageService';
import { AuthService } from '@/services/authService'
import { Component, Inject, Ref } from 'vue-property-decorator';
import { useRouter } from 'vue-router';
import { UserService } from '@/services/userService';
@Options({
    name: 'PanelSideBar',
})

export default class PanelSideBar extends Vue {
    localAuth: boolean = false;
    role!: string;
    roleCheckClass!: string;
    router = useRouter();
    menuItems: RouteInfo[] = [];
    menuChildItems: any[] = [];
    menuChildedItems!: RouteChilInfo[];
    menuChildVisibleClass!: string;
    menuChildVisibleBoolean: boolean = true;
    breakMathIconName: string = "eva-arrow-ios-downward-outline";
    visibleSidebar1: any;
    activePageNameService: ActivePageNameService;
    localStorageService!: LocalStorageService
    iconStyle:string = "";
    accountConfirmation:boolean = null;

    //userActive
    userId: number;

    //services
    @Inject('authService') authService!: AuthService;
    @Inject('userService') userService!: UserService;

    mounted() {
        // this.activePageNameService.pageName = "Home";
    }

    created() {
        this.getMenu();
        // this.routeLocalStorageGetColor();
        this.userId = this.authService.getCurrentUserId();
        this.accountConfirmation = this.userService.isUserConfirmed(this.userId, this.handleUserConfirmed)
    }

    handleUserConfirmed(isConfirm: boolean) {
        this.accountConfirmation = isConfirm;
    }

    getMenu() {

        // this.role = authService.getCurrentRole();
        this.authService.roleName.subscribe(response => {
            this.role = response;
            console.log(response);
        })
        //console.log(this.role);

        // if (this.role != null && this.role.indexOf("developer") > -1) {
        this.roleCheckClass = "";
        ROUTES.forEach(element => {
            if (element.chilName === "claim") {
                element.class = ""
            }
        });
        // } else {
        //     ROUTES.forEach(element => {
        //         if (element.chilName === "claim") {
        //             element.class = "d-none"
        //             //console.log("yok")
        //         }

        //     });
        // }

        this.authService.isAuth.subscribe(response => {
            if (response) {
                this.localAuth = response
                //console.log(response)
            }
        })
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.menuChildItems = ROUTESCHIL.filter(menuchilItem => menuchilItem);
    }

    getChildItems(name: string) {
        this.menuChildedItems = this.menuChildItems.filter(p => p.name == name)
        console.log(this.menuChildedItems)
    }
    isNameIsChildName(name: string, nameChild: string) {
        if (name === nameChild) {
            return true;
        }
        return false
    }
    setActivePageName(object: any) {
        // this.activePageNameService = object.title;
        // this.activePageNameService.saveActivePage(object.title.toString());
        this.menuChildVisibleBoolean = true;
        this.menuChildVisibleClass = "";
    }
    setActivePageNameForMenuItem(object: any) {
        if (ROUTESCHIL.filter(filtre => filtre.name == object.chilName).length == 0) {
            // this.activePageNameService.pageName = object.title.toString();
            // this.activePageNameService.saveActivePage('object.title.toString()');
        }
    }
    routeForOne(object: RouteInfo) {
        if (ROUTESCHIL.filter(filtre => filtre.name == object.chilName).length == 0) {
            this.router.push({ path: object.path })
        }
    }
    childRouteForOne(object: RouteChilInfo) {
        if (this.accountConfirmation == false)
            return ;
        // if (ROUTESCHIL.filter(filtre => filtre.name == object.chilName).length == 0) {
            this.router.push({ path: object.path })
        // }
    }
    childVisible(object: any) {
        if (this.accountConfirmation == false)
            return ;
        this.menuChildItems.forEach(element => {
            if (element.name == object.chilName) {
                if (element.class === "") {
                    element.class = "d-none"
                    object.chilIcon = "eva-arrow-ios-forward-outline"
                } else if (element.class === "d-none") {
                    element.class = ""
                    object.chilIcon = "eva-arrow-ios-downward-outline"
                }
            } else if (element.name != object.chilName) {
                //element.class = ""
            }
        });
    }
    isBreak(object: any): boolean {
        if (ROUTESCHIL.filter(filtre => filtre.name == object.chilName).length != 0) {
            return true;
        }
        return false;
    }
    routeChangeColor(object: any) {
        if (this.menuChildItems.filter(filtre => filtre.name == object.chilName).length == 0) {
            this.menuItems.forEach(element => {
                if (element.title === object.title) {
                    object.style = "color:#0070D9!important;";
                } else if (element.title !== object.title) {
                    // object.style = "color:#97A2B0;";
                }   
            });
        }
        if (this.menuChildItems.filter(filtre => filtre.name == object.chilName).length == 0) {
            this.menuChildItems.forEach(element => {
                element.style = "";
                
            });
        }
    }
    routeChangeColorChild(object: any, chilObject: any) {
        if (this.accountConfirmation == false)
            return ;
        if (this.menuChildItems.filter(filtre => filtre.name == object.chilName).length != 0) {
            this.menuChildItems.forEach(element => {
                if (element.name === object.chilName && element.title === chilObject.title) {
                    element.style = "color:#e9ecef;font-weight: bold;"
                    // this.routeLocalStorageSaveColor();
                } else if (element.title !== chilObject.title || element.name !== object.chilName) {
                    element.style = ""
                }
            });
        }
        this.menuItems.forEach(element => {
            element.style = ""
        });
    }
    // routeLocalStorageSaveColor() {
    //     let menuChildVisibility: string[] = [];
    //     this.menuChildItems.forEach(element => {
    //         menuChildVisibility.push(element.style)
    //     });
    //     localStorage.setItem("menuChilVisibilityColor", menuChildVisibility.toString());
    // }
    // routeLocalStorageGetColor() {
    //     let menuChildVisibilityGet: any = localStorage.getItem("menuChilVisibilityColor");
    //     let menuChildVisibility:string[] = menuChildVisibilityGet.split(",")
    //     for (let index = 0; index < menuChildVisibility.length; index++) {
    //       this.menuChildItems[index].style=menuChildVisibility
    //     }
    // }
}
export const ROUTES: RouteInfo[] = [
    { path: '/', chilName: "users", chilIcon: "", title: 'Anasayfa', icon: 'eva-home-outline', class: '', style: '' },
    { path: 'none', title: 'Hizmet Al', chilName: "get_service", chilIcon: "eva-arrow-ios-downward", icon: 'eva-briefcase-outline', class: '', style: '' },
    { path: 'none', title: 'Hizmet Ver', chilName: "set_service", chilIcon: "eva-arrow-ios-downward", icon: 'eva-globe-outline', class: '', style: '' },
    { path: '/avukatara', chilName: "", chilIcon: "", title: 'Avukat Ara', icon: 'eva-search-outline', class: '', style: '' },
    { path: '/ayarlar', chilName: "", chilIcon: "", title: 'Ayarlar', icon: 'eva-settings-outline', class: '', style: '' },
    //{ path: 'none', title: 'Kategoriler', chilName: "category", icon: 'category', class: '' },
    // { path: '/table', title: 'Table List', chilName: "car1", icon: 'assistant', class: '' },
    // { path: '/typography', title: 'Typography', chilName: "car1", icon: 'assistant', class: '' },
    // { path: '/upgrade', title: 'Upgrade to PRO', chilName: "car1", icon: 'assistant', class: '' },
];
export interface RouteInfo {
    path: string;
    chilName: string;
    chilIcon: string;
    title: string;
    icon: string;
    class: string;
    style: string;
}
export const ROUTESCHIL: RouteChilInfo[] = [
    { path: '/hizmetal/ilanolustur', name: "get_service", title: 'İlan Oluştur', icon: 'arrow_right', class: '', style: '' },
    { path: '/hizmetal/aktifilanlarim', name: "get_service", title: 'Aktif İlanlarım', icon: 'arrow_right', class: '', style: '' },
    // { path: '/hizmetal/tamamlananlar', name: "get_service", title: 'Tamamlananlar', icon: 'arrow_right', class: '', style: '' },
    { path: '/hizmetver/tumilanlar', name: "set_service", title: 'Tüm İlanlar', icon: 'arrow_right', class: '', style: '' },
    { path: '/hizmetver/basvurduklarim', name: "set_service", title: 'Başvurduklarım', icon: 'arrow_right', class: '', style: '' },
    // { path: '/hizmetver/tamamlananlar', name: "set_service", title: 'Tamamlananlar', icon: 'arrow_right', class: '', style: '' },
];
export interface RouteChilInfo {
    name: string
    path?: string;
    title: string;
    icon: string;
    class: string;
    style: string;
}


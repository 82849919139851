
import { AuthService } from '@/services/authService';
import { catchError, from, map, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import Axios from '@/services/axios';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { ListResponseModel } from '@/models/listResponseModel';
import axios from 'axios';
import { CityCode } from '@/models/static/CityCode';
import CityCodes from '@/data/static/CityCodes.json';
import CityCodeChilds from '@/data/static/CityCodeChilds.json';
import { CityCodeChild, CityCodeChildSelect, District } from '@/models/static/CityCodeChild';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { ConformityDto } from '@/models/dto/conformityDto';
import { Inject } from 'vue-property-decorator';
import { exitHeaderMessage, exitRequestErrorMessage, exitRequestSuccessMessage } from '@/utilities/messages';
import AdminPanelSideBar from './AdminPanelSideBar.vue';
@Options({
    name: 'AdminPanelHeader',
    components: {
        AdminPanelSideBar
    }
})
export default class AdminPanelHeader extends Vue {
    userDetail: UserDetailDto = null;
    userName: string = "";

    //services
    @Inject('authService') authService!: AuthService;

    sizeWidth: number = window.innerWidth;
    sizeHeight: number = window.innerHeight;
    adminPanelSideBarVisible: boolean = true;

    mounted(): void {
        window.addEventListener('resize', this.handleResize);
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(): void {
        this.sizeWidth = window.innerWidth;
        this.sizeHeight = window.innerHeight;
        this.isVisiblePanelSidebar();
    }

    isVisiblePanelSidebar() {
        this.sizeWidth < this.sizeHeight ? this.adminPanelSideBarVisible = false : this.adminPanelSideBarVisible = true;
    }

    getCharacterAfterSpace(name: string): string {
        const indexOfSpace = name.indexOf(" ");
        return name.substring(0, indexOfSpace + 2);
    }

    logOut() {
        if (!this.authService.isAuthenticadet()) {
            localStorage.clear();
            this.$router.push({ name: 'home' });
            return;
        }
        this.authService.logOut()
            .pipe(
                catchError((error: ResponseModel) => {
                    if (error.success == false)
                        this.$toast.add({ severity: 'error', summary: exitHeaderMessage, detail: exitRequestErrorMessage, life: 3000 });
                    return of(null);
                })
            )
            .subscribe((response) => {
                if (response.data.success) {
                    this.$toast.add({ severity: 'success', summary: exitHeaderMessage, detail: exitRequestSuccessMessage, life: 3000 });
                    this.$router.push({ path: '/' })
                    localStorage.clear();
                }
            });
    }
}


import { UserDetailDto } from '@/models/dto/userDetailDto';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { AuthService } from '@/services/authService';
import Axios from '@/services/axios/axios';
import { UserService } from '@/services/userService';
import { formatDate } from '@/utilities/formatDate';
import { profileHeaderMessage, profileRequestSuccessInfoMessage } from '@/utilities/messages';
import { catchError, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
@Options({
    name: 'PhoneSetting',
    components: {
    },
})
export default class PhoneSetting extends Vue {
    userDetailDto: UserDetailDto = null;
    phone: string = null;

    //error
    errorResponseModel: ResponseModel = null;
    responseModel: ResponseModel = null;

    //error
    phoneError: string = "";


    @Inject('userService') userService!: UserService;
    @Inject('authService') authService!: AuthService;

    created() {
        this.getUserDetail();
    }

    getUserDetail() {
        let userId: number = this.authService.getCurrentUserId();
        this.userService.getUser(userId)
            .subscribe(response => {
                if (response.data.success) {
                    this.userDetailDto = response.data.data;
                    this.phone = this.userDetailDto.telefon;
                }
            })
    }

    updateInfo() {
        let profileInfo: any = {
            ad_soyad: this.userDetailDto.ad_soyad,
            telefon: this.phone,
            dogum_tarihi: formatDate(this.userDetailDto.dogum_tarihi),
        }
        Axios.put<ResponseModel>("user/updateinfo", profileInfo)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.responseModel = response;
                    this.$toast.add({ severity: 'success', summary: profileHeaderMessage, detail: profileRequestSuccessInfoMessage, life: 4000 });
                }
            });
    }

    onSubmit() {
        if (!this.validateForm()) {
            return;
        }
        else {
            this.updateInfo();
        }
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.phone) {
            this.phoneError = 'Telefon alanı boş!';
            isValid = false;
        } else {
            this.phoneError = '';
        }

        return (isValid);
    }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79904acd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-lg" }
const _hoisted_2 = { class: "div-search p-3" }
const _hoisted_3 = { class: "container-lg mt-2" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "container-lg" }
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = { class: "mb-5 col-md-12 text-center" }
const _hoisted_9 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_10 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_advert_search = _resolveComponent("advert-search")!
  const _component_advert_card = _resolveComponent("advert-card")!
  const _component_DeferredContent = _resolveComponent("DeferredContent")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_data_not_found = _resolveComponent("data-not-found")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_advert_search, {
            onAdvertSearch: _cache[0] || (_cache[0] = ($event: any) => {_ctx.search();}),
            onAdvertClear: _cache[1] || (_cache[1] = ($event: any) => {_ctx.clear();})
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.notFoundFilterAdverts() == false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_DeferredContent, { onLoad: _ctx.advertDtoLoad }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lazyAdvertDtos, (advertDto) => {
                      return (_openBlock(), _createBlock(_component_advert_card, {
                        key: advertDto.id,
                        advertDto: advertDto
                      }, null, 8, ["advertDto"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["onLoad"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.possiblePage() && _ctx.isAdded == true && _ctx.notFoundFilterAdverts() == false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (!_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("i", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.lazyLoadAdvert())),
                      class: "eva eva-loader-outline p-button-rounded m-5",
                      style: {"font-size":"2rem"}
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_ProgressSpinner)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.notFoundFilterAdverts() == true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_data_not_found, { infoText: "Malesef, aradığın kriterlerde ilan\n                                                                bulamadık" })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
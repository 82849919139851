
import { AuthService } from '@/services/authService';
import { catchError, from, map, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import Axios from '@/services/axios';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { ListResponseModel } from '@/models/listResponseModel';
import axios from 'axios';
import { CityCode } from '@/models/static/CityCode';
import CityCodes from '@/data/static/CityCodes.json';
import CityCodeChilds from '@/data/static/CityCodeChilds.json';
import { CityCodeChild, CityCodeChildSelect, District } from '@/models/static/CityCodeChild';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { ConformityDto } from '@/models/dto/conformityDto';
import { Inject } from 'vue-property-decorator';
import { getUrlImageFromAxios } from '@/utilities/getImg';
import { exitHeaderMessage, exitRequestErrorMessage, exitRequestSuccessMessage } from '@/utilities/messages';
import PanelSideBar from './PanelSideBar.vue';
import GoogleAuthLogin from '../login/GoogleAuthLogin.vue';
@Options({
    name: 'PanelHeader',
    components: {
        PanelSideBar
    }
})
export default class PanelHeader extends Vue {
    userDetail: UserDetailDto = null;
    userName: string = "";
    value: string = null;
    checked: boolean = true;
    imgUrl: string = "";
    isLoaded: boolean = false;
    loadLazyTimeout: any = null;
    loading: boolean = false;
    lazyItems: any = null;
    updateInfoResponse: ResponseModel = null;
    conformityDto: ConformityDto = null;
    isAuthBool: boolean = false;

    displayCityState: boolean = false;
    cityStateText: string = null;

    cityList: Array<CityCode> = [];
    stateList: Array<CityCode> = [];
    CitySelect: Array<CityCode> = [];
    StateSelect: Array<CityCode> = [];
    selectedCity: any = null;
    selectedState: any = null;
    selectedCityError: string = '';
    selectedStateError: string = '';
    position: any = 'center';

    sizeWidth: number = window.innerWidth;
    sizeHeight: number = window.innerHeight;
    panelSideBarVisible: boolean = true;
    baseUrl:string = process.env.VUE_APP_IMG_PROFILE_PATH;

    //services
    @Inject('authService') authService!: AuthService;

    mounted(): void {
        this.lazyItems = Array.from({ length: 100000 });
        window.addEventListener('resize', this.handleResize);
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(): void {
        this.sizeWidth = window.innerWidth;
        this.sizeHeight = window.innerHeight;
        this.isVisiblePanelSidebar();
        // console.log('Window was resized');
    }

    isVisiblePanelSidebar() {
        this.sizeWidth < this.sizeHeight ? this.panelSideBarVisible = false : this.panelSideBarVisible = true;
    }

    created() {
        this.cityList = CityCodes.CitySelect;
        this.loadUniqCity();
        let localStorageToken = localStorage.getItem('token');
        let localStorageConformity = localStorage.getItem('saveConformity');

        if (this.authService.isAuthenticadet() && localStorageToken !== null)
            this.getUserDetail();
        if (localStorageConformity != null) {
            this.conformityDto = JSON.parse(localStorageConformity)
        }
    }

    loadUniqCity() {
        let unique: Array<CityCode> = this.cityList.filter((city, index, self) => {
            return self.findIndex(c => c.City === city.City) === index;
        });
        this.CitySelect = unique;
    }

    getUserDetail() {

        let userId: number;

        userId = this.authService.getCurrentUserId();
        Axios.get<SingleResponseModel<UserDetailDto>>("user/getuser/" + userId)
            .subscribe(response => {
                if (response.data.success) {
                    this.userDetail = response.data.data;
                    this.userName = response.data.data.ad_soyad;
                    this.viewCityStateText();
                    this.setConformation();
                }
            })
    }

    getCharacterAfterSpace(name: string): string {
        const indexOfSpace = name.indexOf(" ");
        return name.substring(0, indexOfSpace + 2);
    }

    selecetCityUpdate() {
        Axios.put<ResponseModel>('user/updateinfo', this.conformityDto)
            .subscribe(response => {
                this.updateInfoResponse = response;
            });
    }

    isSelectedCityCodeNull() {
        if (this.selectedState === null || this.selectedState === undefined)
            this.checked = false;
        else
            this.checked = true;
    }

    conformityChange() {
        if (this.selectedState == null)
            this.checked = false;
        this.conformityDto = {
            il: this.selectedCity == null ? null : this.selectedState.City,
            ilce: this.selectedState == null ? null : this.selectedState.State,
            uygunluk: Boolean(this.checked)
        };
        localStorage.setItem('saveConformity', JSON.stringify(this.conformityDto))
        this.cityStateText = null;
        this.selecetCityUpdate()
    }

    conformityChangeUser() {
        if (this.selectedState === null)
            this.checked = false;
        this.conformityDto = {
            il: this.userDetail == null ? null : this.userDetail.il,
            ilce: this.userDetail == null ? null : this.userDetail.ilce,
            uygunluk: Boolean(1)
        };
        localStorage.setItem('saveConformity', JSON.stringify(this.conformityDto))
        this.selecetCityUpdate()
    }

    citySelected() {
        this.stateList.splice(0);
        this.StateSelect.splice(0);
        this.stateList = this.cityList.filter(
            (element: any) => element.City === this.selectedCity.City
        );
        this.stateList.forEach((element: any) => {
            this.StateSelect.push(element);
        });
    }

    stateSelected() {
        this.stateList = this.cityList.filter(
            (element: any) => element.State === this.selectedState.State
        );
        if (this.selectedCity && this.selectedState) {
            this.saveCityState();
            this.displayCityState = false;
            this.viewCityStateText();
        }
    }

    setConformation() {
        if (this.conformityDto)
            this.checked = Boolean(this.conformityDto.uygunluk);
        else
            this.checked = Boolean(this.userDetail.uygunluk);
    }

    saveCityState() {
        this.isSelectedCityCodeNull();
        this.conformityDto = {
            il: this.selectedCity.City,
            ilce: this.selectedState.State,
            uygunluk: Boolean(1)
        };
        this.conformityDto == null || undefined ? this.conformityChangeUser() : this.conformityChange();
        this.selecetCityUpdate();
    }

    viewCityStateText() {
        if (this.conformityDto)
            this.cityStateText = this.conformityDto.il == null || this.conformityDto.ilce == null ? '' : this.conformityDto.il + '/' + this.conformityDto.ilce;
        else
            this.cityStateText = this.userDetail.il == null || this.userDetail.ilce == null ? '' : this.userDetail.il + '/' + this.userDetail.ilce;
    }

    openPosition(position: any) {
        this.position = position;
        this.displayCityState = true;
    }

    logOut() {
        if (!this.authService.isAuthenticadet()) {
            localStorage.removeItem('token');
            this.$router.push({ name: 'home' });
            return;
        }
        this.authService.logOut()
            .pipe(
                catchError((error: ResponseModel) => {
                    if (error.success == false)
                        this.$toast.add({ severity: 'error', summary: exitHeaderMessage, detail: exitRequestErrorMessage, life: 3000 });
                    return of(null);
                })
            )
            .subscribe((response) => {
                if (response.data.success) {
                    this.$toast.add({ severity: 'success', summary: exitHeaderMessage, detail: exitRequestSuccessMessage, life: 3000 });
                    this.$router.push({ path: '/' })
                    localStorage.removeItem("token");
                }
            });
    }
}


import { AdvertDto } from '@/models/dto/advertDto';
import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import { abbreviateNumber } from '@/utilities/abbreviateNumber';
import { advertHeaderMessage, advertRequestFailedErrorMessage, advertRequestInputErrorMessage, advertRequestMaxErrorMessage, advertRequestMyErrorMessage, advertRequestNotConfirmErrorMessage, advertRequestNotFoundMessage, advertRequestSuccessMessage } from '@/utilities/messages';
import { catchError, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import { Emit } from 'vue-property-decorator';

@Options({
    name: 'AdvertDetail',
    props: ['advertDto']
})
export default class AdvertDetail extends Vue {
    advertDto!: AdvertDto
    advertConfirmModal: boolean = false;
    responseModel: ResponseModel = null;
    errorResponseModel: ResponseModel = null;
    errorAdvertMessage: string = null;

    getRouteName(): string {
        return (this.$route.name.toString())

    }

    getDate(date: Date): Date {
        const moment = require('moment');
        let now = moment(date).format('DD MM YYYY - HH.mm');
        return (now);
    }
    reqAdvert() {
        Axios.post<ResponseModel>("advert/reqadvert/" + this.advertDto.id, undefined)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    if (this.errorResponseModel.message === "Bu ilan zaten verilmiş")
                        this.errorAdvertMessage = advertRequestInputErrorMessage
                    else if (this.errorResponseModel.message === "Kendi ilanınıza başvuramazsınız")
                        this.errorAdvertMessage = advertRequestMyErrorMessage
                    else if (this.errorResponseModel.message === "Bu ilan henüz onaylanmadı")
                        this.errorAdvertMessage = advertRequestNotConfirmErrorMessage
                    else if (this.errorResponseModel.message === "İlan maksimum kullanıcı sayısına ulaştı. Bu ilana başvuru yapamazsınız")
                        this.errorAdvertMessage = advertRequestMaxErrorMessage
                    else if (this.errorResponseModel.message == "Bu ilanı bulamadık")
                        this.errorAdvertMessage = advertRequestNotFoundMessage
                    else if (this.errorResponseModel.message == "Bu ilana daha önce başvuru yaptınız")
                        this.errorAdvertMessage = advertRequestFailedErrorMessage
                    if (this.errorAdvertMessage != null && this.errorResponseModel.status != 200)
                        this.$toast.add({ severity: 'error', summary: advertHeaderMessage, detail: this.errorAdvertMessage, life: 4000 });
                    return of();
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.responseModel = response.data;
                    this.$toast.add({ severity: 'success', summary: advertHeaderMessage, detail: advertRequestSuccessMessage, life: 4000 });
                }
            });
        this.closeDialog();
    }

    @Emit('close-advert-detail-modal')
    closeDialog() {
        this.$emit('close-advert-detail-modal');
    }

    abbreviateNumberx(butce: number):string {
        return (abbreviateNumber(butce));
    }
}


import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'FullText',
    props: ['textx', 'title', 'subTitle', 'titleSize']
})

export default class MetaverseView extends Vue {
    textx!: string
    title!: string
    subTitle!: string
    titleSize!: number
}


import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
@Options({
  name: 'AboutView',
  components: {
    FullText
  }
})

export default class AboutView extends Vue {
}

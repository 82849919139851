
import { AdvertDto } from '@/models/dto/advertDto';
import { ref } from 'vue';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'AdvertEdit',
    props: ['advertDto'],
    components:
    {
    },
})
export default class AdvertEdit extends Vue {
    value: string

    selectedCity: any = null;
    date3: Date = null;
    cities: any = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ]
}

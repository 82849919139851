
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import MobileApp from '@/components/MobileApp.vue';
@Options({
    name: 'LegalAdviceView',
    components: {
        FullText,
        MobileApp
    }
})

export default class LegalAdviceView extends Vue {

}

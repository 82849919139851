
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'Accordion',
    props: ['textx', 'title']
})

export default class Header extends Vue {
    textx!: string;
    title!: string;
    isActive: Boolean = false;

    openCloseAccordion(): void {
        this.isActive = !this.isActive;
    }
}

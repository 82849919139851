import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16ffd045"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-lg" }
const _hoisted_2 = { class: "container-lg" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12 mb-3 mt-5" }
const _hoisted_5 = {
  id: "span",
  class: "custom-marker shadow-2"
}
const _hoisted_6 = { class: "time-line-number rounded-circle" }
const _hoisted_7 = { class: "p-main-title" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = { class: "w-100 mt-2 mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_Text = _resolveComponent("full-Text")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_Text, {
        title: "Nasıl Çalışır?",
        textx: "Avukatweb’de ilk kez bireyler bir platform üzerinden, hukuksal konular üzerinde bir araya gelip\n                  konuşabilirler. Burada tüm hukuki taleplerinizi sınırsız olarak oluşturabilirsiniz. Avukatweb global hukuk\n                  teknolojisi (legaltech) projesi olarak, dünyada bir ilk olacak şekilde bireylerin aradığı avukata çok hızlı\n                  bir şekilde ulaşmasını sağlar. Düşünün, evinizdeyken sizin kafanızı karıştıran bir hukuksal konuyu, alanında\n                  uzman bir avukat ile görüşebileceksiniz. Ya da adliye ve kurumlarsa acil bir hukuksal destek almak\n                  istediğinizde, platformumuz üzerinden hemen talep oluşturabileceksiniz. Bu teknolojiye başlamak için\n                  aşağıdaki adımları izleyin."
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Timeline, {
            value: _ctx.events,
            align: "alternate",
            class: "customized-timeline"
          }, {
            marker: _withCtx((slotProps) => [
              _createElementVNode("span", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(slotProps.item.id), 1)
              ])
            ]),
            content: _withCtx((slotProps) => [
              _createVNode(_component_Card, { style: {"box-shadow":"none"} }, {
                title: _withCtx(() => [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(slotProps.item.status), 1)
                ]),
                subtitle: _withCtx(() => [
                  _createTextVNode(_toDisplayString(slotProps.item.date), 1)
                ]),
                content: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.item.texts, (text) => {
                    return (_openBlock(), _createElementBlock("p", {
                      class: "p-main-text",
                      key: text
                    }, _toDisplayString(text), 1))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]),
            opposite: _withCtx((slotProps) => [
              _createElementVNode("div", null, [
                (slotProps.item.image)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.getImgUrl(slotProps.item.image),
                      alt: slotProps.item.name,
                      class: "shadow-2 w-100 h-100"
                    }, null, 8, _hoisted_8))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["value"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_router_link, { to: "/kayit" }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              label: "Kayıt Ol",
              class: "p-button-raised p-button-rounded w-25 p-button-size p-button-lg"
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
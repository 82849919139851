import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-afea777c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container-lg" }
const _hoisted_3 = { class: "row justify-content-md-center" }
const _hoisted_4 = { class: "col-md-5 mt-4 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Steps, {
            model: _ctx.items,
            readonly: true
          }, null, 8, ["model"])
        ])
      ])
    ]),
    _createVNode(_component_router_view, {
      onNextStep: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stepper('next'))),
      onPrevStep: _cache[1] || (_cache[1] = ($event: any) => (_ctx.stepper('prev')))
    })
  ]))
}
import { LoginModel } from "@/models/model/loginModel";
import { RegisterModel } from "@/models/model/registerModel";
import { TokenModel } from "@/models/model/tokenModel";
import { SingleResponseModel } from "@/models/singleResponseModel";
import { BehaviorSubject } from "rxjs";
import { ResetPasswordModel } from './../models/model/resetPasswordModel';
import { Observable } from 'rxjs/';
import { jwtControllerService } from "./jwtControllerService";
import { ResponseModel } from "@/models/responseModel/responseModel";
import Axios from '@/services/axios';
import { AxiosObservable } from '@/services/axios/axios-observable.interface';
import { AxiosRequestConfig } from "axios";
import { provide, inject } from 'vue'

export class AuthService {
    currentUserId: number;
    private data = new BehaviorSubject<boolean>(false)
    isAuth = this.data.asObservable()
    private data1 = new BehaviorSubject<string>(null);
    roleName = this.data1.asObservable()

    login(loginModel: LoginModel): AxiosObservable<TokenModel> {
        return Axios.post<TokenModel>("auth/login", loginModel)
    }
    register(registerModel: any, config?: AxiosRequestConfig): AxiosObservable<TokenModel> {
        return Axios.post<TokenModel>("auth/register", registerModel, config)
    }
    passwordChange(resetPasswordModel: ResetPasswordModel): AxiosObservable<TokenModel> {
        return Axios.post<TokenModel>("auth/resetpassword", resetPasswordModel)
    }
    isAuthenticadet() {
        let token = localStorage.getItem('token');
        if (token && jwtControllerService.isTokenValid(token)) {
            return (true);
        } else {
            return (false);
        }
    }
    logOut(): AxiosObservable<ResponseModel> {
        return Axios.get<ResponseModel>("auth/logout");
    }
    getCurrentFullName(): string {
        let token: string = localStorage.getItem("token")
        if (token) {
            let decoded: any = jwtControllerService.decodeToken(token)
            let userName = Object.keys(decoded).filter(x => x.startsWith("name"))[0];
            return decoded[userName];
        }
        return (null)
    }
    getCurrentUserId(): number {
        let token: string = localStorage.getItem("token")
        if (token) {
            let decoded: any = jwtControllerService.decodeToken(token)
            let userId = Object.keys(decoded).find(x => x.startsWith("id"));
            return decoded[userId];
        }
        return (null)
    }
    getCurrentRole(): string {
        let token: string = localStorage.getItem("token")
        if (token) {
            let decoded:any = jwtControllerService.decodeToken(token)
            let role = Object.keys(decoded).filter(x => x.startsWith("role"))[0];
            return decoded[role];
        }
        return (null)
    }
    // isActive() {
    //     let token: string = localStorage.getItem("token")
    //     if (token) {
    //         return jwtControllerService.isActive(token);
    //     }
    // }
}
export const authServiceNew = new AuthService();

provide('authService', new AuthService());
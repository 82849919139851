import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c81ffcb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-lg" }
const _hoisted_2 = { class: "container-lg" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "container-lg" }
const _hoisted_6 = { class: "row w-100 mb-5" }
const _hoisted_7 = { class: "mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_Text = _resolveComponent("full-Text")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_blog_card = _resolveComponent("blog-card")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_Text, {
        title: "Blog",
        textx: "Hukuka dair bilgiler ve önemli ipuçları Avukatweb Blog’ta."
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            (_ctx.tag != '')
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 0,
                  value: "X",
                  class: "mr-2 custom-bagde",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tagFilterDefault()))
                }))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogFilterModel?.tags, (blogTag) => {
              return (_openBlock(), _createBlock(_component_Chip, {
                key: blogTag.tag,
                label: blogTag.tag,
                class: "mb-2 mr-2 custom-chip",
                onClick: ($event: any) => (_ctx.tagFilter(blogTag.tag))
              }, null, 8, ["label", "onClick"]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogFilterModel?.data, (blog) => {
          return (_openBlock(), _createBlock(_component_blog_card, {
            key: blog.id,
            blog: blog
          }, null, 8, ["blog"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Paginator, {
            first: _ctx.first,
            "onUpdate:first": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.first) = $event)),
            rows: 8,
            onPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPage($event))),
            totalRecords: _ctx.blogFilterModel?.totalCount
          }, null, 8, ["first", "totalRecords"])
        ])
      ])
    ])
  ]))
}
import { BehaviorSubject } from "rxjs";
import { provide, inject } from 'vue'

const ActivePageNameServiceSymbol = Symbol()


export class ActivePageNameService {
    private data = new BehaviorSubject<string>("");
    activePageName = this.data.asObservable();
  
    private _pageName: string = "";
  
    public get pageName(): string {
      return this._pageName;
    }
    
    public set pageName(v: string) {
      this._pageName = v;
      // this.data.next(v);
    }

    constructor() {
      // this.data.next("");
    }
  
    saveActivePage(v:string) {
      this._pageName = v;
      // this.data.next(v);
    }
  
    loadActivePage(): string {
      return (this._pageName);
      // return this.data.getValue();
    }
  }

  provide('activePageNameService', new ActivePageNameService());
  // export function provideActivePageNameService(){
  //   provide(ActivePageNameServiceSymbol, new ActivePageNameService())
  // }
  
  // export function useActivePageNameService(){
  //   return inject(ActivePageNameServiceSymbol) as ActivePageNameService
  // }
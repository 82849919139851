
import { Options, Vue } from 'vue-class-component';
import HomeCard from "@/components/HomeCard.vue";
import HomeBlogCard from "@/components/HomeBlogCard.vue";
import HomeCommentCard from "@/components/HomeCommentCard.vue";
import Accordionx from "@/components/Accordion.vue";
import MobileApp from "@/components/MobileApp.vue";
import FaqData from '@/data/static/FaqData.json';
import HomeCardData from '@/data/static/HomeCardData.json';
import { HomeCardModel } from '@/models/static/HomeCardModel';
import HomeCommentCardData from '@/data/static/HomeCommentCardData.json';
import { HomeCommentCardModel } from '@/models/static/HomeCommentCardModel';
import { Faq } from '@/models/static/Faq';
import { Component, Inject } from 'vue-property-decorator';
import { ActivePageNameService } from '@/services/activePageService';
import { Blog } from '@/models/dto/blog';
import { BlogFilterModel } from '@/models/model/blogFiletModel';
import { BlogFilter } from '@/models/entity/blogFilter';
import Axios from '@/services/axios/axios';
import { catchError, of } from 'rxjs';
@Options({
  name: 'HomeView',
  components: {
    HomeCard,
    HomeCommentCard,
    HomeBlogCard,
    Accordionx,
    MobileApp
  },
})
export default class HomeView extends Vue {
  faqs: Faq[] = FaqData;
  homeCards: HomeCardModel[] = HomeCardData;
  homeCommentCards: HomeCommentCardModel[] = HomeCommentCardData;

  //blog
  blogFilterModel: BlogFilterModel<Blog> | null = null;
  blogFilter: BlogFilter = null;
  tag: string = '';
  page: number = 1;
  first: number = 0

  //inject
  @Inject('activePageNameService') myService!: ActivePageNameService;

  mounted() {
    this.someMethod();
  }

  created() {
    this.getBlogsFilter(1, "");
  }

  someMethod(): void {
    this.myService.saveActivePage("Home");
  }

  routeHowWork() {
    this.$router.push({ path: "/kayit/giris-bilgileri" })
  }

  getBlogsFilter(pageIndex: number = 1, tag: string = "") {
    this.blogFilter =
    {
      page: pageIndex,
      tag: tag
    }
    Axios.post<BlogFilterModel<Blog>>("blog/filter", this.blogFilter)
      .pipe(
        catchError(errorResponse => {
          return of(null);
        })
      )
      .subscribe(response => {
        if (response.data.success)
        {
          this.blogFilterModel = response.data;
          let result:number = response.data.data.length - 3;
          if (response.data.data.length > 3)
            this.blogFilterModel.data = response.data.data.splice(result);
        }
      });
  }
}

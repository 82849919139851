import { AdminLoginModel } from "@/models/model/adminLoginModel";
import { provide } from "vue";
import Axios, { AxiosObservable } from "./axios";
import { TokenModel } from "@/models/model/tokenModel";

export class AdminService {
    login(loginModel: AdminLoginModel): AxiosObservable<TokenModel> {
        return Axios.post<TokenModel>("admin/login", loginModel);
    }

    logout(){
        return Axios.get<TokenModel>("admin/logout");
    }
}

provide('adminService', new AdminService());

import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import PanelHomeCard from '@/components/panel/PanelHomeCard.vue';
import { ActivePageNameService } from '@/services/activePageService';
import { catchError, from, map, of } from 'rxjs';
import { AdvertDto } from '@/models/dto/advertDto';
import Axios from '@/services/axios';
import { ListResponseModel } from '@/models/listResponseModel';
import AdvertCard from '@/components/advert/AdvertCard.vue';
import AdvertSearch from '@/components/advert/AdvertSearch.vue';
import { AdvertFilterModel } from '@/models/model/advertFilterModel';
import { AdvertFilter } from '@/models/entity/advertFilter';
import { AxiosResponse } from 'axios';
import DataNotFound from '@/components/DataNotFound.vue';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { Emit } from 'vue-property-decorator';
@Options({
    name: 'AdvertSearchView',
    components: {
        FullText,
        PanelHomeCard,
        AdvertCard,
        AdvertSearch,
        DataNotFound
    }
})

export default class AdvertSearchView extends Vue {
    advertFilterModels: AdvertFilterModel<AdvertDto> = null;
    advertFilter: AdvertFilter = null;
    accountConfirmation: boolean = true
    activePageNameService: ActivePageNameService;

    //lazy
    lazyAdvertDtos: Array<AdvertDto> = [];
    lazyPageIndex: number = 1;
    lazyPageSize: number;
    isLoading: boolean = false;
    isAdded: boolean = true;
    totalCount: number = 0;

    //error
    errorResponseModel: ResponseModel = null;

    mounted() {
        const listElm = window;
        listElm.addEventListener('scroll', e => {
            if (Math.ceil(listElm.scrollY + listElm.innerHeight) >= document.body.scrollHeight) {
                if (this.possiblePage() && this.isAdded == true)
                    this.lazyLoadAdvert();
            }
        });
    }
    created() {
        let lazyAdvertString: string = localStorage.getItem('saveAdvertFilterData');
        if (localStorage.getItem('saveAdvertFilterData') != null) {
            let localStorageLazyAdvert: AdvertFilter = JSON.parse(lazyAdvertString)
            setTimeout((e: any) => {
                this.getFilterAdverts(localStorageLazyAdvert.page);
            }, 200);
        }
    }
    search() {
        this.lazyAdvertDtos.splice(0);
        delete this.lazyAdvertDtos
        this.lazyAdvertDtos = []
        this.lazyPageIndex = 1;
        this.lazyLoadAdvert(1);
    }
    clear() {
        this.lazyAdvertDtos.splice(0);
        delete this.lazyAdvertDtos
        this.lazyAdvertDtos = []
        this.lazyPageIndex = 1;
        this.lazyLoadAdvert(1);
    }
    possiblePage(): boolean {
        if (this.lazyPageIndex <= this.lazyPageSize && this.totalCount > 0)
            return (true);
        return (false);
    }
    lazyLoadAdvert(index?: number): void {
        if (!this.isLoading) {
            this.isLoading = true;
            setTimeout((e: any) => {
                index === undefined ? this.getFilterAdverts(this.lazyPageIndex) : this.getFilterAdverts(1);
                this.isLoading = false;
            }, 200);
        }
    }
    lazyAddAdvert(response: any) {
        if (this.isAdded == true) {
            response.data.data.forEach((element: AdvertDto) => {
                this.lazyAdvertDtos.push(element);
            });
        }
        this.isAdded = false;
    }
    async getFilterAdverts(index: number) {
        let getFilterData: any = localStorage.getItem('saveAdvertFilterData');
        this.advertFilter = JSON.parse(getFilterData);
        this.advertFilter.page = index;

        Axios.post<AdvertFilterModel<AdvertDto>>('advert/filter', this.advertFilter)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    return of();
                }),
                map((response: AxiosResponse<AdvertFilterModel<AdvertDto>>) => response)
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.advertFilterModels = response.data;
                    this.totalCount = response.data.totalCount === undefined ? 0 : Number(response.data.totalCount);
                    this.lazyPageSize = Math.ceil(Number(this.totalCount) / 8);
                    if (this.possiblePage()) {
                        this.errorResponseModel = null;
                        this.lazyAddAdvert(response)
                        if (this.lazyPageIndex < this.lazyPageSize) {
                            this.lazyPageIndex += 1;
                            this.isAdded = true;
                        }
                        else if (this.lazyPageSize == 1) {
                            this.isAdded = true;
                            this.lazyPageIndex += 1;
                        }
                    }
                }
            });
    }
    notFoundFilterAdverts(): boolean {
        if (this.errorResponseModel != null && this.errorResponseModel.success == false && this.errorResponseModel.message === 'Filtreleme sonucu veri bulunamadı')
            return (true);
        return (false);
    }
    advertDtoLoad() {
        // console.log("loaded");
    }
}


import { Options, Vue } from 'vue-class-component';
import FullText from '../../components/FullText.vue'
@Options({
    name: 'WhyAvukatWebView',
    components: {
        FullText
    }
})

export default class WhyAvukatWebView extends Vue {
    sizeWight: number = window.innerWidth;
    sizeHeight: number = window.innerHeight;
    className: string = null;
    mounted(): void {
        window.addEventListener('resize', this.handleResize);
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(): void {
        this.sizeWight = window.innerWidth;
        this.sizeHeight = window.innerHeight;
        if (this.sizeHeight > this.sizeWight)
            this.className = "why_image_phone";
        else
            this.className = "why_image";
    }
}


import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61cd703a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-lg" }
const _hoisted_2 = { class: "container-lg" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "col-md-6" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-12" }
const _hoisted_8 = { class: "m-2 div-settings" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-md-6" }
const _hoisted_11 = { class: "col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_text = _resolveComponent("full-text")!
  const _component_phone_setting = _resolveComponent("phone-setting")!
  const _component_password_setting = _resolveComponent("password-setting")!
  const _component_profile_photo_setting = _resolveComponent("profile-photo-setting")!
  const _component_areas_of_expertises_setting = _resolveComponent("areas-of-expertises-setting")!
  const _component_profile_description_setting = _resolveComponent("profile-description-setting")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_text, {
        title: _ctx.getRouteName(),
        titleSize: 24
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_phone_setting)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_password_setting)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_profile_photo_setting),
                _createVNode(_component_areas_of_expertises_setting)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_profile_description_setting)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
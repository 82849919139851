
import { Ref, ref } from 'vue';
import { Options, setup, Vue } from 'vue-class-component';
import { Component, Watch, Prop } from 'vue-property-decorator';
import FullText from '@/components/FullText.vue'
import Axios from '@/services/axios/axios';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { catchError, of } from 'rxjs';
import { passHeaderMessage, passRequestUpdateErrorMessage, passRequestUpdateSuccessMessage } from '@/utilities/messages';
@Options({
  name: 'PasswordResetView',
  components: {
    FullText
  }
})
export default class PasswordResetView extends Vue {
  password: string = null;
  password1: string = null;
  responseModel: ResponseModel = null;
  errorResponseModel: ResponseModel = null;

  //validation
  passwordError: string = null;
  password1Error: string = null;
  token: string;

  //disabled
  sendDisabled: boolean = true;

  created() {
    this.token = String(this.$route.params.token);
  }

  resetPassword() {
    let passwordForgot: any = {
      sifre: this.password
    }
    Axios.put<ResponseModel>("auth/resetpassword/" + this.token, passwordForgot)
      .pipe(
        catchError(error => {
          this.errorResponseModel = error;
          if (this.errorResponseModel.success == false)
            this.$toast.add({ severity: 'error', summary: passHeaderMessage, detail: passRequestUpdateErrorMessage, life: 4000 });
          return of(null);
        })
      )
      .subscribe(response => {
        if (response.data.success == true) {
          this.responseModel = response.data;
          this.$toast.add({ severity: 'success', summary: passHeaderMessage, detail: passRequestUpdateSuccessMessage, life: 4000 });
          this.responseModel.success = false;
          this.sendDisabled = false;
        }
      });
    let intervalId = setInterval(() => {
      if (this.sendDisabled == false) {
        this.resetForm();
        clearInterval(intervalId);
        this.$router.push({ name: 'home' });
      }
    }, 200);
  }

  validateForm(): boolean {
    let isValid = true;

    if (!this.password) {
      this.passwordError = 'Şifre alanı boş!';
      isValid = false;
    } else {
      this.passwordError = '';
    }

    if (!this.password1) {
      this.password1Error = 'Şifre alanı boş!';
      isValid = false;
    } else {
      this.password1Error = '';
    }

    if (this.password !== this.password1) {
      this.password1Error = 'Şifreler aynı değil!';
      isValid = false;
    } else {
      this.password1Error = '';
    }
    return (isValid);
  }

  onSubmit() {
    if (!this.validateForm())
      return;
    this.resetPassword()
  }
  resetForm() {
    let formRef = this.$refs.form as HTMLFormElement;
    if (formRef) {
      formRef.reset();
      this.password = null;
      this.password1 = null;
    }
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_Text = _resolveComponent("full-Text")!
  const _component_mobile_app = _resolveComponent("mobile-app")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_Text, {
        title: "Hukuki Danışmanlık",
        textx: "Avukatweb’in kullanıcılara sağladığı tek hizmet salt avukatların kendileri arasında tevkil işlerini yönlendirmesine yönelik değildir. Avukatweb üyeleri dilerse, ilanlar kısmında ilan oluşturarak iş kategorileri içerisinden “hukuki danışmanlık” seçeneğini tercih ederek diledikleri hukuki mesele konusunda farklı meslektaşların fikirlerini alabilirler."
      })
    ]),
    _createVNode(_component_mobile_app)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_text = _resolveComponent("full-text")!
  const _component_advert_search_view = _resolveComponent("advert-search-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_text, {
        title: _ctx.getRouteName(),
        titleSize: 24
      }, null, 8, ["title"])
    ]),
    _createVNode(_component_advert_search_view)
  ]))
}

import { Options, Vue } from 'vue-class-component';
import CityCodes from "@/data/static/CityCodes.json";
import { CityCode } from '@/models/static/CityCode';
import { AreasOfExpertise } from '@/models/static/AreasOfExpertise';
import AreasOfExpertises from '@/data/static/AreasOfExpertises.json';
import { AdvertAddDto } from '@/models/dto/advertAddDto';
import { UserFilter } from '@/models/entity/userFilter';
import { AdvertFilter } from '@/models/entity/advertFilter';
import { Courthouse } from '@/models/static/Courthouse';
import Courthouses from '@/data/static/Courthouses.json';
import { LawBar } from '@/models/static/LawBar';
import LawBars from '@/data/static/LawBars.json';
import { Emit } from 'vue-property-decorator';
import { Experience } from '@/models/static/Experience';
import Experiences from '@/data/static/Experiences.json';
import ChipSearch from '../ChipSearch.vue';
@Options({
    name: 'AdvertSerach',
    components:{ ChipSearch }
})
export default class AdvertSerach extends Vue {
    cityList: Array<CityCode> = CityCodes.CitySelect;
    stateList: Array<CityCode> = []
    CitySelect: Array<CityCode> = [];
    StateSelect: Array<CityCode> = [];
    selectedCity: any = null;
    selectedState: any = null;
    advertDetail: string;
    advertBudget: number = null;
    jobDate: Date = null;
    jobEndDate: Date = null;
    userFilter: UserFilter = null;
    areasOfExpertises: AreasOfExpertise[] = AreasOfExpertises;
    selectedAreasOfExpertise: AreasOfExpertise = null;
    courthouses: Courthouse[] = Courthouses;
    selectedCourthouse: Courthouse = null;
    lawBars: LawBar[] = LawBars
    selectedLawBar: LawBar = null;
    experiences: Experience[] = Experiences;
    selectedExperience: Experience = null;
    advertAddDto: AdvertAddDto = null;
    advertAddResult: any = null;
    lawyerName: string = null;

    created() {
        let unique: Array<CityCode> = this.cityList.filter((city, index, self) => {
            return self.findIndex(c => c.City === city.City) === index;
        });
        this.CitySelect = unique;
        this.startDefaultSearch();
        this.getLocalStorageAdvertFilter();
        this.getUserFilter();
    }

    getLocalStorageAdvertFilter() {
        let userFilterString = localStorage.getItem('saveUserFilterData');
        let localStorageUserFilter: UserFilter = JSON.parse(userFilterString);
        this.userFilter = localStorageUserFilter;
    }

    citySelected() {
        this.stateList.splice(0);
        this.StateSelect.splice(0);
        this.stateList = this.cityList.filter(
            (element: any) => element.City === this.selectedCity.City
        );
        this.stateList.forEach((element: any) => {
            this.StateSelect.push(element);
        });
    }
    stateSelected() {
        this.stateList = this.cityList.filter(
            (element: any) => element.State === this.selectedState.State
        );
    }
    @Emit('lawyer-search')
    search() {
        this.$emit('lawyer-search');
        this.userFilter = {}

        this.userFilter.page = 1;
        this.userFilter.uzmanlik_alani = this.selectedAreasOfExpertise == null ? "" : String(this.selectedAreasOfExpertise.text);
        this.userFilter.il = this.selectedCity == null ? "" : String(this.selectedCity.City);
        this.userFilter.ilce = this.selectedState == null ? "" : String(this.selectedState.State);
        this.userFilter.ad_soyad = this.lawyerName == null ? "" : String(this.lawyerName);
        this.userFilter.kayitli_oldugu_baro = this.selectedLawBar == null ? "" : String(this.selectedLawBar.text);
        this.userFilter.tecrube_start = this.selectedExperience == null ? "" : String(this.selectedExperience.minYear);
        this.userFilter.tecrube_end = this.selectedExperience == null ? "" : String(this.selectedExperience.maxYear);

        localStorage.setItem('saveUserFilterData', JSON.stringify(this.userFilter))
    }

    @Emit('lawyer-clear')
    clear() {
        this.$emit('lawyer-clear');
        this.defaultUserFilter();
    }
    defaultUserFilter() {
        localStorage.removeItem('saveUserFilterData')

        this.userFilter = {}
        this.userFilter.page = 1;
        this.userFilter.uzmanlik_alani = null;
        this.userFilter.il = null;
        this.userFilter.ilce = null;
        this.userFilter.ad_soyad = null;
        this.userFilter.kayitli_oldugu_baro = null;
        this.userFilter.tecrube_start = null;
        this.userFilter.tecrube_end = null;

        this.selectedCity = null;
        this.selectedState = null;
        this.selectedAreasOfExpertise = null;
        this.selectedLawBar = null;
        this.selectedExperience = null;
        this.lawyerName = null;
        this.search();
    }
    getUserFilter() {
        let userFilterString = localStorage.getItem('saveUserFilterData');
        let localStorageAdvertFilter: UserFilter = JSON.parse(userFilterString);

        this.selectedAreasOfExpertise = this.areasOfExpertises.find(s => s.text == localStorageAdvertFilter.uzmanlik_alani);
        this.selectedCourthouse = this.courthouses.find(s => s.text == localStorageAdvertFilter.il);
        this.selectedLawBar = this.lawBars.find(s => s.text == localStorageAdvertFilter.kayitli_oldugu_baro);
        this.selectedCity = this.cityList.find(s => s.City == localStorageAdvertFilter.il);
        if (this.selectedCity != null || this.selectedCity !== undefined)
            this.citySelected();
        this.selectedState = this.stateList.find(s => s.State == localStorageAdvertFilter.ilce);
        this.lawyerName = localStorageAdvertFilter.ad_soyad;
    }

    startDefaultSearch() {
        let saveUserFilterDataLocalStorage = localStorage.getItem('saveUserFilterData');
        if (saveUserFilterDataLocalStorage == null || saveUserFilterDataLocalStorage === undefined)
            this.defaultUserFilter()
    }
}

export function abbreviateNumber(num: number): string {
    if (num < 1000) {
      return num.toString();
    }
  
    // const abbreviations = ['', 'K', 'M', 'B', 'T'];
    // const power = Math.floor(Math.log10(num) / 3);
    // const abbreviatedNum = num / Math.pow(10, power * 3);
  
    // return num.toFixed(1) + abbreviations[power];
    return (num.toString());
  }

import { Options, Vue } from 'vue-class-component';
import FullText from '../../components/FullText.vue'
import TermsOfUseData from '@/data/static/TermsOfUseData.json'
import { FullTextModel } from '../../models/static/FullTextModel'
@Options({
    name: 'ExplicitConsentTextView',
    components: {
        FullText
    },
    methods: {
        mounted() {
            this.pageOnLoad();
        }
    },
})

export default class ExplicitConsentTextView extends Vue {
    fullTextModels: FullTextModel[] = TermsOfUseData;
    pageOnLoad(): void {
        this.fullTextModels = TermsOfUseData;
    }
}


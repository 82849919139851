
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import { Blog } from '@/models/dto/blog';
import Axios from '@/services/axios';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { catchError, from, map, of } from 'rxjs';
import axios from 'axios';
import { getUrlImageFromAxios } from '@/utilities/getImg';
@Options({
    name: 'BlogView',
    components: {
        FullText
    }
})

export default class BlogView extends Vue {
    blog: Blog = null;
    blogUrl!: string;
    imgUrl:string = null;
    isLoaded:boolean = false;
    blogDiv:any = null;
    baseUrl:string = process.env.VUE_APP_IMG_BLOG_PATH;

    mounted(): void {
        this.blogUrl = String(this.$route.params.url);
        this.getBlog(this.blogUrl);
    }

    created()
    {
        this.blogDiv = this.$refs.blogDiv as HTMLElement;
    }

    getDate(date: Date): Date {
        const moment = require('moment');
        let now = moment(date).format('DD MMMM YYYY');
        return (now);
    }

    getBlog(url: string) {
        Axios.get<SingleResponseModel<Blog>>('blog/getbyurl/' + url)
            .pipe(
                catchError(error => {
                    return of();
                })
            )
            .subscribe(response => {
                if (response.data.success == true)
                {
                    this.blog = response.data.data;
                    this.blogDiv.innerHTML = response.data.data.body;
                }
            })
    }
}

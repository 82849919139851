import { AuthService } from '@/services/authService';
import { jwtControllerService } from '@/services/jwtControllerService';
import { inject } from 'vue';

export default function notLoginGuard(to: any, from: any, next: any) {
    // let authService = inject<AuthService>('authService');
    let token = localStorage.getItem('token');
    if (token === null) {
        next()
    } else {
        next({ name: 'Anasayfa' })
    }
}
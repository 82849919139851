
import { Options, Vue } from 'vue-class-component';
import { useRouter } from 'vue-router';
import { catchError, from, map, of, tap } from 'rxjs';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { loginErrorMessage, loginHeaderMessage, loginInputErrorMessage } from '@/utilities/messages';
import { AdminService } from '@/services/adminService';
import { AdminLoginModel } from '@/models/model/adminLoginModel';
import { Inject } from 'vue-property-decorator';
@Options({
    name: 'AdminLoginView',
    components: {
    },
})

export default class AdminLoginView extends Vue {
    username: string = "";
    password: string = "";
    adminLoginModel: AdminLoginModel = null;
    router: any = useRouter();
    errorVisible: boolean = false;
    errorMessage: string = "";
    usernameError: string = "";
    passwordError: string = "";

    //services
    @Inject('adminService') adminService!: AdminService;

    // rules = {
    //     name: { required },
    //     email: { required, email },
    //     password: { required },
    //     accept: { required }
    // };

    // state = reactive({
    //         name: '',
    //         email: '',
    //         password: '',
    //         accept: null
    //     });

    // v$ = useVuelidate(this.rules, this.state);

    login() {
        this.adminLoginModel = {
            username: this.username.toString(),
            password: this.password.toString()
        };

        this.adminService.login(this.adminLoginModel)
            .pipe(
                catchError((errorResponse: ResponseModel) => {
                    this.errorVisible = true;
                    if (errorResponse.message === "Lütfen bilgilerinizi kontrol ediniz" && errorResponse.success == false)
                        this.errorMessage = loginErrorMessage;
                    if (errorResponse.message === "Please check your input" && errorResponse.success == false)
                        this.errorMessage = loginInputErrorMessage;
                    if (errorResponse.success == false)
                        this.$toast.add({ severity: 'error', summary: loginHeaderMessage, detail: this.errorMessage, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                let token: string = String(response.data.access_token);
                if (token && response.data.success) {
                    localStorage.setItem("token", token);
                    this.$router.push({ path: '/admin/panel', replace: true });
                }
            })
    }

    onSubmit() {
        if (!this.validateForm())
            return;
        this.login();
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.username) {
            this.usernameError = 'Kullanıcı adı alanı boş!';
            isValid = false;
        } else if (this.username.length < 5) {
            this.usernameError = 'Kullanıcı adı 4 karakerden az!';
            isValid = false;
        } else if (this.username.indexOf(' ') > -1) {
            this.usernameError = 'Kullanıcı adında boşluk olamaz!';
            isValid = false;
        } else {
            this.usernameError = '';
        }

        if (!this.password) {
            this.passwordError = 'Şifre alanı boş!';
            isValid = false;
        } else {
            this.passwordError = '';
        }
        return (isValid);
    }
}

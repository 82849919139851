import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1c93b08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white mt-4 mb-4" }
const _hoisted_2 = { class: "sidebar-wrapper d-block" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "px-2 mx-2 align-middle d-flex align-items-center" }
const _hoisted_6 = { class: "default-child" }
const _hoisted_7 = {
  key: 0,
  class: "px-2 mx-2 my-2 pl-3"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "text-left my-3 py-3 pl-4 px-auto mx-auto default-route-child" }
const _hoisted_10 = { style: 'text-transform:capitalize;' }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
          return (_openBlock(), _createElementBlock("li", {
            key: menuItem.title
          }, [
            _createElementVNode("div", {
              onClick: ($event: any) => {_ctx.routeForOne(menuItem); _ctx.getChildItems(menuItem.chilName); _ctx.setActivePageNameForMenuItem(menuItem)},
              class: _normalizeClass(menuItem.class),
              style: _normalizeStyle(menuItem.style)
            }, [
              _createElementVNode("label", {
                class: "align-middle d-block py-2 px-1",
                onClick: ($event: any) => {_ctx.routeChangeColor(menuItem); _ctx.childVisible(menuItem)}
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("i", {
                    class: _normalizeClass('eva ' + menuItem.icon),
                    style: _normalizeStyle('font-size: 1.5rem;' + { iconStyle: _ctx.iconStyle })
                  }, null, 6),
                  _createElementVNode("a", _hoisted_6, _toDisplayString(menuItem.title), 1),
                  (_ctx.isBreak(menuItem) == true)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: _normalizeClass('align-middle pl-1 eva ' + menuItem.chilIcon),
                        style: {"font-size":"1.5rem"}
                      }, null, 2))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_4),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuChildItems, (menuChildItem) => {
                return (_openBlock(), _createElementBlock("label", {
                  key: menuChildItem,
                  class: "align-middle bg-black d-block",
                  style: _normalizeStyle(menuChildItem.style)
                }, [
                  (menuChildItem.name === menuItem.chilName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", {
                          class: _normalizeClass(menuChildItem.class),
                          onClick: ($event: any) => {_ctx.routeChangeColorChild(menuItem, menuChildItem); _ctx.setActivePageName(menuChildItem); _ctx.childRouteForOne(menuChildItem)}
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("a", _hoisted_9, [
                              _createElementVNode("span", _hoisted_10, _toDisplayString(menuChildItem.title), 1)
                            ])
                          ])
                        ], 10, _hoisted_8)
                      ]))
                    : _createCommentVNode("", true)
                ], 4))
              }), 128))
            ], 14, _hoisted_3)
          ]))
        }), 128))
      ])
    ])
  ]))
}

import { Blog } from '@/models/dto/blog';
import { getUrlImageFromAxios } from '@/utilities/getImg';
import axios from 'axios';
import { catchError, from, map, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'HomeBlogCard',
    props: ['blog']
})

export default class HomeBlogCard extends Vue {
    blog!:Blog;
    isLoaded:boolean = false;
    imgUrl:string = "";
    baseUrl:string = process.env.VUE_APP_IMG_BLOG_PATH;

    getDate(date: Date): Date {
        const moment = require('moment');
        let now = moment(date).format('DD MM YYYY - HH.mm');
        return (now);
    }

    routeBlogDetailView(url:string)
    {
        this.$router.push({path:'/blogdetail/' + url })
    }
}

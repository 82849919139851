
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import MobileApp from '@/components/MobileApp.vue';
import { ContactModel } from '@/models/model/contactModel';
import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import { catchError, of } from 'rxjs';
import { isValidEmail } from '@/utilities/isValidEmail';
import { contactHeaderMessage, contactRequestErrorMessage, contactRequestSuccessMessage } from '@/utilities/messages';
@Options({
    name: 'ContactView',
    components: {
        FullText,
        MobileApp
    }
})

export default class MetaverseView extends Vue {
    message: string = null;
    nameSurname: string = null;
    email: string = null;

    contactModel: ContactModel;
    responseModel: ResponseModel;

    //error
    errorResponseModel: ResponseModel;

    //validaiton
    messageError: string = null;
    nameSurnameError: string = null;
    emailError: string = null;

    //disabled
    sendDisabled:boolean;

    validateForm(): boolean {
        let isValid = true;

        if (!this.email) {
            this.emailError = 'Eposta alanı boş!';
            isValid = false;
        } else if (!isValidEmail(this.email)) {
            this.emailError = 'Eposta geçersiz!';
            isValid = false;
        } else if (this.email.length < 6) {
            this.emailError = 'Eposta 5 karakerden az!';
            isValid = false;
        } else {
            this.emailError = '';
        }

        if (!this.nameSurname) {
            this.nameSurnameError = 'AdSoyad alanı boş!';
            isValid = false;
        } else {
            this.nameSurnameError = '';
        }

        if (!this.message) {
            this.messageError = 'Mesaj alanı boş!';
            isValid = false;
        } else if (this.message.length < 7) {
            this.messageError = 'Mesaj 7 karakterden büyük olmalı!';
            isValid = false;
        } else {
            this.messageError = '';
        }
        return (isValid);
    }

    contactSend() {
        this.contactModel =
        {
            adsoyad: this.nameSurname,
            mesaj: this.message,
            mail: this.email
        }
        // console.log("this.advertDto.id " + this.contactModel);
        this.sendDisabled = false;
        Axios.post<ResponseModel>("auth/contactform", this.contactModel)
            .pipe(
                catchError((errorResponse: ResponseModel) => {
                    this.errorResponseModel = errorResponse;
                    if (errorResponse.success = false)
                        this.$toast.add({ severity: 'error', summary: contactHeaderMessage, detail: contactRequestErrorMessage, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true)
                {
                    this.responseModel = response.data;
                    this.$toast.add({ severity: 'success', summary: contactHeaderMessage, detail: contactRequestSuccessMessage, life: 4000 });
                    this.sendDisabled = false;
                }
            });
        let intervalId = setInterval(() => {
            if (this.sendDisabled == false) {
                this.resetForm();
                clearInterval(intervalId);
            }
        }, 100);
    }

    resetForm() {
        let formRef = this.$refs.form as HTMLFormElement;
        if (formRef) {
            formRef.reset();
            this.message = null;
            this.nameSurname = null;
            this.email = null;
        }
    }

    onSubmit() {
        if (!this.validateForm()) {
            return;
        }
        else
            this.contactSend();
    }
}

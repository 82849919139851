
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { AuthService } from '@/services/authService';
import Axios from '@/services/axios';
import { UserService } from '@/services/userService';
import { profileHeaderMessage, profileRequestSuccessDescMessage } from '@/utilities/messages';
import { catchError, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
@Options({
    name: 'ProfileDescriptionSetting',
    components: {
    },
})
export default class ProfileDescriptionSetting extends Vue {
    userDetailDto!: UserDetailDto;
    profileDescription: string = null;

    //error
    errorResponseModel: ResponseModel = null;
    responseModel: ResponseModel = null;
    profileDescriptionError:string = '';

    @Inject('userService') userService!: UserService;
    @Inject('authService') authService!: AuthService;

    created() {
        this.getUserDetail();
    }

    getUserDetail() {
        let userId: number = this.authService.getCurrentUserId();
        this.userService.getUser(userId)
            .subscribe(response => {
                if (response.data.success) {
                    this.userDetailDto = response.data.data;
                    this.profileDescription = this.userDetailDto.profil_aciklamasi;
                }
            })
    }

    updateDescription() {
        let profileDescription: any = {
            profil_aciklamasi: this.profileDescription
        }
        Axios.put<ResponseModel>("user/updatedescription", profileDescription)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.responseModel = response;
                    this.$toast.add({ severity: 'success', summary: profileHeaderMessage, detail: profileRequestSuccessDescMessage, life: 4000 });
                }
            });
    }


    onSubmit() {
        if (!this.validateForm()) {
            return;
        }
        else {
            this.updateDescription();
        }
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.profileDescription) {
            this.profileDescriptionError = 'Açıklama alanı boş!';
            isValid = false;
        } else {
            this.profileDescriptionError = '';
        }

        return (isValid);
    }
}


import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import { profileHeaderMessage, profileRequestErrorPassMessage, profileRequestSuccessPassMessage } from '@/utilities/messages';
import { catchError, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'PasswordSetting',
    components: {
    },
})
export default class PasswordSetting extends Vue {
    oldPassword: string = null;
    newPassword: string = null;
    newPassword2: string = null;


    //error
    errorResponseModel: ResponseModel = null;
    responseModel: ResponseModel = null;
    oldPasswordError: string = '';
    newPasswordError: string = '';
    newPassword2Error: string = '';

    updatePassword() {
        let profilePassword: any = {
            oldpassword: this.oldPassword,
            sifre: this.newPassword,
            sifre2: this.newPassword2,
        }
        Axios.put<ResponseModel>("user/editpassword", profilePassword)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    if (this.errorResponseModel.success == false)
                        this.$toast.add({ severity: 'error', summary: profileHeaderMessage, detail: profileRequestErrorPassMessage, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.responseModel = response;
                    this.$toast.add({ severity: 'success', summary: profileHeaderMessage, detail: profileRequestSuccessPassMessage, life: 4000 });
                }
            });
    }

    onSubmit() {
        if (!this.validateForm()) {
            return;
        }
        else {
            this.updatePassword();
        }
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.oldPassword) {
            this.oldPasswordError = 'Şifre alanı boş!';
            isValid = false;
        } else {
            this.oldPasswordError = '';
        }

        if (!this.newPassword) {
            this.newPasswordError = 'Şifre alanı boş!';
            isValid = false;
        } else {
            this.newPasswordError = '';
        }

        if (!this.newPassword2) {
            this.newPassword2Error = 'Şifre alanı boş!';
            isValid = false;
        } else {
            this.newPassword2Error = '';
        }

        return (isValid);
    }
}


import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'NotFoundView'
})

export default class NotFoundView extends Vue {
    sizeWidth: number = window.innerWidth;
    sizeHeight: number = window.innerHeight;
    mounted(): void {
        window.addEventListener('resize', this.handleResize);
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(): void {
        this.sizeWidth = window.innerWidth;
        this.sizeHeight = window.innerHeight;
        // console.log('Window was resized');
    }
}

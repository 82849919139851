import { UserDetailDto } from "@/models/dto/userDetailDto"
import Axios from "./axios/axios"
import { SingleResponseModel } from "@/models/singleResponseModel"
import { provide } from "vue";
import { AxiosObservable } from "./axios";
import { UserFilterModel } from "@/models/model/userFilterModel";
import { UserFilter } from "@/models/entity/userFilter";
import { ResponseModel } from "@/models/responseModel/responseModel";

export class UserService {
    isUserConfirmed(userId: number, callback: (isConfirm: boolean) => void): boolean {
        let isConfirm!: boolean;
        Axios.get<SingleResponseModel<UserDetailDto>>("user/getuser/" + userId)
            .subscribe(
                response => {
                    isConfirm = Boolean(response.data.data.onay);
                    callback(isConfirm);
                });
        callback(isConfirm);
        return (isConfirm);
    }

    getUser(userId: number): AxiosObservable<SingleResponseModel<UserDetailDto>>
    {
        return Axios.get<SingleResponseModel<UserDetailDto>>("user/getuser/" + userId);
    }
    
    userFilter(userFilter:UserFilter): AxiosObservable<UserFilterModel<UserDetailDto>>
    {
        return Axios.post<UserFilterModel<UserDetailDto>>('user/filteruser', userFilter);
    }

    updateInfo(profileInfo:any) : AxiosObservable<ResponseModel>
    {

        return Axios.put<ResponseModel>("user/updateinfo", profileInfo);
    }

    updateDescription(profileDescription:any): AxiosObservable<ResponseModel>
    {
        return Axios.put<ResponseModel>("user/updatedescription", profileDescription);
    }

    updateImage(formData:FormData)
    {
        return Axios.put("user/updateimage", formData, { headers: { "Content-Type": "multipart/form-data" } });
    }

    editPassword(profilePassword: any): AxiosObservable<ResponseModel>
    {
        return Axios.put<ResponseModel>("user/editpassword", profilePassword);
    }

}

export const userServiceNew = new UserService();

provide('userService', new UserService());
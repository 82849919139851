import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12489e88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-3 mt-3 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleSignInButton = _resolveComponent("GoogleSignInButton")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GoogleSignInButton, {
      clientId: _ctx.clientId,
      size: "large",
      shape: "pill",
      scope: _ctx.scope,
      onSuccess: _ctx.onSuccess,
      onFailure: _ctx.onFailure
    }, null, 8, ["clientId", "scope", "onSuccess", "onFailure"]),
    _createVNode(_component_Toast)
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Main = _resolveComponent("Main")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Admin_Panel = _resolveComponent("Admin-Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAuthBool == false)
      ? (_openBlock(), _createBlock(_component_Main, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isAuthBool == true && _ctx.role === 'user')
      ? (_openBlock(), _createBlock(_component_Panel, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isAuthBool == true && _ctx.role === 'admin')
      ? (_openBlock(), _createBlock(_component_Admin_Panel, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}

import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import { profileHeaderMessage, profileRequestSuccessImageMessage } from '@/utilities/messages';
import { catchError, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
const MAX_FILE_SIZE = 1024 * 1024 * 5;
@Options({
    name: 'ProfilePhotoSetting',
    components: {
    },
})
export default class ProfilePhotoSetting extends Vue {
    //file
    file: File | null = null;
    maxFileSize = MAX_FILE_SIZE;
    fileStatusText: string = "Profil Fotoğrafımı Güncelle";
    fileStatusColor: string = "";
    
    //error
    errorResponseModel: ResponseModel = null;
    responseModel: ResponseModel = null;
    fileError: string = '';

    onFileSelected(event: Event) {
        const target = event.target as HTMLInputElement;
        let file: File = (target.files as FileList)[0];
        if (file && file.size <= this.maxFileSize) {
            this.file = file;
            this.fileStatusText = "Yükle";
            this.fileStatusColor = "background-color: #29CC6A !important;";
        } else {
            this.fileStatusText = "Yüklenemedi";
            this.fileStatusColor = "background-color: #FC5555 !important;";
            this.$toast.add({
                severity: 'error',
                summary: 'Profil Fotoğrafı',
                detail: 'Boyutu ' + `${this.maxFileSize / 1024 / 1024} MB'dan fazla olamaz.`,
                life: 3000
            });
            console.error(`File size should be less than ${this.maxFileSize / 1024 / 1024} MB`);
        }
    }

    uploadFile() {
        if (!this.file) {
            (this.$refs.fileInput as HTMLInputElement).click();
            return;
        }
        const formData = new FormData();
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const extension = this.file.name.split('.').pop()?.toLowerCase();
        let file!: File;
        if (extension && allowedExtensions.includes(extension))
            file = new File([this.file], String(this.file.name), { type: `image/${extension}` });
        formData.append('profile_image', file);
        Axios.put("user/updateimage", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .pipe(
                catchError((errorResponse: ResponseModel) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Profil',
                        detail: 'Profil Resim Başarısız',
                        life: 3000
                    });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.$toast.add({ severity: 'success', summary: profileHeaderMessage, detail: profileRequestSuccessImageMessage, life: 4000 });
                    this.fileStatusText = "Profil Fotoğrafımı Güncelle";
                    this.fileStatusColor = "";
                    delete this.file;
                    this.file = null;
                }
            });
    }

    onSubmit() {
        if (!this.validateForm()) {
            return;
        }
        else {
            this.uploadFile();
        }
    }

    validateForm(): boolean {
        let isValid = true;

        if (this.file.size > this.maxFileSize) {
            this.fileError = 'Profil Resim boyutu geçersiz!';
            isValid = false;
        } else {
            this.fileError = '';
        }

        return (isValid);
    }
}

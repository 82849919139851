
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import { Emit } from 'vue-property-decorator';
import { AdvertDto } from '@/models/dto/advertDto';
import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import { catchError, of } from 'rxjs';
import { AdvertListDto } from '@/models/dto/advertListDto';
import { advertHeaderMessage, advertRequestListConfirmRejectLoadedMessage, advertRequestListConfirmSuccessLoadedMessage } from '@/utilities/messages';
@Options({
    name: 'AdminAdvertConfirm',
    components: {
        FullText
    },
    props: ['advertListDto', 'isConfirmAdvert'],
})

export default class AdminAdvertConfirm extends Vue {
    advertListDto!: AdvertListDto
    responseModel: ResponseModel = null;
    visible: boolean = true;
    isConfirmAdvert!: boolean;

    //error
    errorResponseModel: ResponseModel = null;
    errorAdvertMessage: string = null;

    confirmAdvert() {
        let advertConfirmDto =
        {
            advert_id : this.advertListDto.id,
            onay : Number(this.isConfirmAdvert)
        }
        Axios.post<ResponseModel>("admin/confirmadvert", advertConfirmDto)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    this.$toast.add({ severity: 'error', summary: advertHeaderMessage, detail: this.errorResponseModel.message, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.responseModel = response.data;
                    if (this.isConfirmAdvert)
                        this.$toast.add({ severity: 'success', summary: advertHeaderMessage, detail: advertRequestListConfirmSuccessLoadedMessage, life: 4000 });
                    else
                        this.$toast.add({ severity: 'success', summary: advertHeaderMessage, detail: advertRequestListConfirmRejectLoadedMessage, life: 4000 });
                    this.closeDialog();
                }
            });
    }

    @Emit('close-admin-advert-confirm-modal')
    closeDialog() {
        this.$emit('close-admin-advert-confirm-modal');
        this.visible = false;
    }
}


import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'HomeCommentCard',
    props: ['name', 'textx', 'image']
})
export default class HomeCommentCard extends Vue {
    name!: string;
    textx!: string;
    image!: string;
    getImgUrl(path: string) {
        var images = require.context('../assets/images', false, /\.png$/)
        return images('./' + path)
    }
}


import { AdvertDto } from '@/models/dto/advertDto';
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import CityCodes from "@/data/static/CityCodes.json";
import { CityCode } from '@/models/static/CityCode';
import Axios from '@/services/axios';
import { AdvertAddDto } from '@/models/dto/advertAddDto';
import moment from 'moment';
import { catchError, map, of } from 'rxjs';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { AdvertAddResult } from '@/models/entity/advertAddResult';
import { JobType } from '@/models/static/JobType';
import JobTypesData from '@/data/static/JobTypesData.json';
import { InsertModel } from '@/models/model/insertModel';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { Ref } from 'vue';
import { advertHeaderMessage, advertRequestErrorAddMessage, advertRequestSuccessAddMessage } from '@/utilities/messages';
@Options({
    name: 'AdvertCreateView',
    components:
    {
        FullText,
    }
})
export default class AdvertCreateView extends Vue {
    cityList: Array<CityCode> = CityCodes.CitySelect;
    stateList: Array<CityCode> = [];
    CitySelect: Array<CityCode> = [];
    StateSelect: Array<CityCode> = [];
    selectedCity: any = null;
    selectedState: any = null;
    advertDetail: string = null;
    advertBudget: number = null;
    jobDate: Date = null;
    selectedJobType: JobType = null;
    jobTypes: JobType[] = JobTypesData;

    advertAddDto: AdvertAddDto = null;
    advertAddResult: SingleResponseModel<InsertModel> = null;

    //validation
    selectedCityError: string = '';
    selectedStateError: string = '';
    selectedJobTypeError: string = '';
    advertDetailError: string = '';
    advertBudgetError: string = '';
    jobDateError: string = '';

    //disabled
    sendDisabled:boolean;

    //date
    minDate:Date = new Date();
    maxDate:Date = new Date('1-12-9999')
    created() {
        let unique: Array<CityCode> = this.cityList.filter((city, index, self) => {
            return self.findIndex(c => c.City === city.City) === index;
        });
        this.CitySelect = unique;
    }
    citySelected() {
        this.stateList.splice(0);
        this.StateSelect.splice(0);
        this.stateList = this.cityList.filter(
            (element: any) => element.City === this.selectedCity.City
        );
        this.stateList.forEach((element: any) => {
            this.StateSelect.push(element);
        });
    }
    stateSelected() {
        this.stateList = this.cityList.filter(
            (element: any) => element.State === this.selectedState.State
        );
    }
    getRouteName(): string {
        return (this.$route.name.toString())
    }
    addAdvert() {
        this.advertAddDto = {
            is_turu: this.selectedJobType.text,
            butce: this.advertBudget == null ? '0' : (this.advertBudget.toString().includes('.') ? this.advertBudget.toString().replace('.', '') : this.advertBudget.toString()),
            detay: this.advertDetail.toString(),
            il: this.selectedCity.City.toString(),
            ilce: this.selectedState.State.toString(),
            tarih: moment(this.jobDate.toDateString()).format('YYYY-MM-DD'),
        }
        Axios.post<SingleResponseModel<AdvertAddResult>>("advert/add", this.advertAddDto)
            .pipe(
                catchError((errorResponse: ResponseModel) => {
                    if (errorResponse.success == false)
                        this.$toast.add({ severity: 'error', summary: advertHeaderMessage, detail: advertRequestErrorAddMessage, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.advertAddResult = response.data;
                    this.$toast.add({ severity: 'success', summary: advertHeaderMessage, detail: advertRequestSuccessAddMessage, life: 4000 });
                    this.advertAddResult.success = false;
                    this.sendDisabled = false;
                }
            });
        let intervalId = setInterval(() => {
            if (this.sendDisabled == false) {
                this.resetForm();
                clearInterval(intervalId);
            }
        }, 1000);
    }

    resetForm() {
        let formRef = this.$refs.form as HTMLFormElement;
        if (formRef) {
            formRef.reset();
            this.selectedCity = null;
            this.selectedState = null;
            this.selectedJobType = null;
            this.advertBudget = null;
            this.advertDetail = null;
            this.jobDate = null;
        }
    }

    onSubmit() {
        if (!this.validateForm())
            return;
        this.addAdvert();
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.selectedJobType) {
            this.selectedJobTypeError = 'İş Türü boş!';
            isValid = false;
        } else {
            this.selectedJobTypeError = '';
        }

        if (Number(this.advertBudget) < 0 && Number(this.advertBudget) > 32767) {
            this.advertBudgetError = 'Bütçe alanı aralık dışında!';
            isValid = false;
        } else {
            this.advertBudgetError = '';
        }

        if (!this.advertDetail) {
            this.advertDetailError = 'İlan detayı boş!';
            isValid = false;
        } else {
            this.advertDetailError = '';
        }

        if (!this.selectedCity) {
            this.selectedCityError = 'İl boş!';
            isValid = false;
        } else {
            this.selectedCityError = '';
        }

        if (!this.selectedState) {
            this.selectedStateError = 'İlçe boş!';
            isValid = false;
        } else {
            this.selectedStateError = '';
        }

        if (!this.jobDate) {
            this.jobDateError = 'İş Tarihi boş!';
            isValid = false;
        } else {
            this.jobDateError = '';
        }
        return (isValid);
    }
}

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-678cafc1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-3" }
const _hoisted_2 = {
  key: 0,
  id: "file-help",
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onSubmit()), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("input", {
          type: "file",
          ref: "fileInput",
          accept: "image/*",
          style: {"display":"none"},
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileSelected && _ctx.onFileSelected(...args)))
        }, null, 544),
        _createVNode(_component_Button, {
          id: "file",
          ref: "fileButton",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.uploadFile())),
          label: _ctx.fileStatusText,
          class: "p-button-raised p-button-rounded2 w-100",
          style: _normalizeStyle(_ctx.fileStatusColor),
          icon: "eva eva-image",
          iconPos: "left"
        }, null, 8, ["label", "style"]),
        (_ctx.fileError)
          ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.fileError), 1))
          : _createCommentVNode("", true)
      ])
    ], 32)
  ]))
}
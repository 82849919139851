
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import PanelHomeCard from '@/components/panel/PanelHomeCard.vue';
import { ActivePageNameService } from '@/services/activePageService';
import { Link } from '@/models/static/Link';
import AdvertCard from '@/components/advert/AdvertCard.vue';
import { catchError, from, map, of } from 'rxjs';
import { AdvertDto } from '@/models/dto/advertDto';
import { ref } from 'vue';
import { ListResponseModel } from '@/models/listResponseModel';
import { AdvertPagination } from '@/models/entity/advertPagination';
import { AdvertPaginationModel } from '@/models/model/advertPaginationModel';
import Axios from '@/services/axios/axios';
import { AuthService } from '@/services/authService';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { ResponseModel } from '@/models/responseModel/responseModel';
import DataNotFound from '@/components/DataNotFound.vue';
import { Inject } from 'vue-property-decorator';
import { advertRequestInputErrorMessage, advertRequestMaxErrorMessage, advertRequestMyErrorMessage, advertRequestNotConfirmErrorMessage, advertRequestNotFoundMessage } from '@/utilities/messages';
import { UserService } from '@/services/userService';
@Options({
    name: 'PanelHomeView',
    components: {
        FullText,
        PanelHomeCard,
        AdvertCard,
        DataNotFound
    }
})

export default class PanelHomeView extends Vue {
    advertFilterModels: AdvertPaginationModel<AdvertDto> = null;
    advertFilter: AdvertPagination = null;
    accountConfirmation: boolean = null;
    activePageNameService: ActivePageNameService;
    linkAccountNotConf: Link = { name: 'Ayarlar', url: 'ayarlar' }
    linkAccountConf: Link = { name: 'Tüm İlanlar', url: '/hizmetver/tumilanlar' }

    //lazyLoad
    lazyAdvertDtos: Array<AdvertDto> = [];
    lazyPageIndex: number = 1;
    lazyPageSize: number;
    isLoading: boolean = false;
    totalCount: number = 0;

    //userActive
    userId: number;

    //error
    errorResponseModel: ResponseModel = null;

    //services
    @Inject('authService') authService!: AuthService;
    @Inject('userService') userService!: UserService;

    mounted() {
        const listElm = window;
        listElm.addEventListener('scroll', e => {
            if (Math.ceil(listElm.scrollY + listElm.innerHeight) >= document.body.scrollHeight) {
                this.lazyLoadAdvert();
            }
        });
    }
    created() {
        this.userId = this.authService.getCurrentUserId();
        this.accountConfirmation = this.userService.isUserConfirmed(this.userId, this.handleUserConfirmed)
        this.getAdverts(1);
    }
    handleUserConfirmed(isConfirm: boolean) {
        this.accountConfirmation = isConfirm;
    }
    getRouteName(): string {
        return (this.$route.name.toString())
    }
    possiblePage(): boolean {
        if (this.lazyPageIndex <= this.lazyPageSize)
            return (true);
        return (false);
    }
    lazyLoadAdvert(): void {
        if (!this.isLoading) {
            this.isLoading = true;
            setTimeout((e: any) => {
                this.getAdverts(this.lazyPageIndex);
                this.isLoading = false;
            }, 200);
        }
    }
    lazyAddAdvert(response: any) {
        response.data.data.forEach((element: AdvertDto) => {
            this.lazyAdvertDtos.push(element);
        });
    }
    getAdverts(index: number) {
        this.advertFilter = {
            page: index
        }
        Axios.post<AdvertPaginationModel<AdvertDto>>('advert/getAll', this.advertFilter)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.advertFilterModels = response.data;
                    this.totalCount = response.data.totalCount === undefined ? 0 : Number(response.data.totalCount);
                    this.lazyPageSize = Math.ceil(Number(this.totalCount) / 8);
                    if (this.possiblePage()) {
                        this.errorResponseModel = null;
                        this.lazyAddAdvert(response)
                        this.lazyPageIndex += 1;
                    }
                }
            });
    }

    notFoundFilterAdverts(): boolean {
        if (this.errorResponseModel != null && this.errorResponseModel.success == false && this.errorResponseModel.message === 'Filtreleme sonucu veri bulunamadı')
            return (true);
        return (false);
    }
    advertDtoLoad() {
        console.log("loaded");
    }
}

import { provide } from "vue";

export class WhatsappService
{
    sendMessage(phone:string, message:string)
    {
        let phoneUrl = "phone=90" + phone;
        let messageUrl = "&text=" + encodeURI(message)
        let url = "https://api.whatsapp.com/send?" + phoneUrl + messageUrl;
        window.open(
            encodeURI(url),
            '_blank'
          );
    }
}
provide('whatsappService', new WhatsappService());
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_Text = _resolveComponent("full-Text")!
  const _component_mobile_app = _resolveComponent("mobile-app")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_Text, {
        title: "Detaylı Avukat Arama",
        textx: "Avukatweb’in birçok tevkil uygulamasından farklı bir özelliği de, sisteme üye olan kişilerin avukatlara yönelik detaylı filtreleme yapma olanağının tanınmasıdır. Avukatweb’e üye olan kişiler avukatların kayıtlı oldukları baro, sahip oldukları tecrübe (ilgili baroya kayıt tarihi esas alınmaktadır), uzmanlık alanları gibi farklı parametreler üzerinden filtreleme yaparak verilecek işle ilgili en uygun avukatı bulma imkanına sahiptir. Bu noktada, Avukatweb herhangi bir yönlendirme yapmamaktadır. Avukatların zaten kayıtlı oldukları bilgileri ilgili baroların web sitesinde kamuya açık bir şekilde paylaşıldığından, Avukatweb sadece avukatların Baro sayfasından kolayca ulaşılabilen bilgileri sistematize ederek kullanıcılar açısından detaylı filtreleme yapılmasına imkân sağlamaktadır."
      })
    ]),
    _createVNode(_component_mobile_app)
  ]))
}
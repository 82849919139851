import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-lg" }
const _hoisted_2 = { class: "container-lg" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "container-lg" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_text = _resolveComponent("full-text")!
  const _component_incoming_advert_card = _resolveComponent("incoming-advert-card")!
  const _component_DeferredContent = _resolveComponent("DeferredContent")!
  const _component_data_not_found = _resolveComponent("data-not-found")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_text, {
        title: _ctx.getRouteName(),
        titleSize: 24
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.notFoundIncomingreqs() == false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_DeferredContent, { onLoad: _ctx.incomingreqsDtoLoad }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomingreqsDtos, (incomingreqsDto) => {
                    return (_openBlock(), _createBlock(_component_incoming_advert_card, {
                      key: incomingreqsDto.id,
                      incomingreqsDto: incomingreqsDto,
                      advertId: _ctx.$route.params.id
                    }, null, 8, ["incomingreqsDto", "advertId"]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["onLoad"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.notFoundIncomingreqs() == true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_data_not_found, { infoText: "Malesef, aradığın kriterlerde ilan\n                                                bulamadık" })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
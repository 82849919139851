
import { AdvertDto } from '@/models/dto/advertDto';
import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import {
    advertRequestMyErrorMessage, advertRequestMaxErrorMessage, advertRequestInputErrorMessage,
    advertRequestNotConfirmErrorMessage, advertRequestFailedErrorMessage, advertRequestNotFoundMessage, advertHeaderMessage, advertRequestSuccessMessage
} from '@/utilities/messages';
import { catchError, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import { Emit } from 'vue-property-decorator';
import FullText from '@/components/FullText.vue';

@Options({
    name: 'AdvertConfirm',
    props: ['advertDto'],
    components: {
        FullText
    }
})
export default class AdvertConfirm extends Vue {
    advertDto!: AdvertDto
    responseModel: ResponseModel = null;
    visible: boolean = true;
    //error
    errorResponseModel: ResponseModel = null;
    errorAdvertMessage: string = null;

    reqAdvert() {
        Axios.post<ResponseModel>("advert/reqadvert/" + this.advertDto.id, undefined)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    if (this.errorResponseModel.message === "Bu ilan zaten verilmiş")
                        this.errorAdvertMessage = advertRequestInputErrorMessage
                    else if (this.errorResponseModel.message === "Kendi ilanınıza başvuramazsınız")
                        this.errorAdvertMessage = advertRequestMyErrorMessage
                    else if (this.errorResponseModel.message === "Bu ilan henüz onaylanmadı")
                        this.errorAdvertMessage = advertRequestNotConfirmErrorMessage
                    else if (this.errorResponseModel.message === "İlan maksimum kullanıcı sayısına ulaştı. Bu ilana başvuru yapamazsınız")
                        this.errorAdvertMessage = advertRequestMaxErrorMessage
                    else if (this.errorResponseModel.message == "Bu ilanı bulamadık")
                        this.errorAdvertMessage = advertRequestNotFoundMessage
                    else if (this.errorResponseModel.message == "Bu ilana daha önce başvuru yaptınız")
                        this.errorAdvertMessage = advertRequestFailedErrorMessage
                    if (this.errorAdvertMessage != null && this.errorResponseModel.status != 200)
                        this.$toast.add({ severity: 'error', summary: advertHeaderMessage, detail: this.errorAdvertMessage, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.responseModel = response.data;
                    this.$toast.add({ severity: 'success', summary: advertHeaderMessage, detail: advertRequestSuccessMessage, life: 4000 });
                }
            });
        this.closeDialog();
    }

    @Emit('close-advert-confirm-modal')
    closeDialog() {
        this.$emit('close-advert-confirm-modal');
        this.visible = false;
    }
}

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ChildRoute from './views/ChildRoute.vue'
import HomeView from '../views/navbar/HomeView.vue'
import MetaverseView from '../views/navbar/MetaverseView.vue'
import HowWorkView from '../views/navbar/HowWorkView.vue'
import ContactView from '../views/navbar/ContactView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import AboutView from '../views/navbar/AboutView.vue'
import BlogView from '../views/blog/BlogView.vue'
import BlogDetailView from '../views/blog/BlogDetailView.vue'
import DeputeView from '../views/services/DeputeView.vue'
import WhyAvukatWebView from '../views/services/WhyAvukatWebView.vue'
import PrivacyPolicyView from '../views/avukatweb/PrivacyPolicyView.vue'
import LightingTextView from '../views/avukatweb/LightingTextView.vue'
import ExplicitConsentTextView from '../views/avukatweb/ExplicitConsentTextView.vue'
import InformationSecurityPolicyView from '../views/avukatweb/InformationSecurityPolicyView.vue'
import CookiePolicyView from '../views/avukatweb/CookiePolicyView.vue'
import DataControllerApplicationFormView from '../views/avukatweb/DataControllerApplicationFormView.vue'
import UserandMembershipAgreementView from '../views/avukatweb/UserandMembershipAgreementView.vue'
import CommercialElectronicMessageConfirmationView from '../views/avukatweb/CommercialElectronicMessageConfirmationView.vue'
import MetaverseLawFirmsView from '../views/services/MetaverseLawFirmsView.vue'
import LegalAdviceView from '../views/services/LegalAdviceView.vue'
import SearchLawyerView from '../views/services/SearchLawyerView.vue'
import TermsOfUseView from '../views/avukatweb/TermsOfUseView.vue'
import LoginView from '../views/user/LoginView.vue'
import BaseRegisterView from '../views/user/BaseRegisterView.vue'
import StepRegisterView from '../views/user/StepRegisterView.vue'
import ProfileInfoRegisterView from '@/views/user/ProfileInfoRegisterView.vue'
import LawyerInfoRegisterView from '@/views/user/LawyerInfoRegisterView.vue'
import PasswordForgot from '@/views/user/PasswordForgot.vue'
import PasswordResetView from '@/views/user/PasswordResetView.vue'
import loginGuard from '@/guards/loginGuard'
import notLoginGuard from '@/guards/notLoginGuard'
import PanelHomeView from '@/views/panel/PanelHomeView.vue'
import PanelSettingsView from '@/views/panel/PanelSettingsView.vue'
import AdvertCreateView from '@/views/advert/AdvertCreateView.vue'
import MyAdvertView from '@/views/advert/MyAdvertView.vue'
import MyRequestAdvertView from '@/views/advert/MyRequestAdvertView.vue'
import AllAdvertView from '@/views/advert/AllAdvertView.vue'
import IncomingAdvertView from '@/views/advert/IncomingAdvertView.vue'
// import LawyerProfileDetail from '@/components/lawyer/LawyerProfileDetail.vue'
import ComplateAdvertView from '@/views/advert/ComplateAdvertView.vue'
import LawyerSearchView from '@/views/lawyer/LawyerSearchView.vue'
import activeUserGuard from '@/guards/activeUserGuard'
import AdminLoginView from '@/views/admin/AdminLoginView.vue'
import AdvertListView from '@/views/admin/AdvertListView.vue'
import LawyerListView from '@/views/admin/LawyerListView.vue'
import BlogListView from '@/views/admin/BlogListView.vue'
import AdminHomeView from '@/views/admin/AdminHomeView.vue'
import adminLoginGuard from '@/guards/adminLoginGuard'
import BlogEditView from '@/views/admin/BlogEditView.vue'
import { UserService } from '@/services/userService'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView
  },
  {
    path: '/:pathMatch(.*)',
    name: 'bad-not-found',
    component: NotFoundView
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: notLoginGuard,
    component: HomeView
  }
  ,
  {
    path: '/panel',
    name: 'Anasayfa',
    beforeEnter: loginGuard,
    component: PanelHomeView
  }
  ,
  {
    path: '/metaverse',
    name: 'metaverse',
    component: MetaverseView
  },
  {
    path: '/nasilcalisir',
    name: 'howdoesitword',
    component: HowWorkView
  },
  {
    path: '/iletisim',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView
  },
  {
    path: '/blogdetail/:url',
    name: 'Blog Detail',
    component: BlogDetailView
  },
  {
    path: '/hakkimizda',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')

  },
  {
    path: '/giris',
    name: 'login',
    // meta: { requiresAuth: true },
    component: LoginView
  },
  {
    path: '/avukatweb',
    name: 'avukatweb',
    children: [
      {
        path: 'gizlilikpolitikasi',
        name: 'privacypolicy',
        component: PrivacyPolicyView
      },
      {
        path: 'kullanimsartlari',
        name: 'termsofuse',
        component: TermsOfUseView
      },
      {
        path: 'kvkk',
        name: 'lightingtext',
        component: LightingTextView
      },
      {
        path: 'acikrizametni',
        name: 'acikrizametni',
        component: ExplicitConsentTextView
      },
      {
        path: 'bilgiguvenligipolitikasi',
        name: 'bilgiguvenligipolitikasi',
        component: InformationSecurityPolicyView
      },
      {
        path: 'kullaniciveuyeliksozlesmesi',
        name: 'kullaniciveuyeliksozlesmesi',
        component: UserandMembershipAgreementView
      },
      {
        path: 'ticarielektronikiletionayi',
        name: 'ticarielektronikiletionayi',
        component: CommercialElectronicMessageConfirmationView
      },
      {
        path: 'verisorumlusunabassvuruformu',
        name: 'verisorumlusunabassvuruformu',
        component: DataControllerApplicationFormView
      },
      {
        path: 'cerezpolitikasi',
        name: 'cerezpolitikasi',
        component: CookiePolicyView
      }
    ]
  },
  {
    path: '/hizmetler',
    name: 'services',
    children: [
      {
        path: 'tevkil',
        name: 'deputeview',
        component: DeputeView
      },
      {
        path: 'hukikidanismanlik',
        name: 'legaladvice',
        component: LegalAdviceView
      },
      {
        path: 'detayliavukatarama',
        name: 'searchlawyer',
        component: SearchLawyerView
      },
      {
        path: 'metaversehukukburolari',
        name: 'metaverselawfirmsview',
        component: MetaverseLawFirmsView
      },
      {
        path: 'nedenavukatweb',
        name: 'whyavukatweb',
        component: WhyAvukatWebView
      }
    ],
  },
  {
    path: '/kayit',
    component: StepRegisterView,
    children: [
      {
        path: 'giris-bilgileri',
        component: BaseRegisterView
      },
      {
        path: 'avukat-bilgileri',
        component: LawyerInfoRegisterView
      },
      {
        path: 'profil-bilgileri',
        component: ProfileInfoRegisterView
      }
    ]
  },
  {
    path: '/sifre',
    children: [
      {
        path: 'sifremi-unuttum',
        component: PasswordForgot,
        beforeEnter: notLoginGuard
      },
      {
        path: 'sifremi-sifirla/:token',
        component: PasswordResetView,
        beforeEnter: notLoginGuard
      }
    ]
  },
  {
    path: '/gelenistekler/:id',
    name: 'Gelen İstekler',
    component: IncomingAdvertView,
    beforeEnter: loginGuard,
  },
  // {
  //   path: '/profildetay',
  //   name: 'Profil Detay',
  //   component: LawyerProfileDetail,
  //   beforeEnter: loginGuard,
  // },
  {
    path: '/avukatara',
    name: 'Avukat Ara',
    component: LawyerSearchView,
    beforeEnter: [loginGuard, activeUserGuard],
  },
  {
    path: '/ayarlar',
    name: 'Ayarlar',
    component: PanelSettingsView,
    beforeEnter: loginGuard,
  },
  {
    path: '/hizmetal',
    // name: 'Hizmet Al',
    children: [
      {
        path: 'ilanolustur',
        name: 'Ilan Olustur',
        component: AdvertCreateView,
        beforeEnter: [loginGuard, activeUserGuard],
      },
      {
        path: 'aktifilanlarim',
        name: 'Aktif İlanlarım',
        component: MyAdvertView,
        beforeEnter: [loginGuard, activeUserGuard],
      },
      {
        path: 'tamamlananlar',
        name: 'Tamamlananlar ',
        component: ComplateAdvertView,
        beforeEnter: [loginGuard, activeUserGuard],
      },
    ],
  },
  {
    path: '/hizmetver',
    // name: 'Hizmet Al',
    children: [
      {
        path: 'tumilanlar',
        name: 'Tüm İlanlar',
        component: AllAdvertView,
        beforeEnter: [loginGuard, activeUserGuard],
      },
      {
        path: 'basvurduklarim',
        name: 'Başvurduklarım',
        component: MyRequestAdvertView,
        beforeEnter: [loginGuard, activeUserGuard],
      },
      {
        path: 'tamamlananlar',
        name: 'Tamamlananlar',
        component: ComplateAdvertView,
        beforeEnter: [loginGuard, activeUserGuard],
      },
    ],
  },
  {
    path: '/admin',
    children: [
      {
        path: 'giris',
        name: 'Admin Giriş',
        component: AdminLoginView,
        beforeEnter: notLoginGuard,
      },
      {
        path: 'ilanlar',
        name: 'Admin İlanlar',
        component: AdvertListView,
        beforeEnter: adminLoginGuard,
      },
      {
        path: 'avukatlar',
        name: 'Admin Avukatlar',
        component: LawyerListView,
        beforeEnter: adminLoginGuard,
      }
      ,
      {
        path: 'panel',
        name: 'Admin Anasayfa',
        component: AdminHomeView,
        beforeEnter: adminLoginGuard,
      },
      {
        path: 'blog',
        children: [
          {
            path: 'bloglar',
            name: 'Admin Bloglar',
            component: BlogListView,
            beforeEnter: adminLoginGuard,
          },
          {
            path: 'guncelle/:url',
            name: 'Blog Düzenle',
            component: BlogEditView,
            beforeEnter: adminLoginGuard,
          }
          ,
          {
            path: 'ekle',
            name: 'Blog Ekle',
            component: BlogEditView,
            beforeEnter: adminLoginGuard,
          }
        ],
      }
    ],
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Menubar from 'primevue/menubar'
import Menu from 'primevue/menu'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Timeline from 'primevue/timeline';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import Password from 'primevue/password';
import Checkbox from 'primevue/checkbox';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Steps from 'primevue/steps';
import InputMask from 'primevue/inputmask';
import CascadeSelect from 'primevue/cascadeselect';
import Paginator from 'primevue/paginator';
import InputSwitch from 'primevue/inputswitch';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Badge from 'primevue/badge';
import VirtualScroller from 'primevue/virtualscroller';
import Skeleton from 'primevue/skeleton';
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage';
import FileUpload from 'primevue/fileupload';
import DeferredContent from 'primevue/deferredcontent';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Image from 'primevue/image';
import Editor from 'primevue/editor';
import MultiSelect from 'primevue/multiselect';

import { createAuth0 } from '@auth0/auth0-vue';
import VeeValidate from 'vee-validate';
import JQuery from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'eva-icons/style/eva-icons.css'

import * as dotenv from 'dotenv'

import { BehaviorSubject } from 'rxjs';
import { Vue } from 'vue-class-component'
import store from './stores/store';
import { ActivePageNameService } from './services/activePageService'
import Axios from './services/axios'
import { useRouter } from 'vue-router'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { AuthService } from './services/authService'
import { WhatsappService } from './services/whatsappService'
import { UserService } from './services/userService'
import { AdminService } from './services/adminService'

const app = createApp(App);

Axios.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token: string | null = localStorage.getItem('token');
    const router = useRouter();
    if (token) {
      config.headers.Authorization = `Bearer: ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    if (error.response?.status === 502) {
      router.push({name:'not-found'});
    }
    return Promise.reject(error);
  }
);

import GoogleSignInPlugin from "vue3-google-signin"

app.use(router)
app.provide('authService', new AuthService());
app.provide('userService', new UserService());
app.provide('adminService', new AdminService());
app.use(PrimeVue, { ripple: true })
app.use(JQuery)
app.use(ToastService)
app.use(ConfirmationService);
app.use(GoogleSignInPlugin, {
  clientId: '457143918750-ml3vvio0o3svibt1s5is67d9f31tnksv.apps.googleusercontent.com',
});
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('Button', Button)
app.component('Menubar', Menubar)
app.component('Menu', Menu)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Timeline', Timeline)
app.component('Card', Card)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('Textarea', Textarea)
app.component('Tag', Tag)
app.component('Chip', Chip)
app.component('Password', Password)
app.component('Checkbox', Checkbox)
app.component('Steps', Steps)
app.component('InputMask', InputMask)
app.component('CascadeSelect', CascadeSelect)
app.component('Paginator', Paginator)
app.component('InputSwitch', InputSwitch)
app.component('Dialog', Dialog)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Toast', Toast)
app.component('Dropdown', Dropdown)
app.component('Calendar', Calendar)
app.component('VirtualScroller', VirtualScroller)
app.component('Skeleton', Skeleton)
app.component('Badge', Badge)
app.component('Message', Message)
app.component('InlineMessage', InlineMessage)
app.component('FileUpload', FileUpload)
app.component('DeferredContent', DeferredContent)
app.component('ProgressSpinner', ProgressSpinner)
app.component('Image', Image)
app.component('Editor', Editor)
app.component('MultiSelect', MultiSelect)
app.directive('badge', BadgeDirective)
app.provide('activePageNameService', new ActivePageNameService());
app.provide('whatsappService', new WhatsappService());
app.mount('#app')

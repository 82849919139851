
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import PrivacyPolicyData from '@/data/static/PrivacyPolicyData.json'
import { FullTextModel } from '@/models/static/FullTextModel'
@Options({
    name: 'PrivacyPolicyView',
    components: {
        FullText
    },
    methods: {
        mounted() {
            this.pageOnLoad();
        }
    },
})

export default class PrivacyPolicyView extends Vue {
    fullTextModels: FullTextModel[] = PrivacyPolicyData;
    pageOnLoad(): void {
        this.fullTextModels = PrivacyPolicyData;
    }
}



import { Ref, ref } from 'vue';
import { Options, setup, Vue } from 'vue-class-component';
import { Component, Watch, Prop, Inject } from 'vue-property-decorator';
import FullText from '@/components/FullText.vue'
import { AreasOfExpertise } from '@/models/static/AreasOfExpertise';
import AreasOfExpertises from '@/data/static/AreasOfExpertises.json';
import { RegisterModel } from '@/models/model/registerModel';
import { StepRegisterModel } from '@/models/model/stepRegisterValidateModel';
import Axios from '@/services/axios';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { catchError, map, of } from 'rxjs';
import { AuthService } from '@/services/authService';
import { registerEmailFormatErrorMessage, registerEmailMessage, registerErrorMessage, registerFailBaseMessage, registerHeaderMessage, registerImageFormatErrorMessage, registerSuccessBaseMessage } from '@/utilities/messages';

const MAX_FILE_SIZE = 1024 * 1024 * 5;
@Options({
    name: 'ProfileInfoRegisterView',
    components: {
        FullText
    }
})
export default class ProfileInfoRegisterView extends Vue {
    registerModel: RegisterModel = null;
    stepRegisterValidate: StepRegisterModel = null;
    profileDetail: string = null;
    areasOfExpertises: AreasOfExpertise[] = AreasOfExpertises
    selectedAreasOfExpertise: AreasOfExpertise[] = [];
    areasOfExpertise: string = null;
    areasOfExpertiseOther: string = null;
    areasOfExpertiseOtherVisible: boolean = false;
    formData: FormData;
    file: File | null = null;
    maxFileSize = MAX_FILE_SIZE;
    registerResponseModel: ResponseModel = null;

    //error
    areasOfExpertiseError: string = null;
    fileError: string = null;
    profileDetailError: string = null;
    errorVisible: boolean = false;
    errorMessage: string = "";

    //
    fileStatusText: string = "Profil Fotoğrafımı Ekle";
    fileStatusColor: string = "";
    registerDisable: boolean = true;

    //services
    @Inject('authService') authService!: AuthService;
    created() {
        let registerModelLocalStorage = localStorage.getItem("registerForm");
        this.registerModel = JSON.parse(registerModelLocalStorage);
        let stepRegisterValidateLocalStorage = localStorage.getItem("stepRegisterValidate");
        if (stepRegisterValidateLocalStorage != null || stepRegisterValidateLocalStorage != undefined) {
            this.stepRegisterValidate = JSON.parse(stepRegisterValidateLocalStorage);
            this.stepRegisterValidate.pageIndex = 3;
            localStorage.setItem("stepRegisterValidate", JSON.stringify(this.stepRegisterValidate));
        }
        this.getRegisterForm();
    }

    registerFormChange() {

        let getRegisterFormLocalStorage = localStorage.getItem("registerForm");
        let getRegisterForm: RegisterModel = JSON.parse(getRegisterFormLocalStorage);
        this.registerModel =
        {
            ad_soyad: getRegisterForm.ad_soyad,
            sifre: getRegisterForm.sifre,
            mail: getRegisterForm.mail,
            telefon: getRegisterForm.telefon,
            dogum_tarihi: getRegisterForm.dogum_tarihi,
            baro_kayit_tarihi: getRegisterForm.baro_kayit_tarihi,
            baro_sicil_no: getRegisterForm.baro_sicil_no,
            kayitli_oldugu_baro: getRegisterForm.kayitli_oldugu_baro,
            uzmanlik_alani: this.areasOfExpertise,
            profil_aciklamasi: this.profileDetail,
            profile_image: getRegisterForm.profile_image,
        }
        localStorage.setItem("registerForm", JSON.stringify(this.registerModel));
    }

    getRegisterForm() {
        let getRegisterFormLocalStorage = localStorage.getItem("registerForm");
        let getRegisterForm: RegisterModel = JSON.parse(getRegisterFormLocalStorage);

        if (!getRegisterFormLocalStorage)
            return;
        this.profileDetail = getRegisterForm.profil_aciklamasi;
        //uzmanlık alanı getirilecek
    }

    onSubmit() {
        // console.log("this.validateForm() " + this.validateForm());
        this.getStepValidate();
        if (!this.validateForm()) {
            this.saveStepValidate(false);
            return;
        }
        else {
            this.saveStepValidate(true);
            this.registerFormChange();
            this.getRegisterModel();
            this.register();
            return;
        }
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.file) {
            this.fileError = 'Profil Resim alanı boş!';
            isValid = false;
        } else if (this.file.size > this.maxFileSize) {
            this.fileError = 'Profil Resim boyutu geçersiz!';
            isValid = false;
        } else {
            this.fileError = '';
        }

        if (!this.selectedAreasOfExpertise) {
            this.areasOfExpertiseError = 'Uzmanlık alanı boş!';
            isValid = false;
        } else if (this.selectedAreasOfExpertise.length > 3) {
            this.areasOfExpertiseError = 'Uzmanlık alanı 3 den fazla!';
            isValid = false;
        } else if (!this.areasOfExpertiseOther && this.areasOfExpertiseOtherVisible) {
            this.areasOfExpertiseError = 'Diğer Uzmanlık alanı boş!';
            isValid = false;
        } else {
            this.areasOfExpertiseError = '';
        }

        if (!this.profileDetail) {
            this.profileDetailError = 'Profil Açıklama alanı boş!';
            isValid = false;
        } else if (this.profileDetail.length > 1000) {
            this.profileDetailError = 'Profil Açıklama alanı 1000 karakterden az olmalı!' + '{' + this.profileDetail.length + '}';
            isValid = false;
        } else {
            this.profileDetailError = '';
        }
        return (isValid);
    }

    saveStepValidate(validate: boolean) {
        this.stepRegisterValidate.stepRegisterValidateModel[1].validate = validate;
        localStorage.setItem("stepRegisterValidate", JSON.stringify(this.stepRegisterValidate));
    }

    getStepValidate() {
        let stepRegisterValidate = localStorage.getItem("stepRegisterValidate");
        this.stepRegisterValidate = JSON.parse(stepRegisterValidate);
    }

    onFileSelected(event: Event) {
        const target = event.target as HTMLInputElement;
        this.file = (target.files as FileList)[0];
        if (this.file && this.file.size <= this.maxFileSize) {
            this.registerModel.profile_image = this.file;
            this.fileStatusText = "Resim Yüklendi";
            this.fileStatusColor = "border-color:#29CC6A !important; background-color: #29CC6A !important;";
            localStorage.setItem("stepRegisterValidate", JSON.stringify(this.stepRegisterValidate));
            localStorage.setItem("registerForm", JSON.stringify(this.registerModel));
        } else {
            this.fileStatusText = "Resim Yüklenemedi";
            this.fileStatusColor = "border-color:#FC5555 !important;background-color: #FC5555 !important;";
            this.$toast.add({
                severity: 'error',
                summary: 'Profil Fotoğrafı',
                detail: 'Boyutu ' + `${this.maxFileSize / 1024 / 1024} MB'dan fazla olamaz.`,
                life: 3000
            });
            console.error(`File size should be less than ${this.maxFileSize / 1024 / 1024} MB`);
        }
    }

    uploadFile() {
        this.getRegisterModel();
        (this.$refs.fileInput as HTMLInputElement).click();
        if (!this.registerModel.profile_image) return;
    }

    register() {
        let areasOfExpertiseOtherString: string = "";
        if (this.areasOfExpertiseOtherVisible && this.areasOfExpertiseOther.length > 0){
            areasOfExpertiseOtherString = this.areasOfExpertiseOther.replace(', ', ' ');
            this.areasOfExpertise = this.areasOfExpertise.replace("Diğer", "Diğer: " + areasOfExpertiseOtherString);
        }
        this.getRegisterModel();
        const formData = new FormData();
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const extension = this.file.name.split('.').pop()?.toLowerCase();
        let file: File = null;
        if (extension && allowedExtensions.includes(extension))
            file = new File([this.file], String(this.file.name), { type: `image/${extension}` });
        formData.append('profile_image', file);
        Object.entries(this.registerModel).forEach(([key, value]) => {
            if (key !== 'profile_image') {
                formData.append(key, value);
            }
        });
        this.authService.register(formData, { headers: { "Content-Type": "multipart/form-data" } })
            .pipe(
                catchError((error: ResponseModel) => {
                    this.errorVisible = true;
                    if (error.message === "This email is already exist" && error.success == false)
                        this.errorMessage = registerEmailMessage;
                    else if (error.message === "Please provide all inputs" && error.success == false)
                        this.errorMessage = registerErrorMessage;
                    else if (error.message === "Email formatı yanlış" && error.success == false)
                        this.errorMessage = registerEmailFormatErrorMessage;
                    else if (error.message === "Please provide a valid image file" && error.success == false)
                        this.errorMessage = registerImageFormatErrorMessage;
                    if (error.success == false)
                        this.$toast.add({ severity: 'error', summary: registerHeaderMessage, detail: this.errorMessage, life: 4000 });
                    return of();
                })
            )
            .subscribe(response => {
                this.errorVisible = false;
                this.registerResponseModel = response.data;
                if (response.data.success == true && response.data.message === "Register Successfull") {
                    localStorage.clear();
                    this.registerDisable = false;
                    this.$toast.add({ severity: 'success', summary: registerHeaderMessage, detail: registerSuccessBaseMessage, life: 6000 });
                }
            });
        let intervalId = setInterval(() => {
            if (this.registerResponseModel.success == true) {
                this.$router.push({ path: '/giris', replace: true });
                clearInterval(intervalId);
            }
        }, 4000);
    }

    getRegisterModel() {
        let getRegisterFormLocalStorage = localStorage.getItem("registerForm");
        this.registerModel = JSON.parse(getRegisterFormLocalStorage);
    }

    findAreasOfExpertiseOther(): boolean {
        if (!this.selectedAreasOfExpertise)
            return (false);
        return (this.selectedAreasOfExpertise.filter(x => x.id == 33).length > 0);
    }

    arrayToStringWithCommas(arr: any[]): string {
        return arr.map(expertise => expertise.text).join(', ');
    }

    areasOfExpertiseChange() {
        this.areasOfExpertise = this.arrayToStringWithCommas(this.selectedAreasOfExpertise);
        this.areasOfExpertiseOtherVisible = this.findAreasOfExpertiseOther();
    }

    areasOfExpertiseOtherChange() {
        this.areasOfExpertiseChange();
    }

    getOtherAreaOfExpertise(strs: string[]): string {
        let index: number = 0;
        let result: string = '';
        [...strs].forEach(res => {
            index = res.toLowerCase().indexOf('diğer:');
            if (index > -1)
                result = res.substring(index + 6).trim();
        })
        return (result);
    }

    getProfession() {
        let getRegisterFormLocalStorage = localStorage.getItem("registerForm");
        let getRegisterForm: RegisterModel = JSON.parse(getRegisterFormLocalStorage);
        let areasOfExpertisesString: string[] = getRegisterForm.uzmanlik_alani.split(', ');

        if (getRegisterForm.uzmanlik_alani == null)
            return ;
        [...areasOfExpertisesString].forEach(str => {
            let trimmedStr = str.trim();
            let areaOfExpertise = this.areasOfExpertises.find(area => area.text.toLowerCase() === trimmedStr.toLowerCase() && trimmedStr.toLowerCase().search('diğer:') === -1);
            if (areaOfExpertise) {
                this.selectedAreasOfExpertise.push({ id: areaOfExpertise.id, text: areaOfExpertise.text });
            }

            if (trimmedStr.toLowerCase().search('diğer:') !== -1) {
                this.selectedAreasOfExpertise.push({ id: 33, text: "Diğer" });
                this.areasOfExpertiseOtherVisible = true;
                this.areasOfExpertiseOther = this.getOtherAreaOfExpertise(areasOfExpertisesString);
            }
        });
    }
}

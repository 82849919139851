
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'MobileApp',
})

export default class MobileApp extends Vue {
    sizeWight: number = window.innerWidth;
    mounted(): void {
        window.addEventListener('resize', this.handleResize);
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(): void {
        this.sizeWight = window.innerWidth;
    }
}

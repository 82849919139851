
import { Options, Vue } from 'vue-class-component';
import { Component, Watch, Prop, Inject } from 'vue-property-decorator';
import { AuthService } from '@/services/authService'
import { LoginModel } from '@/models/model/loginModel';
import { catchError, from, map, of, tap } from 'rxjs';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { loginErrorMessage, loginFailBaseMessage, loginHeaderMessage, loginInputErrorMessage, loginSuccessBaseMessage } from '@/utilities/messages';
import { isValidEmail } from '@/utilities/isValidEmail';
import GoogleAuthLogin from '@/components/login/GoogleAuthLogin.vue';
import FacebookAuthLogin from '@/components/login/FacebookAuthLogin.vue';
@Options({
    name: 'LoginView',
    components: {
        GoogleAuthLogin,
        FacebookAuthLogin
    },
})

export default class LoginView extends Vue {
    loginModel: LoginModel = null;
    email: string = null;
    password: string = null;

    //error
    errorVisible: boolean = false;
    errorMessage: string = "";
    emailError: string = "";
    passwordError: string = "";

    //services
    @Inject('authService') authService!: AuthService;

    login() {
        const loginModel = {
            mail: this.email.toString(),
            sifre: this.password.toString()
        };

        this.authService.login(loginModel)
            .pipe(
                catchError((error: ResponseModel) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: loginHeaderMessage,
                        detail: loginFailBaseMessage,
                        life: 4000
                    });
                    this.errorVisible = true;
                    if (error.message === "Lütfen bilgilerinizi kontrol ediniz" && error.success == false)
                        this.errorMessage = loginErrorMessage;
                    if (error.message === "Please check your input" && error.success == false)
                        this.errorMessage = loginInputErrorMessage;
                    console.log("error : " + error.message + " " + error.success);
                    return of();
                })
            )
            .subscribe(response => {
                let token: string = String(response.data.access_token);
                if (token && response.data.success) {
                    localStorage.setItem("token", token);
                    this.$toast.add({ severity: 'success', summary: loginHeaderMessage, detail: loginSuccessBaseMessage, life: 4000 });
                    this.$router.push({ path: '/panel', replace: true });
                }
            })
    }

    onSubmit() {
        if (!this.validateForm()) {
            return;
        }
        else
            this.login();
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.email) {
            this.emailError = 'Eposta alanı boş!';
            isValid = false;
        } else if (!isValidEmail(this.email)) {
            this.emailError = 'Eposta geçersiz!';
            isValid = false;
        } else if (this.email.length < 6) {
            this.emailError = 'Eposta 5 karakerden az!';
            isValid = false;
        } else {
            this.emailError = '';
        }

        if (!this.password) {
            this.passwordError = 'Şifre alanı boş!';
            isValid = false;
        } else if (this.password.length < 7) {
            this.passwordError = 'Şifre 7 karakterden büyük olmalı!';
            isValid = false;
        } else {
            this.passwordError = '';
        }
        return (isValid);
    }
}

import { UserDetailDto } from '@/models/dto/userDetailDto';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { authServiceNew } from '@/services/authService';
import Axios from '@/services/axios/axios';

export default function activeUserGuard(to: any, from: any, next: any) {
  const authService = authServiceNew;
  const userId = authService.getCurrentUserId();
  Axios.get<SingleResponseModel<UserDetailDto>>("user/getuser/" + userId)
  .subscribe(
    response => {
      if (Boolean(response.data.data.onay)) {
        next()
      } else {
        next({ name: 'Anasayfa' })
      }
    });
}
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-089d6a4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-12 mt-2" }
const _hoisted_3 = { class: "cl-md-12 mt-2" }
const _hoisted_4 = { class: "cl-md-12 mt-2" }
const _hoisted_5 = { class: "p-sub-title" }
const _hoisted_6 = { class: "col-md-12" }
const _hoisted_7 = { class: "p-main-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", {
          style: _normalizeStyle({ 'font-size': _ctx.titleSize + 'px' }),
          class: "p-main-title"
        }, _toDisplayString(_ctx.title), 5)
      ], 512), [
        [_vShow, _ctx.title !== null && _ctx.title !== undefined]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.subTitle), 1)
      ], 512), [
        [_vShow, _ctx.subTitle !== null && _ctx.subTitle !== undefined]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.textx), 1)
    ], 512), [
      [_vShow, _ctx.textx !== null && _ctx.textx !== undefined]
    ])
  ]))
}
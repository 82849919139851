import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cee56426"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.searchObject !== null || _ctx.searchObject !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (property) => {
            return (_openBlock(), _createElementBlock("div", {
              key: property.key,
              style: {"display":"inline-block"}
            }, [
              (property.value !== '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_Chip, {
                      label: property.value,
                      class: "mb-2 mr-2 custom-chip",
                      onRemove: ($event: any) => (_ctx.remove(property)),
                      removable: true
                    }, null, 8, ["label", "onRemove"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}

import { Options, Vue } from 'vue-class-component';
import Main from '@/containers/Main.vue'
import Panel from './containers/Panel.vue';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '@/services/authService'
import { Inject, Watch } from 'vue-property-decorator';
import AdminPanel from './containers/AdminPanel.vue';
import { AdminService } from './services/adminService';

@Options({
  name: 'App',
  components: {
    Main,
    Panel,
    AdminPanel
  }
})
export default class App extends Vue {
  loading: boolean = true;
  isAuthenticadet: boolean = false;
  isAuthBool: boolean = false;
  data: any = new BehaviorSubject<boolean>(false);
  role: string = null;
  isNotFoundPage: boolean = false;

  //services
  @Inject('authService') authService!: AuthService;
  @Inject('adminService') adminService!: AdminService;

  mounted() {
    this.checkTokenStatus();
    // console.log('token' + localStorage.getItem('token'));
    const token = localStorage.getItem('token');
    this.isAuthBool = token !== null && token !== undefined;
    this.role = this.authService.getCurrentRole();
    // this.loading = false;
  }

  @Watch('isAuthenticadet')
  onTokenChange(isValid: boolean) {
    if (!isValid && localStorage.getItem('token') != null) {
      localStorage.removeItem('token');
      this.$router.push({ name: 'home' });
    }
  }

  // Token durumunu kontrol etmek için fonksiyon
  checkTokenStatus() {
    this.isAuthenticadet = this.authService.isAuthenticadet();
    if (!this.isAuthenticadet && localStorage.getItem('token') != null) {
      localStorage.removeItem('token');
      this.$router.push({ path: '/' });
    }
  }

  beforeCreate() {
    let intervalId = setInterval(() => {
      this.updateAuthBool();
      if (this.isAuthBool == true && this.authService.isAuthenticadet()) {
        clearInterval(intervalId);
      }
    }, 100);
  }

  beforeUpdate() {
    let intervalId = setInterval(() => {
      this.updateAuthBool();
      if (this.isAuthBool == false && this.authService.isAuthenticadet()) {
        clearInterval(intervalId);
      }
    }, 100);
  }

  updateAuthBool() {
    const token = localStorage.getItem('token');
    this.isAuthBool = token !== null && token !== undefined;
    this.role = this.authService.getCurrentRole();
  }
}

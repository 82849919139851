
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'Header'
})
export default class Header extends Vue {
    routeHomePage()
    {
        this.$router.push({path:'/'});
    }
}


import { Options, Vue } from 'vue-class-component';
import { ActivePageNameService } from '@/services/activePageService'
import { LocalStorageService, provideLocalStorageService, useLocalStorageService } from '@/services/localStorageService';
import { AuthService } from '@/services/authService'
import { Component, Inject, Ref } from 'vue-property-decorator';
import { useRouter } from 'vue-router';
import { UserService } from '@/services/userService';
@Options({
    name: 'AdminPanelSideBar',
})

export default class AdminPanelSideBar extends Vue {
    localAuth: boolean = false;
    role!: string;
    roleCheckClass!: string;
    router = useRouter();
    menuItems: RouteInfo[] = [];
    menuChildItems: any[] = [];
    menuChildedItems!: RouteChilInfo[];
    menuChildVisibleClass!: string;
    menuChildVisibleBoolean: boolean = true;
    breakMathIconName: string = "eva-arrow-ios-downward-outline";
    visibleSidebar1: any;
    activePageNameService: ActivePageNameService;
    localStorageService!: LocalStorageService
    iconStyle: string = "";
    accountConfirmation: boolean = null;

    //userActive
    userId: number;

    //services
    @Inject('authService') authService!: AuthService;
    @Inject('userService') userService!: UserService;

    setup() {
        this.localStorageService = useLocalStorageService()

    }
    mounted() {
        // this.activePageNameService.pageName = "Home";
    }

    created() {
        this.getMenu();
        this.routeLocalStorageGetColor();
        this.userId = this.authService.getCurrentUserId();
        // this.accountConfirmation = this.userService.isUserConfirmed(this.userId, this.handleUserConfirmed)
    }

    // handleUserConfirmed(isConfirm: boolean) {
    //     this.accountConfirmation = isConfirm;
    // }

    getMenu() {

        // this.role = authService.getCurrentRole();
        this.authService.roleName.subscribe(response => {
            this.role = response;
        })
        //console.log(this.role);

        // if (this.role != null && this.role.indexOf("developer") > -1) {
        this.roleCheckClass = "";
        ROUTES.forEach(element => {
            if (element.chilName === "claim") {
                element.class = ""
            }
        });
        // } else {
        //     ROUTES.forEach(element => {
        //         if (element.chilName === "claim") {
        //             element.class = "d-none"
        //             //console.log("yok")
        //         }

        //     });
        // }

        this.authService.isAuth.subscribe(response => {
            if (response) {
                this.localAuth = response
                //console.log(response)
            }
        })
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.menuChildItems = ROUTESCHIL.filter(menuchilItem => menuchilItem);
    }

    getChildItems(name: string) {
        this.menuChildedItems = this.menuChildItems.filter(p => p.name == name)
        console.log(this.menuChildedItems)
    }
    isNameIsChildName(name: string, nameChild: string) {
        if (name === nameChild) {
            return true;
        }
        return false
    }
    setActivePageName(object: any) {
        console.log("object.title " + object.title);
        // this.activePageNameService = object.title;
        // this.activePageNameService.saveActivePage(object.title.toString());
        this.menuChildVisibleBoolean = true;
        this.menuChildVisibleClass = "";
    }
    setActivePageNameForMenuItem(object: any) {
        if (ROUTESCHIL.filter(filtre => filtre.name == object.chilName).length == 0) {
            console.log("object.title " + object.title);
            // this.activePageNameService.pageName = object.title.toString();
            // this.activePageNameService.saveActivePage('object.title.toString()');
        }
    }
    routeForOne(object: RouteInfo) {
        if (ROUTESCHIL.filter(filtre => filtre.name == object.chilName).length == 0) {
            this.router.push({ path: object.path })
        }
    }
    childRouteForOne(object: RouteChilInfo) {
        if (this.accountConfirmation == false)
            return;
        // if (ROUTESCHIL.filter(filtre => filtre.name == object.chilName).length == 0) {
        this.router.push({ path: object.path })
        // }
    }
    childVisible(object: any) {
        this.menuChildItems.forEach(element => {
            if (element.name == object.chilName) {
                if (element.class === "") {
                    element.class = "d-none"
                    object.chilIcon = "eva-arrow-ios-forward-outline"
                } else if (element.class === "d-none") {
                    element.class = ""
                    object.chilIcon = "eva-arrow-ios-downward-outline"
                }
            } else if (element.name != object.chilName) {
                //element.class = ""
            }
        });
    }
    isBreak(object: any): boolean {
        if (ROUTESCHIL.filter(filtre => filtre.name == object.chilName).length != 0) {
            return true;
        }
        return false;
    }
    routeChangeColor(object: any) {
        if (this.menuChildItems.filter(filtre => filtre.name == object.chilName).length == 0) {
            this.menuItems.forEach(element => {
                if (element.title === object.title) {
                    object.style = "color:#0070D9!important;";
                } else if (element.title !== object.title) {
                    // object.style = "color:#97A2B0;";
                }
            });
        }
        if (this.menuChildItems.filter(filtre => filtre.name == object.chilName).length == 0) {
            this.menuChildItems.forEach(element => {
                element.style = "";

            });
        }
    }
    routeChangeColorChild(object: any, chilObject: any) {
        console.log("routeChangeColorChild");
        if (this.accountConfirmation == false)
            return;
        if (this.menuChildItems.filter(filtre => filtre.name == object.chilName).length != 0) {
            this.menuChildItems.forEach(element => {
                if (element.name === object.chilName && element.title === chilObject.title) {
                    element.style = "color:#e9ecef;font-weight: bold;"
                    // this.routeLocalStorageSaveColor();
                } else if (element.title !== chilObject.title || element.name !== object.chilName) {
                    element.style = ""
                }
            });
        }
        this.menuItems.forEach(element => {
            element.style = ""
        });
    }
    routeLocalStorageSaveColor() {
        // let menuChildVisibility: string[] = [];
        // this.menuChildItems.forEach(element => {
        //     menuChildVisibility.push(element.style)
        // });
        // this.localStorageService.saveItem("menuChilVisibilityColor", menuChildVisibility.toString());
    }
    routeLocalStorageGetColor() {
        // let menuChildVisibilityGet: any = this.localStorageService.get("menuChilVisibilityColor");
        // let menuChildVisibility:string[] = menuChildVisibilityGet.split(",")
        // for (let index = 0; index < menuChildVisibility.length; index++) {
        //   this.menuChildItems[index].style=menuChildVisibility
        // }
    }
}
export const ROUTES: RouteInfo[] = [
    { path: 'none', title: 'Kullanıcı', chilName: "get_users", chilIcon: "eva-arrow-ios-downward", icon: 'eva-briefcase-outline', class: '', style: '' },
    { path: 'none', title: 'İlan', chilName: "get_adverts", chilIcon: "eva-arrow-ios-downward", icon: 'eva-globe-outline', class: '', style: '' },
    { path: 'none', title: 'Blog', chilName: "get_blogs", chilIcon: "eva-arrow-ios-downward", icon: 'eva-globe-outline', class: '', style: '' },
    //{ path: 'none', title: 'Kategoriler', chilName: "category", icon: 'category', class: '' },
    // { path: '/table', title: 'Table List', chilName: "car1", icon: 'assistant', class: '' },
    // { path: '/typography', title: 'Typography', chilName: "car1", icon: 'assistant', class: '' },
    // { path: '/upgrade', title: 'Upgrade to PRO', chilName: "car1", icon: 'assistant', class: '' },
];
export interface RouteInfo {
    path: string;
    chilName: string;
    chilIcon: string;
    title: string;
    icon: string;
    class: string;
    style: string;
}
export const ROUTESCHIL: RouteChilInfo[] = [
    { path: '/admin/ilanlar', name: "get_adverts", title: 'İlanlar', icon: 'arrow_right', class: '', style: '' },
    { path: '/admin/avukatlar', name: "get_users", title: 'Avukatlar', icon: 'arrow_right', class: '', style: '' },
    { path: '/admin/blog/bloglar', name: "get_blogs", title: 'Bloglar', icon: 'arrow_right', class: '', style: '' },
];
export interface RouteChilInfo {
    name: string
    path?: string;
    title: string;
    icon: string;
    class: string;
    style: string;
}


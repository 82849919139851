
import { Options, Vue } from 'vue-class-component';
import FaqData from '@/data/static/FaqData.json';
import HomeCardData from '@/data/static/HomeCardData.json';
import { HomeCardModel } from '@/models/static/HomeCardModel';
import HomeCommentCardData from '@/data/static/HomeCommentCardData.json';
import { HomeCommentCardModel } from '@/models/static/HomeCommentCardModel';
import { Faq } from '@/models/static/Faq';
import { Component, Inject } from 'vue-property-decorator';
import { ActivePageNameService } from '@/services/activePageService';
@Options({
  name: 'AdminHomeView',
  components: {
  },
})
export default class AdminHomeView extends Vue {
  faqs: Faq[] = FaqData;
  homeCards: HomeCardModel[] = HomeCardData;
  homeCommentCards: HomeCommentCardModel[] = HomeCommentCardData;
  @Inject('activePageNameService') myService!: ActivePageNameService;

  mounted() {
    this.someMethod()
  }
  someMethod(): void {
    this.myService.saveActivePage("Home");
    console.log("this.myService.loadActivePage()" + this.myService.loadActivePage());
  }
}


import { Blog } from '@/models/dto/blog';
import { getDateFormat } from '@/utilities/getFormat';
import { getUrlImageFromAxios } from '@/utilities/getImg';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'Accordion',
    props: ['blog']
})

export default class BlogCard extends Vue {
    blog!: Blog;
    imgUrl: string;
    baseUrl:string = process.env.VUE_APP_IMG_BLOG_PATH;
    isLoaded: boolean = false;

    getDate(date: Date): Date {
        return (getDateFormat(date, 'DD MMMM YYYY'));
    }

    routeDetailPage(url: string) {
        this.$router.push({ path: '/blogdetail/' + url })
    }
}

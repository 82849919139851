
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import PanelHomeCard from '@/components/panel/PanelHomeCard.vue';
import { ActivePageNameService } from '@/services/activePageService';
import { catchError, from, map, of } from 'rxjs';
import { AdvertDto } from '@/models/dto/advertDto';
import Axios from '@/services/axios';
import { ListResponseModel } from '@/models/listResponseModel';
import AdvertCard from '@/components/advert/AdvertCard.vue';
import AdvertSearchView from './AdvertSearchView.vue';
@Options({
    name: 'AllAdvertView',
    components: {
        FullText,
        PanelHomeCard,
        AdvertCard,
        AdvertSearchView
    }
})

export default class AllAdvertView extends Vue {
    advertDtos: AdvertDto[] = []
    accountConfirmation: boolean = true
    activePageNameService: ActivePageNameService;

    getRouteName(): string {
        return (this.$route.name.toString())
    }
}



import { TokenModel } from '@/models/model/tokenModel';
import Axios from '@/services/axios/axios';
import { googleLoginErrorAPIMessage, googleLoginFailBaseMessage, googleLoginHeaderMessage, googleLoginSuccessBaseMessage } from '@/utilities/messages';
import { catchError, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import {
  GoogleSignInButton,
  type CredentialResponse,
} from "vue3-google-signin";
import { useTokenClient, AuthCodeFlowSuccessResponse, AuthCodeFlowErrorResponse } from "vue3-google-signin";
@Options({
  name: 'GoogleAuthLogin',
  components: {
    GoogleSignInButton
  }
})
export default class GoogleAuthLogin extends Vue {
  clientId: string = '457143918750-ml3vvio0o3svibt1s5is67d9f31tnksv.apps.googleusercontent.com';
  scope: string = 'profile email';
  isReady: boolean = false;

  // async login(): Promise<void> {
  //   const options: any = {
  //     onSuccess: this.onSuccess,
  //     onError: this.onFailure,
  //     prompt: "consent",
  //     scope: "email",
  //   };

  //   const { isReady:boolean, login } = useTokenClient(options);
  //   this.isReady = this.isReady;

  //   await login();
  // }

  onSuccess(response: AuthCodeFlowSuccessResponse) {

    // console.log('Signed in successfully!', response);
    Axios.post<TokenModel>("auth/googleLogin", response)
      .pipe(
        catchError(error => {
          this.$toast.add({
            severity: 'error',
            summary: googleLoginHeaderMessage,
            detail: googleLoginFailBaseMessage,
            life: 4000
          });
          return of(null);
        })
      )
      .subscribe(response => {
        let token: string = String(response.data.access_token);
        if (response.data.success == true) {
          if (token && response.data.success) {
            localStorage.setItem("token", token);
            this.$toast.add({ severity: 'success', summary: googleLoginHeaderMessage, detail: googleLoginSuccessBaseMessage, life: 4000 });
            this.$router.push({ path: '/panel', replace: true });
          }
        }
      });
    // Do something with the user data, like send it to your backend server
  }

  onFailure(error: AuthCodeFlowErrorResponse) {
          this.$toast.add({
            severity: 'error',
            summary: googleLoginHeaderMessage,
            detail: googleLoginErrorAPIMessage,
            life: 4000
          });
    // console.error('Failed to sign in:', error);
  }

  login():void
  {
    (this.$refs.fileInput as HTMLInputElement).click();
  }
}



import { AreasOfExpertise } from '@/models/static/AreasOfExpertise';
import { Options, Vue } from 'vue-class-component';
import AreasOfExpertises from '@/data/static/AreasOfExpertises.json';
import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import { catchError, of } from 'rxjs';
import { profileHeaderMessage, profileRequestSuccessProfessionMessage } from '@/utilities/messages';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { Inject } from 'vue-property-decorator';
import { UserService } from '@/services/userService';
import { AuthService } from '@/services/authService';

@Options({
    name: 'AreasOfExpertisesSetting',
    components: {
    },
})
export default class AreasOfExpertisesSetting extends Vue {
    userDetailDto!: UserDetailDto;
    areasOfExpertises: AreasOfExpertise[] = AreasOfExpertises
    selectedAreasOfExpertise: AreasOfExpertise[] = [];
    areasOfExpertise: string = null;
    areasOfExpertiseOther: string = null;
    areasOfExpertiseOtherVisible: boolean = false;

    //error
    areasOfExpertiseError: string = null;
    errorResponseModel: ResponseModel = null;
    responseModel: ResponseModel = null;

    @Inject('userService') userService!: UserService;
    @Inject('authService') authService!: AuthService;

    created() {
        this.getUserDetail();
    }

    getUserDetail() {
        let userId: number = this.authService.getCurrentUserId();
        this.userService.getUser(userId)
            .subscribe(response => {
                if (response.data.success) {
                    this.userDetailDto = response.data.data;
                    this.getProfession();
                }
            })
    }

    getProfession() {
        let areasOfExpertisesString: string[] = this.userDetailDto.uzmanlik_alani.split(', ');

        [...areasOfExpertisesString].forEach(str => {
            let trimmedStr = str.trim();
            let areaOfExpertise = this.areasOfExpertises.find(area => area.text.toLowerCase() === trimmedStr.toLowerCase() && trimmedStr.toLowerCase().search('diğer:') === -1);
            if (areaOfExpertise) {
                this.selectedAreasOfExpertise.push({ id: areaOfExpertise.id, text: areaOfExpertise.text });
            }

            if (trimmedStr.toLowerCase().search('diğer:') !== -1) {
                this.selectedAreasOfExpertise.push({ id: 33, text: "Diğer" });
                this.areasOfExpertiseOtherVisible = true;
                this.areasOfExpertiseOther = this.getOtherAreaOfExpertise(areasOfExpertisesString);
            }
        });

        // [...this.selectedAreasOfExpertise].forEach(res => { console.log(res) });
    }

    updateProfession() {
        let areasOfExpertiseOtherString: string = this.areasOfExpertiseOther.replace(', ', ' ');
        let areasOfExpertiseReplace: string;
        areasOfExpertiseReplace = this.areasOfExpertise.replace("Diğer", "Diğer: " + areasOfExpertiseOtherString);
        this.areasOfExpertise = areasOfExpertiseReplace;
        let profileProfession: any = {
            uzmanlik_alani: this.areasOfExpertise
        }
        Axios.put<ResponseModel>("user/updateprofession", profileProfession)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.responseModel = response;
                    this.$toast.add({ severity: 'success', summary: profileHeaderMessage, detail: profileRequestSuccessProfessionMessage, life: 4000 });
                }
            });
    }

    getOtherAreaOfExpertise(strs: string[]): string {
        let index: number = 0;
        let result: string = '';
        [...strs].forEach(res => {
            index = res.toLowerCase().indexOf('diğer:');
            if (index > -1)
                result = res.substring(index + 6).trim();
        })
        return (result);
    }

    findAreasOfExpertiseOther(): boolean {
        if (!this.selectedAreasOfExpertise)
            return (false);
        return (this.selectedAreasOfExpertise.filter(x => x.id == 33).length > 0);
    }

    arrayToStringWithCommas(arr: any[]): string {
        return arr.map(expertise => expertise.text).join(', ');
    }

    areasOfExpertiseChange() {
        this.areasOfExpertise = this.arrayToStringWithCommas(this.selectedAreasOfExpertise);
        this.areasOfExpertiseOtherVisible = this.findAreasOfExpertiseOther();
    }

    areasOfExpertiseOtherChange() {
        this.areasOfExpertiseChange();
    }

    onSubmit() {
        if (!this.validateForm()) {
            return;
        }
        else {
            this.updateProfession();
        }
    }

    validateForm(): boolean {
        let isValid = true;

        if (!this.selectedAreasOfExpertise) {
            this.areasOfExpertiseError = 'Uzmanlık alanı boş!';
            isValid = false;
        } else if (this.selectedAreasOfExpertise.length > 3) {
            this.areasOfExpertiseError = 'Uzmanlık alanı 3 den fazla!';
            isValid = false;
        } else if (!this.areasOfExpertiseOther && this.areasOfExpertiseOtherVisible) {
            this.areasOfExpertiseError = 'Diğer Uzmanlık alanı boş!';
            isValid = false;
        } else {
            this.areasOfExpertiseError = '';
        }

        return (isValid);
    }
}

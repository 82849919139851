
import { AdvertDto } from '@/models/dto/advertDto';
import { ref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import AdvertDetail from './AdvertDetail.vue';
import AdvertConfirm from './AdvertConfirm.vue';
import { param } from 'jquery';
import LawyerProfileDetail from '../lawyer/LavyerProfileDetail.vue';
import { IncomingreqsDto } from '@/models/dto/incomingreqsDto';
import Axios from '@/services/axios/axios';
import { SingleResponseModel } from '@/models/singleResponseModel';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { Inject } from 'vue-property-decorator';
import { WhatsappService } from '@/services/whatsappService';
import { advertHeaderMessage, advertRequestErrorRejectMessage, advertRequestInputErrorMessage, advertRequestListConfirmRejectLoadedMessage, advertRequestListConfirmSuccessLoadedMessage, whatsappServiceMessage } from '@/utilities/messages';
import { catchError, map, of } from 'rxjs';
import { InsertModel } from '@/models/model/insertModel';
import { AxiosResponse } from 'axios';
import { getUrlImageFromAxios } from '@/utilities/getImg';

@Options({
    name: 'IncomingAdvertCard',
    props: ['incomingreqsDto', "advertId"],
    components:
    {
        AdvertDetail,
        AdvertConfirm,
        LawyerProfileDetail
    }
})
export default class IncomingAdvertCard extends Vue {
    incomingreqsDto!: IncomingreqsDto;
    advertId!: number;
    insertModel: InsertModel = null;
    userDetailDto: UserDetailDto = null;
    advertDto: AdvertDto = null;
    activeText: string = "";
    isLoaded: boolean = false;
    
    lawyerProfileDisplayModal: boolean = false;
    baseUrl:string = process.env.VUE_APP_IMG_PROFILE_PATH;

    @Inject('whatsappService') whatsappService!: WhatsappService;

    mounted(): void {
        if (this.incomingreqsDto != null || this.incomingreqsDto !== undefined) {
            this.getUserDetail();
        }
    }
    
    created(): void {
        this.getAdvertDto();
    }

    getUserDetail() {
        let userId: number;

        userId = Number(this.incomingreqsDto.id);
        Axios.get<SingleResponseModel<UserDetailDto>>("user/getuser/" + userId)
            .subscribe(response => {
                this.userDetailDto = response.data.data;
                this.isActive();
            })
    }

    getAdvertDto() {
        Axios.get<SingleResponseModel<AdvertDto>>('advert/getadvertdetail/' + this.advertId)
            .pipe(
                catchError(errorResponse => {
                    return of(null);
                }),
                map((response: AxiosResponse<SingleResponseModel<AdvertDto>>) => response)
            )
            .subscribe(response => {
                this.advertDto = response.data.data;
            });
    }

    isActive() {
        if (this.userDetailDto.uygunluk == 1)
            this.activeText = "Şuanda Aktif";
        else if (this.userDetailDto.uygunluk == 0)
            this.activeText = "Aktif Değil";
    }

    acceptrequest() {
        Axios.post<SingleResponseModel<InsertModel>>("advert/acceptrequest/" + this.advertDto.id + "/" + this.incomingreqsDto.id, undefined)
            .pipe(
                catchError(errorResponse => {
                    if (errorResponse.message === "Bu ilan zaten alinmis")
                        this.$toast.add({ severity: 'error', summary: advertHeaderMessage, detail: advertRequestInputErrorMessage, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                this.insertModel = response.data.data;
                if (response.data.success == true)
                    this.$toast.add({ severity: 'success', summary: advertHeaderMessage, detail: advertRequestListConfirmSuccessLoadedMessage, life: 4000 });
            });
    }

    cancelrequest() {
        Axios.post<SingleResponseModel<InsertModel>>("advert/cancelrequest/" + this.advertDto.id + "/" + this.incomingreqsDto.id, undefined)
            .pipe(
                catchError(errorResponse => {
                    if (errorResponse.message === "Bu ilana ait teklifleri red edemezsiniz")
                        this.$toast.add({ severity: 'error', summary: advertHeaderMessage, detail: advertRequestErrorRejectMessage, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                this.insertModel = response.data.data;
                if (response.data.success == true)
                    this.$toast.add({ severity: 'success', summary: advertHeaderMessage, detail: advertRequestListConfirmRejectLoadedMessage, life: 4000 });
            });
    }

    openLawyerProfileModal() {
        this.lawyerProfileDisplayModal = true;
    }

    closeLawyerProfileModal() {
        this.lawyerProfileDisplayModal = false;
    }
}

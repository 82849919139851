
import { AdvertDto } from '@/models/dto/advertDto';
import { ref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import AdvertDetail from '../advert/AdvertDetail.vue';
import AdvertConfirm from '../advert/AdvertConfirm.vue';
import { param } from 'jquery';
import LawyerProfileDetail from '@/components/lawyer/LavyerProfileDetail.vue';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { catchError, from, map, of } from 'rxjs';
import axios from 'axios';
import { Inject } from 'vue-property-decorator';
import { WhatsappService } from '@/services/whatsappService';
import { whatsappServiceMessage } from '@/utilities/messages';

@Options({
    name: 'LawyerProfileCard',
    props: ['userDetailDto'],
    components:
    {
        AdvertDetail,
        AdvertConfirm,
        LawyerProfileDetail
    }
})
export default class LawyerProfileCard extends Vue {
    baseUrl = process.env.VUE_APP_IMG_PROFILE_PATH;
    userDetailDto!: UserDetailDto
    professions: string[]
    isLoaded: boolean = false;
    activeText: string = "";

    lawyerProfileDisplayModal: boolean = false;

    @Inject('whatsappService') whatsappService!: WhatsappService;

    created() {
        if (this.userDetailDto != null || this.userDetailDto !== undefined) {
            this.professions = this.userDetailDto.uzmanlik_alani.split(",");
            this.isActive();
        }
    }

    isActive() {
        if (this.userDetailDto.uygunluk == 1)
            this.activeText = "Şuanda Aktif";
        else if (this.userDetailDto.uygunluk == 0)
            this.activeText = "Aktif Değil";
    }

    openLawyerProfileModal() {
        this.lawyerProfileDisplayModal = true;
    }

    closeLawyerProfileModal() {
        this.lawyerProfileDisplayModal = false;
    }

    sendMessage() {
        this.whatsappService.sendMessage(this.userDetailDto.telefon, whatsappServiceMessage)
    }
}

import { provide, inject } from 'vue'

const LocalStorageServiceSymbol = Symbol()

class LocalStorageService {
    saveItem(name: string, value: string) {
        localStorage.setItem(name, value)
    }
    get(name: string): string | null {
        const value = localStorage.getItem(String(name))
        return value === null ? null : value
    }
    clearItem(name: string) {
        localStorage.removeItem(name)
    }
    clear() {
        localStorage.clear()
    }
}

export { LocalStorageService }

export function provideLocalStorageService(){
  provide(LocalStorageServiceSymbol, new LocalStorageService())
}

export function useLocalStorageService(){
  return inject(LocalStorageServiceSymbol) as LocalStorageService
}
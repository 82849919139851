
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import { Emit } from 'vue-property-decorator';
import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import { catchError, of } from 'rxjs';
import { blogHeaderMessage, blogRequestDeleteMessage } from '@/utilities/messages';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { Blog } from '@/models/dto/blog';
@Options({
    name: 'AdminBlogConfirm',
    components: {
        FullText
    },
    props: ['blog'],
})

export default class AdminBlogConfirm extends Vue {
    blog!: Blog;
    responseModel: ResponseModel = null;
    visible: boolean = true;

    //error
    errorResponseModel: ResponseModel = null;

    confirmBlog() {
        Axios.delete<ResponseModel>("blog/deleteblog/" + this.blog.id)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    this.$toast.add({ severity: 'error', summary: blogHeaderMessage, detail: this.errorResponseModel.message, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.responseModel = response.data;
                    this.$toast.add({ severity: 'success', summary: blogHeaderMessage, detail: blogRequestDeleteMessage, life: 4000 });
                    this.closeDialog();
                }
            });
    }

    @Emit('close-admin-blog-confirm-modal')
    closeDialog() {
        this.$emit('close-admin-blog-confirm-modal');
        this.visible = false;
    }
}

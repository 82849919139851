
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import { ActivePageNameService } from '@/services/activePageService';
@Options({
    name: 'ComplateAdvertView',
    components: {
        FullText,
    }
})

export default class ComplateAdvertView extends Vue {
    activePageNameService: ActivePageNameService;

    getRouteName(): string {
        return (this.$route.name.toString())
    }
}

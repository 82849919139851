

import { TokenModel } from '@/models/model/tokenModel';
import Axios from '@/services/axios/axios';
import { catchError, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'FacebookAuthLogin',
    components: {
    }
})
export default class GoogleAuthLogin extends Vue {

    async handleFacebookResponse(response: any) {
        if (response.status !== 'connected') {
            return;
        }

        const { accessToken, userID } = response.authResponse;

        // perform authentication and user retrieval logic here using accessToken and userID
        // Axios.post<TokenModel>("auth/googleLogin", response)
        //     .pipe(
        //         catchError(error => {
        //             // this.$toast.add({
        //             //   severity: 'error',
        //             //   summary: googleLoginHeaderMessage,
        //             //   detail: googleLoginFailBaseMessage,
        //             //   life: 4000
        //             // });
        //             return of(null);
        //         })
        //     )
        //     .subscribe(response => {
        //         let token: string = String(response.data.access_token);
        //         if (response.data.success == true) {
        //             if (token && response.data.success) {
        //                 localStorage.setItem("token", token);
        //                 //   this.$toast.add({ severity: 'success', summary: googleLoginHeaderMessage, detail: googleLoginSuccessBaseMessage, life: 4000 });
        //                 this.$router.push({ path: '/panel', replace: true });
        //             }
        //         }
        //     });
    }
}


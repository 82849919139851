
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import LawyerSearch from '@/components/lawyer/LawyerSearch.vue';
import { ActivePageNameService } from '@/services/activePageService';
import { UserFilterModel } from '@/models/model/userFilterModel';
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { UserFilter } from '@/models/entity/userFilter';
import LawyerProfileCard from '@/components/lawyer/LawyerProfileCard.vue';
import Axios from '@/services/axios';
import { catchError, map, of } from 'rxjs';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { AxiosResponse } from 'axios';
import DataNotFound from '@/components/DataNotFound.vue';
@Options({
    name: 'LawyerSearchView',
    components: {
        FullText,
        LawyerSearch,
        LawyerProfileCard,
        DataNotFound
    }
})

export default class LawyerSearchView extends Vue {
    userFilterModels: UserFilterModel<UserDetailDto> = null;
    userFilter: UserFilter = null;
    accountConfirmation: boolean = true
    activePageNameService: ActivePageNameService;

    //lazy
    lazyUserDetailDtos: Array<UserDetailDto> = [];
    lazyPageIndex: number = 1;
    lazyPageSize: number;
    isLoading: boolean = false;
    isAdded: boolean = true;
    totalCount: number = 0;

    //error
    errorResponseModel: ResponseModel = null;
    setup() {

        // console.log("Active:" + this.activePageNameService.loadActivePage());
    }
    mounted() {
        const listElm = window;
        listElm.addEventListener('scroll', e => {
            if (Math.ceil(listElm.scrollY + listElm.innerHeight) >= document.body.scrollHeight) {
                if (this.possiblePage() && this.isAdded == true)
                    this.lazyLoadUser();
            }
        });
    }
    created() {
        let lazyUserString: string = localStorage.getItem('saveUserFilterData');
        if (localStorage.getItem('saveUserFilterData') != null) {
            let localStorageLazyAdvert: UserFilter = JSON.parse(lazyUserString)
            setTimeout((e: any) => {
                this.getFilterUsers(localStorageLazyAdvert.page);
            }, 200);
        }
    }
    getRouteName(): string {
        return (this.$route.name.toString())
    }
    search() {
        this.lazyUserDetailDtos.splice(0);
        delete this.lazyUserDetailDtos
        this.lazyUserDetailDtos = []
        this.lazyPageIndex = 1;
        this.lazyLoadUser(1);
    }
    clear() {
        this.lazyUserDetailDtos.splice(0);
        delete this.lazyUserDetailDtos
        this.lazyUserDetailDtos = []
        this.lazyPageIndex = 1;
        this.lazyLoadUser(1);
    }
    possiblePage(): boolean {
        if (this.lazyPageIndex <= this.lazyPageSize && this.totalCount > 0)
            return (true);
        return (false);
    }
    lazyLoadUser(index?: number): void {
        if (!this.isLoading) {
            this.isLoading = true;
            setTimeout((e: any) => {
                index === undefined ? this.getFilterUsers(this.lazyPageIndex) : this.getFilterUsers(1);
                this.isLoading = false;
            }, 200);
        }
    }
    lazyAddUser(response: any) {
        if (this.isAdded == true) {
            response.data.data.forEach((element: UserDetailDto) => {
                this.lazyUserDetailDtos.push(element);
            });
        }
        this.isAdded = false;
    }
    async getFilterUsers(index: number) {
        let getFilterData: any = localStorage.getItem('saveUserFilterData');
        this.userFilter = JSON.parse(getFilterData);
        this.userFilter.page = index;

        Axios.post<UserFilterModel<UserDetailDto>>('user/filteruser', this.userFilter)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    return of(null);
                }),
                map((response: AxiosResponse<UserFilterModel<UserDetailDto>>) => response)
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.userFilterModels = response.data;
                    this.totalCount = response.data.totalCount === undefined ? 0 : Number(response.data.totalCount);
                    this.lazyPageSize = Math.ceil(Number(this.totalCount) / 8);
                    if (this.possiblePage()) {
                        this.errorResponseModel = null;
                        this.lazyAddUser(response)
                        if (this.lazyPageIndex < this.lazyPageSize) {
                            this.lazyPageIndex += 1;
                            this.isAdded = true;
                        } else if (this.lazyPageSize == 1) {
                            this.isAdded = true;
                            this.lazyPageIndex += 1;
                        }
                    }
                }
            });
    }
    notFoundFilterUsers(): boolean {
        if (this.errorResponseModel != null && this.errorResponseModel.success == false && this.errorResponseModel.message === 'Aradığınız kriterlere uygun avukat bulunamadı')
            return (true);
        return (false);
    }
    advertDtoLoad() {
        console.log("loaded");
    }
}

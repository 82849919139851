
import { Options, Vue } from 'vue-class-component';
import { Emit } from 'vue-property-decorator';

@Options({
    name: 'ChipSearch',
    props: ['searchObject', 'noneDisplayObjectName', 'localStorageName', 'getFilter', 'searchx']
})
export default class ChipSearch extends Vue {
    searchObject!: any;
    noneDisplayObjectName!: string;
    localStorageName!: string;
    getFilter!: ()=>void;
    searchx!: ()=>void;

    get properties(): Array<{ key: string, value: any }> {
        return Object.entries(this.searchObject)
            .filter(([key, value]) => key.toString() !== "page" && value !== null && value !== undefined)
            .map(([key, value]) => ({ key, value }));
    }

    remove(property: any) {
        const [key, value] = Object.entries(this.searchObject)
            .find(([key, value]) => key.toString() === property.key);
        let adverFilterString = localStorage.getItem(this.localStorageName);
        let localStorageAdvertFilter = JSON.parse(adverFilterString);
        localStorageAdvertFilter[key] = "";
        localStorage.setItem(this.localStorageName, JSON.stringify(localStorageAdvertFilter))
        this.getFilter();
        this.searchx();
        const index = this.properties.indexOf(property);
        if (index > -1) {
            this.properties.splice(index, 1);
        }
    }
}

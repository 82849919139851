
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
import PanelHomeCard from '@/components/panel/PanelHomeCard.vue';
import { ActivePageNameService } from '@/services/activePageService';
import AdvertCard from '@/components/advert/AdvertCard.vue';
import PhoneSetting from '@/components/settings/PhoneSetting.vue';
import PasswordSetting from '@/components/settings/PasswordSetting.vue';
import ProfilePhotoSetting from '@/components/settings/ProfilePhotoSetting.vue';
import AreasOfExpertisesSetting from '@/components/settings/AreasOfExpertisesSetting.vue';
import ProfileDescriptionSetting from '@/components/settings/ProfileDescriptionSetting.vue';
@Options({
    name: 'PanelSettingsView',
    components: {
        FullText,
        PanelHomeCard,
        AdvertCard,
        PhoneSetting,
        PasswordSetting,
        ProfilePhotoSetting,
        AreasOfExpertisesSetting,
        ProfileDescriptionSetting
    }
})
export default class PanelSettingsView extends Vue {
    activePageNameService: ActivePageNameService;

    getRouteName(): string {
        return (this.$route.name.toString())
    }
}


import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import BlogCard from '@/components/BlogCard.vue'
import { Blog } from '@/models/dto/blog'
import { map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { BlogFilterModel } from '@/models/model/blogFiletModel';
import { BlogFilter } from '@/models/entity/blogFilter';
import Axios from '@/services/axios';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { blogDefaultMessage, blogHeaderMessage } from '@/utilities/messages';
@Options({
    name: 'BlogView',
    components: {
        FullText,
        BlogCard
    }
})

export default class BlogView extends Vue {
    blogFilterModel: BlogFilterModel<Blog> = null;
    blogFilter: BlogFilter
    tag: string = '';
    page: number = 1;
    first: number = 0

    //error
    errorResponseModel: ResponseModel = null;

    created() {
        this.first = 1;
        this.getBlogsFilter(this.page, this.tag);
    }

    tagFilter(tag: string) {
        this.tag = tag;
        this.first = this.page;
        this.getBlogsFilter(1, this.tag);
    }

    tagFilterDefault() {
        this.tag = ""
        this.page = 1;
        this.first = 1;
        this.getBlogsFilter(1, "");
    }

    onPage(event: any) {
        this.page = event.page;
        this.getBlogsFilter(event.page + 1, this.tag)
    }
    getBlogsFilter(pageIndex: number = 1, tag: string = "") {
        this.blogFilter =
        {
            page: pageIndex,
            tag: tag
        }
        Axios.post<BlogFilterModel<Blog>>("blog/filter", this.blogFilter)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    if (error.success == false) {
                        this.$toast.add({ severity: 'error', summary: blogHeaderMessage, detail: blogDefaultMessage, life: 4000 });
                    }
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success) {
                    this.blogFilterModel = response.data;
                }
            });
    }
}


import { MenuItem } from 'primevue/menuitem';
import { Ref, ref, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { Options, setup, Vue } from 'vue-class-component';
import { Component, Watch, Prop } from 'vue-property-decorator';
import Menu from 'primevue/menu';
import { ReadMe } from '@/models/static/ReadMe';
import { RegisterModel } from '@/models/model/registerModel';
import { StepRegisterModel, StepRegisterValidateModel } from '@/models/model/stepRegisterValidateModel';

@Options({
    name: 'StepRegisterView',
    components: {
    }
})

export default class StepRegisterView extends Vue {
    registerModel!: RegisterModel;
    currentStepIndex: Ref<number> = ref(0)
    readMe: ReadMe[] = [];
    stepRegisterValidate: StepRegisterModel = null;
    items: MenuItem = ref<MenuItem>([
        {
            label: 'Giriş Bilgileri',
            to: '/kayit/giris-bilgileri',
        },
        {
            label: 'Avukat Bilgileri',
            to: '/kayit/avukat-bilgileri',
        },
        {
            label: 'Profil Bilgileri',
            to: '/kayit/profil-bilgileri',
        }
    ])
    created(): void {
        watch(
            () => this.$route.fullPath,
            () => {
                if (this.$route.fullPath === '/kayit') {
                    this.$router.push('/kayit/giris-bilgileri')
                }
            }
        )
        onMounted(() => {
            if (this.$route.fullPath === '/kayit') {
                this.$router.push('/kayit/giris-bilgileri')
            }
            this.currentStepIndex = this.items.findIndex(
                (item: MenuItem) => item.to === this.$route.fullPath
            )
            let readMeLocalStorage = localStorage.getItem("readMe");
            this.readMe = JSON.parse(readMeLocalStorage)
            let stepRegisterValidate = localStorage.getItem("stepRegisterValidate");
            this.stepRegisterValidate = JSON.parse(stepRegisterValidate)
        })
        this.registerFormDefault();
        if (localStorage.getItem("stepRegisterValidate") !== null || localStorage.getItem("stepRegisterValidate") !== undefined)
            this.stepRegisterValidateDefault();
    }

    stepper(direction: 'next' | 'prev'): void {
        let currentStepIndex = this.items.findIndex(
            (item: any) => item.to === this.$route.fullPath
        )
        if (direction === 'next') currentStepIndex++
        if (direction === 'prev') currentStepIndex--
        let readMeLocalStorage = localStorage.getItem("readMe");
        this.readMe = JSON.parse(readMeLocalStorage)
        console.log("this.nextStepValidate(this.stepRegisterValidate.pageIndex) " + this.nextStepValidate(this.stepRegisterValidate.pageIndex));

        if (this.readMe[0].confirm == true && this.readMe[1].confirm == true
            && this.nextStepValidate(this.stepRegisterValidate.pageIndex) == true)
            this.$router.push(this.items[currentStepIndex]?.to)
    }
    provide() {
        return {
            stepper: this.stepper
        }
    }

    registerFormDefault() {
        this.registerModel =
        {
            ad_soyad: null,
            sifre: null,
            mail: null,
            telefon: null,
            dogum_tarihi: null,
            baro_kayit_tarihi: null,
            baro_sicil_no: null,
            kayitli_oldugu_baro: null,
            uzmanlik_alani: null,
            profil_aciklamasi: null,
            profile_image: null,
        }
        localStorage.setItem("registerForm", JSON.stringify(this.registerModel));
    }

    stepRegisterValidateDefault() {
        let stepRegister: StepRegisterModel = {
            pageIndex: 1,
            stepRegisterValidateModel: [{
                index: 1,
                validate: false,
            },
            {
                index: 2,
                validate: false,
            },
            {
                index: 3,
                validate: false,
            },]
        }
        localStorage.setItem("stepRegisterValidate", JSON.stringify(stepRegister));
    }

    nextStepValidate(pageIndex: number): boolean {
        let result: boolean;
        let stepRegisterValidate = localStorage.getItem("stepRegisterValidate");
        this.stepRegisterValidate = JSON.parse(stepRegisterValidate)
        this.stepRegisterValidate.stepRegisterValidateModel.forEach(element => {
            if (pageIndex == element.index) {
                result = element.validate
            }
        });
        return (result);
    }
}

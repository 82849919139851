import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18e7476b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container-fuild"
}
const _hoisted_2 = {
  key: 1,
  class: "container-fuild"
}
const _hoisted_3 = {
  key: 0,
  class: "row m-0"
}
const _hoisted_4 = { class: "col-md-2 d-block sidebar h-100 p-0 bg-white" }
const _hoisted_5 = { class: "sidebar-sticky" }
const _hoisted_6 = { class: "col-md-10 p-0" }
const _hoisted_7 = {
  key: 1,
  class: "row m-0"
}
const _hoisted_8 = { class: "col-md-12 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_panel_header = _resolveComponent("panel-header")!
  const _component_panel_side_bar = _resolveComponent("panel-side-bar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.pageNotFoundVisibility)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_panel_header)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.pageNotFoundVisibility)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.panelSideBarVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("nav", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_panel_side_bar)
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_router_view, {
                    route: _ctx.currentRoute,
                    style: {"background":"#F3F9FF !important","height":"auto"}
                  }, null, 8, ["route"])
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.panelSideBarVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_router_view, {
                    route: _ctx.currentRoute,
                    style: {"background":"#F3F9FF !important","height":"auto"}
                  }, null, 8, ["route"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
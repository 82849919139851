
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import { Emit } from 'vue-property-decorator';
import { ResponseModel } from '@/models/responseModel/responseModel';
import Axios from '@/services/axios/axios';
import { catchError, of } from 'rxjs';
import { userHeaderMessage, userRequestListConfirmRejectLoadedMessage, userRequestListConfirmSuccessLoadedMessage } from '@/utilities/messages';
import { UserDetailDto } from '@/models/dto/userDetailDto';
@Options({
    name: 'AdminLawyerConfirm',
    components: {
        FullText
    },
    props: ['userDetailDto', 'isConfirmLawyer'],
})

export default class AdminLawyerConfirm extends Vue {
    userDetailDto!: UserDetailDto
    responseModel: ResponseModel = null;
    visible: boolean = true;
    isConfirmLawyer!: boolean;

    //error
    errorResponseModel: ResponseModel = null;
    errorAdvertMessage: string = null;

    confirmUser() {
        let userConfirmDto =
        {
            user_id : this.userDetailDto.id,
            onay : Number(this.isConfirmLawyer)
        }
        Axios.post<ResponseModel>("admin/confirmuser", userConfirmDto)
            .pipe(
                catchError(error => {
                    this.errorResponseModel = error;
                    this.$toast.add({ severity: 'error', summary: userHeaderMessage, detail: this.errorResponseModel.message, life: 4000 });
                    return of(null);
                })
            )
            .subscribe(response => {
                if (response.data.success == true) {
                    this.responseModel = response.data;
                    if (this.isConfirmLawyer)
                        this.$toast.add({ severity: 'success', summary: userHeaderMessage, detail: userRequestListConfirmSuccessLoadedMessage, life: 4000 });
                    else
                        this.$toast.add({ severity: 'success', summary: userHeaderMessage, detail: userRequestListConfirmRejectLoadedMessage, life: 4000 });
                    this.closeDialog();
                }
            });
    }

    @Emit('close-admin-lawyer-confirm-modal')
    closeDialog() {
        this.$emit('close-admin-lawyer-confirm-modal');
        this.visible = false;
    }
}


import { Ref, ref } from 'vue';
import { Options, setup, Vue } from 'vue-class-component';
import { Component, Watch, Prop } from 'vue-property-decorator';
import FullText from '@/components/FullText.vue'
import { catchError, of } from 'rxjs';
import Axios from '@/services/axios';
import { ResponseModel } from '@/models/responseModel/responseModel';
import { isValidEmail } from '@/utilities/isValidEmail';
import { passHeaderMessage, passRequestErrorMessage, passRequestSuccessMessage } from '@/utilities/messages';
@Options({
  name: 'PasswordForgot',
  components: {
    FullText
  }
})
export default class PasswordForgot extends Vue {
  email: string = null;
  responseModel: ResponseModel = null;
  errorResponseModel: ResponseModel = null;

  //validation
  emailError: string = '';

  //disabled
  sendDisabled: boolean = true;

  forgotPassword() {
    let passwordForgot: any = {
      mail: this.email
    }
    Axios.post<ResponseModel>("auth/forgotpassword", passwordForgot)
      .pipe(
        catchError(error => {
          this.errorResponseModel = error;
          if (this.errorResponseModel.success == false)
            this.$toast.add({ severity: 'error', summary: passHeaderMessage, detail: passRequestErrorMessage, life: 4000 });
          return of();
        })
      )
      .subscribe(response => {
        if (response.data.success == true) {
          this.responseModel = response.data;
          if (response.data.message === 'E-posta gönderildi')
            this.$toast.add({ severity: 'success', summary: passHeaderMessage, detail: passRequestSuccessMessage, life: 4000 });
          this.responseModel.success = false;
          this.sendDisabled = false;
        }
      });
    let intervalId = setInterval(() => {
      if (this.sendDisabled == false) {
        this.resetForm();
        this.$router.push({ name: 'home' });
        clearInterval(intervalId);
      }
    }, 4000);
  }

  onSubmit() {
    if (!this.validateForm())
      return;
    this.forgotPassword();
  }
  validateForm(): boolean {
    let isValid = true;

    if (!this.email) {
      this.emailError = 'Eposta alanı boş!';
      isValid = false;
    } else if (this.email.length < 6) {
      this.emailError = 'Eposta 5 karakerden az!';
      isValid = false;
    } else if (!isValidEmail(this.email)) {
      this.emailError = 'Eposta geçersiz!';
      isValid = false;
    } else {
      this.emailError = '';
    }
    return (isValid);
  }
  resetForm() {
    let formRef = this.$refs.form as HTMLFormElement;
    if (formRef) {
      formRef.reset();
      this.email = null;
    }
  }
}

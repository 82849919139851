import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-lg" }
const _hoisted_2 = { class: "container-lg" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "container-lg" }
const _hoisted_6 = {
  key: 0,
  class: "row"
}
const _hoisted_7 = { class: "mb-5 col-md-12 text-center" }
const _hoisted_8 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_9 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_text = _resolveComponent("full-text")!
  const _component_advert_card = _resolveComponent("advert-card")!
  const _component_DeferredContent = _resolveComponent("DeferredContent")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_data_not_found = _resolveComponent("data-not-found")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_full_text, {
        title: _ctx.getRouteName(),
        titleSize: 24
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.notFoundFilterAdverts() == false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_DeferredContent, { onLoad: _ctx.advertDtoLoad }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lazyAdvertDtos, (advert) => {
                    return (_openBlock(), _createBlock(_component_advert_card, {
                      key: advert.id,
                      advertDto: advert
                    }, null, 8, ["advertDto"]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["onLoad"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.possiblePage())
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (!_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("i", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.lazyLoadAdvert())),
                      class: "eva eva-loader-outline m-5",
                      style: {"font-size":"2rem"}
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_ProgressSpinner)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.notFoundFilterAdverts() == true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_data_not_found, { infoText: "Malesef, başvurduğun ilan\n                                            bulamadık" })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
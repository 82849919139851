
import { Options, Vue } from 'vue-class-component'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NotFoundView from '@/views/NotFoundView.vue'
@Options({
    name: 'Main',
    components: {
        Header,
        Footer,
        NotFoundView
    }
})

export default class Main extends Vue {

    routeName!: string;
    pageNotFoundVisibility: boolean = false;

    get currentRoute() {
        this.getRouteName();
        return this.$route;
    }

    getRouteName(): void {
        console.log(this.$route.name);
        if (this.$route.name === "bad-not-found" || this.$route.name === "not-found")
            this.pageNotFoundVisibility = true
        else
            this.pageNotFoundVisibility = false
    }
}


import { Link } from '@/models/static/Link';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'Accordion',
    props: ['bgImage', 'bgColor', 'subTitle', 'endText', 'link']
})

export default class HomeBlogCard extends Vue {
    bgImage: string
    bgColor: string
    subTitle: string
    endText: string
    link: Link
}

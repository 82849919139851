
import { Ref, ref } from 'vue';
import { Options, setup, Vue } from 'vue-class-component';
import { Component, Watch, Prop } from 'vue-property-decorator';
import FullText from '@/components/FullText.vue'
import { RegisterModel } from '@/models/model/registerModel';
import { LawBar } from '@/models/static/LawBar';
import LawBars from '@/data/static/LawBars.json';
import { StepRegisterModel } from '@/models/model/stepRegisterValidateModel';
@Options({
  name: 'LawyerInfoRegisterView',
  components: {
    FullText
  }
})
export default class LawyerInfoRegisterView extends Vue {
  registerModel: RegisterModel = null;
  nameSurname: string = null;
  phone: string = null;
  birtDay: string = null;
  baroRegisterDate: Date = null;
  baroRegisterNo: string = null;
  lawBars: LawBar[] = LawBars;
  selectedLawBar: LawBar = null;
  stepRegisterValidate: StepRegisterModel = null;
  categories: any = [
    { name: "KVKK ve Üyelik Sözleşmesini okudum, kabul ediyorum.*", key: "A" },
    { name: "Açık Rıza Metnini okudum, kabul ediyorum.*", key: "M" },
  ]

  nameSurnameError: string = "";
  phoneError: string = "";
  birtDayError: string = "";
  baroRegisterDateError: string = "";
  baroRegisterNoError: string = "";
  lawBarError: string = "";

  baroRegisterMaxDate: Date = new Date();
  baroRegisterMinDate: Date = new Date('01-01-1900');
  birtDayDate: Date = null;
  mounted() {
  }

  created() {
    let registerModelLocalStorage = localStorage.getItem("registerForm");
    this.registerModel = JSON.parse(registerModelLocalStorage);
    let stepRegisterValidateLocalStorage = localStorage.getItem("stepRegisterValidate");
    if (stepRegisterValidateLocalStorage != null || stepRegisterValidateLocalStorage != undefined) {
      this.stepRegisterValidate = JSON.parse(stepRegisterValidateLocalStorage);
      this.stepRegisterValidate.pageIndex = 2;
      localStorage.setItem("stepRegisterValidate", JSON.stringify(this.stepRegisterValidate));
    }
    this.getRegisterForm();
  }

  registerFormChange() {
    const [day, month, year] = this.birtDay.split("-");
    let birtDayDate = new Date(`${year}-${month}-${day}`);
    this.birtDayDate = birtDayDate;
    let getRegisterFormLocalStorage = localStorage.getItem("registerForm");
    let getRegisterForm: RegisterModel = JSON.parse(getRegisterFormLocalStorage);
    this.registerModel =
    {
      ad_soyad: this.nameSurname,
      sifre: getRegisterForm.sifre,
      mail: getRegisterForm.mail,
      telefon: this.phone,
      dogum_tarihi: `${year}-${month}-${day}`,
      baro_kayit_tarihi: `${this.baroRegisterDate.getFullYear()}-${('0' + (this.baroRegisterDate.getMonth() + 1)).slice(-2)}-${('0' + this.baroRegisterDate.getDate()).slice(-2)}`,
      baro_sicil_no: String(this.baroRegisterNo),
      kayitli_oldugu_baro: this.selectedLawBar.text,
      uzmanlik_alani: getRegisterForm.uzmanlik_alani,
      profil_aciklamasi: getRegisterForm.profil_aciklamasi,
      profile_image: getRegisterForm.profile_image
    }
    localStorage.setItem("registerForm", JSON.stringify(this.registerModel));
  }

  getRegisterForm() {
    let getRegisterFormLocalStorage = localStorage.getItem("registerForm");
    let getRegisterForm: RegisterModel = JSON.parse(getRegisterFormLocalStorage);

    if (!getRegisterFormLocalStorage)
      return;
    this.nameSurname = getRegisterForm.ad_soyad;
    this.phone = getRegisterForm.telefon;
    this.birtDay = getRegisterForm.dogum_tarihi == null ? null : getRegisterForm.dogum_tarihi;
    this.baroRegisterDate = getRegisterForm.baro_kayit_tarihi == null ? null : new Date(getRegisterForm.baro_kayit_tarihi);
    this.baroRegisterNo = getRegisterForm.baro_sicil_no;
    this.selectedLawBar = this.lawBars.find(x => x.text === getRegisterForm.kayitli_oldugu_baro);
  }


  onSubmit() {
    this.getStepValidate();
    if (!this.validateForm()) {
      this.saveStepValidate(false);
      return;
    }
    else {
      this.saveStepValidate(true);
      this.registerFormChange();
      this.$emit('next-step');
      return;
    }
  }

  validateForm(): boolean {
    let isValid = true;

    if (!this.nameSurname) {
      this.nameSurnameError = 'Ad Soyad alanı boş!';
      isValid = false;
    } else {
      this.nameSurnameError = '';
    }

    if (!this.phone) {
      this.phoneError = 'Telefon alanı boş!';
      isValid = false;
    } else {
      this.phoneError = '';
    }

    const [day, month, year] = this.birtDay.split("-");
    const birtDayDate = new Date(Number(year), Number(month) - 1, Number(day));

    if (!this.birtDay) {
      this.birtDayError = 'Doğum Tarihi alanı boş!';
      isValid = false;
    } else if (birtDayDate < new Date('1900-01-01') || birtDayDate > new Date() ||  Number(month) > 12 || Number(day) > 31) {
      this.birtDayError = 'Doğum Tarihi alanı yanlış!';
      isValid = false;
    } else {
      this.birtDayError = '';
    }

    if (!this.baroRegisterDate) {
      this.baroRegisterDateError = 'Baro Kayıt alanı boş!';
      isValid = false;
    } else {
      this.baroRegisterDateError = '';
    }

    if (!this.baroRegisterNo) {
      this.baroRegisterNoError = 'Baro Sicil alanı boş!';
      isValid = false;
    }
    else {
      this.baroRegisterNoError = '';
    }

    if (!this.selectedLawBar) {
      this.lawBarError = 'Baro Ad alanı boş!';
      isValid = false;
    } else {
      this.lawBarError = '';
    }
    return (isValid);
  }

  saveStepValidate(validate: boolean) {
    this.stepRegisterValidate.stepRegisterValidateModel[1].validate = validate;
    localStorage.setItem("stepRegisterValidate", JSON.stringify(this.stepRegisterValidate));
  }

  getStepValidate() {
    let stepRegisterValidate = localStorage.getItem("stepRegisterValidate");
    this.stepRegisterValidate = JSON.parse(stepRegisterValidate);
  }
}

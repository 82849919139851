
import { UserDetailDto } from '@/models/dto/userDetailDto';
import { Experience } from '@/models/static/Experience';
import { WhatsappService } from '@/services/whatsappService';
import { getUrlImageFromAxios } from '@/utilities/getImg';
import { whatsappServiceMessage } from '@/utilities/messages';
import axios from 'axios';
import { catchError, from, map, of } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import Experiences from '@/data/static/Experiences.json';
@Options({
    name: 'LawyerProfileDetail',
    props: ['userDetailDto'],
    components: {
    }
})

export default class LawyerProfileDetail extends Vue {
    userDetailDto!: UserDetailDto
    professions: string[]
    isLoaded: boolean = false;
    activeStyle: string = "";
    experiences:Experience[] = Experiences;
    baseUrl:string = process.env.VUE_APP_IMG_PROFILE_PATH;

    @Inject('whatsappService') whatsappService!: WhatsappService;

    created() {
        if (this.userDetailDto != null) {
            this.professions = this.userDetailDto.uzmanlik_alani.split(",");
            this.isActive();
        }
    }

    experienceYear(baroRegistrationDate: Date):string {
        const year1 = new Date(baroRegistrationDate);
        const now = new Date();

        const difference = year1.getFullYear() - now.getFullYear();
        const year = Math.abs(difference);

        let result:string = null;
        this.experiences.forEach(res=>
        {
            if (Number(res.maxYear) >= year && Number(res.minYear) <= year)
                result = res.text;
        })
        return (result);
    }

    isActive() {
        if (this.userDetailDto.uygunluk == 1)
            this.activeStyle = ":5px solid #1CBC09;";
        else if (this.userDetailDto.uygunluk == 0)
            this.activeStyle = ":5px solid #FC5555;";
    }

    sendMessage() {
        this.whatsappService.sendMessage(this.userDetailDto.telefon, whatsappServiceMessage)
    }
}


import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue';
@Options({
    name: 'DataNotFound',
    components: {
        FullText,
    },
    props: ['infoText']
})

export default class DataNotFound extends Vue {
    infoText!: string;
}


import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
@Options({
    name: 'MetaverseLawFirmsView',
    components: {
        FullText
    }
})

export default class MetaverseLawFirmsView extends Vue {
}


//
export const loginHeaderMessage = "Giriş";
export const loginFailBaseMessage = "Giriş Başarısız";
export const loginSuccessBaseMessage = "Giriş Başarılı";
export const loginErrorMessage = "Lütfen kimlik bilgilerinizi kontrol edin";
export const loginInputErrorMessage = "Lütfen girişinizi kontrol edin";

//register
export const registerHeaderMessage = "Kayıt";
export const registerFailBaseMessage = "Kayıt Başarısız";
export const registerSuccessBaseMessage = "Kayıt Başarılı";
export const registerErrorMessage = "Lütfen tüm girdileri sağlayın";
export const registerEmailMessage = "Böyle bir kullanıcı mevcut";
export const registerEmailFormatErrorMessage = "Email formatı yanlış";
export const registerImageFormatErrorMessage = "Resim formatı yanlış";

//advert request
export const advertHeaderMessage = "İlan";
export const advertRequestMyErrorMessage = "Kendi İlanına başvurma";
export const advertRequestMaxErrorMessage = "İlan maksimum kullanıcı sayısına ulaştı";
export const advertRequestInputErrorMessage = "Bu ilan zaten alınmış";
export const advertRequestNotConfirmErrorMessage = "Bu ilan daha onaylanmadı";
export const advertRequestFailedErrorMessage = "Bu ilana daha önce başvuru yaptınız";
export const advertRequestNotFoundMessage = "Böyle bir ilan yok";
export const advertRequestListNotFoundMessage = "İlanlar bulunamadı";
export const advertRequestListLoadedMessage = "İlanlar Yükleniyor...";
export const advertRequestListConfirmSuccessLoadedMessage = "İlan Onaylandı";
export const advertRequestListConfirmRejectLoadedMessage = "İlan Reddedildi";
export const advertRequestSuccessMessage = "İlana Başvuru yapıldı";
export const advertRequestSuccessAddMessage = "İlan Kayıt Başarılı";
export const advertRequestErrorAddMessage = "İlan Kayıt Başarısız";
export const advertRequestErrorRejectMessage = "Bu ilana ait teklifleri red edemezsiniz";


//user request
export const userHeaderMessage = "Kullanıcı";
export const userRequestListNotFoundMessage = "Avukatlar bulunamadı";
export const userRequestListLoadedMessage = "Avukatlar Yükleniyor...";
export const userRequestListConfirmSuccessLoadedMessage = "Avukat Onaylandı";
export const userRequestListConfirmRejectLoadedMessage = "Avukat Reddedildi";


//blog request
export const blogHeaderMessage = "Blog";
export const blogDefaultMessage = "Blog Hata";
export const blogRequestListNotFoundMessage = "Blog bulunamadı";
export const blogRequestListLoadedMessage = "Blog Yükleniyor...";
export const blogRequestDeleteMessage = "Blog Silindi";
export const blogRequestSuccessAddMessage = "Blog Eklendi";
export const blogRequestErrorAddMessage = "Blog Eklenemedi";
export const blogRequestSuccessUpdateMessage = "Blog Güncellendi";
export const blogRequestErrorUpdateMessage = "Blog Güncellenemedi";

//profile
export const profileHeaderMessage = "Profil";
export const profileRequestSuccessImageMessage = "Profil Resim Başarılı";
export const profileRequestSuccessInfoMessage = "Profil Bilgi Başarılı";
export const profileRequestSuccessDescMessage = "Profil Açıklama Başarılı";
export const profileRequestSuccessProfessionMessage = "Profil Uzmanlık Başarılı";
export const profileRequestSuccessPassMessage = "Profil Şifre Başarılı";
export const profileRequestErrorPassMessage = "Profil Şifre Başarısız";

//whatsapp
export const whatsappServiceMessage = "Merhaba ben avukatweb.com sayfasından sizlere ulaşıyorum.";

//exit
export const exitHeaderMessage = "Çıkış";
export const exitRequestSuccessMessage = "Çıkış Başarısız";
export const exitRequestErrorMessage = "Başarılı İşlem";

//contact
export const contactHeaderMessage = "İletişim";
export const contactRequestSuccessMessage = "E-posta gönderildi";
export const contactRequestErrorMessage = "Hata oluştu";


//password
export const passHeaderMessage = "Şifre";
export const passRequestSuccessMessage = "E-posta gönderildi";
export const passRequestErrorMessage = "E-posta gönderilemedi";
export const passRequestUpdateSuccessMessage = "Şifre Güncelleme İşlemi Başarılı";
export const passRequestUpdateErrorMessage = "Şifre Güncelleme İşlemi Başarısız";


//googleLogin
export const googleLoginHeaderMessage = "Google Giriş";
export const googleLoginFailBaseMessage = "Giriş Yapılamadı. Bu mail adresiyle kayıt yapılmamış";
export const googleLoginSuccessBaseMessage = "Giriş Yapıldı";
export const googleLoginErrorAPIMessage = "Google Giriş Hata";

import { Ref, ref } from 'vue';
import { Options, setup, Vue } from 'vue-class-component';
import { Component, Watch, Prop, Inject } from 'vue-property-decorator';
import FullText from '@/components/FullText.vue'
import { ReadMe } from '@/models/static/ReadMe';
import { RegisterModel } from '@/models/model/registerModel';
import { isValidEmail } from '@/utilities/isValidEmail';
import { StepRegisterModel } from '@/models/model/stepRegisterValidateModel';

@Options({
  name: 'BaseRegisterView',
  components: {
    FullText
  }
})

export default class BaseRegisterView extends Vue {
  registerModel!: RegisterModel;
  email: string = null;
  password: string = null;
  selectedCategories: Ref<boolean> = ref(false);
  selectedCategories1: Ref<boolean> = ref(false);
  emailError: string = "";
  passwordError: string = "";
  selectedCategoriesError: string = "";
  selectedCategories1Error: string = "";
  getReadMe: ReadMe[] = [];
  stepRegisterValidate: StepRegisterModel = null;
  categories = [
    {
      id: 1,
      name1: "KVKK",
      name2: " ve ",
      name3: "Üyelik Sözleşmesini okudum",
      name4: ", kabul ediyorum.*",
      key: "K",
      link1: "/avukatweb/kvkk",
      link2: "/avukatweb/kullaniciveuyeliksozlesmesi",
      model: this.selectedCategories,
    },
    {
      id: 2,
      name1: "Açık Rıza Metnini okudum",
      name2: ", kabul ediyorum.*",
      key: "A",
      link1: "/avukatweb/acikrizametni",
      model: this.selectedCategories1,
    },
  ]

  created() {
    let readMeLocalStorage = localStorage.getItem("readMe");
    let stepRegisterValidateLocalStorage = localStorage.getItem("stepRegisterValidate");

    if (readMeLocalStorage == null || readMeLocalStorage === undefined)
      this.categoryDefault();
    else if (readMeLocalStorage != null || readMeLocalStorage !== undefined) {
      this.getReadMe = JSON.parse(readMeLocalStorage);
      this.selectedCategories = this.getReadMe[0].confirm;
      this.selectedCategories1 = this.getReadMe[1].confirm;
    }

    if (stepRegisterValidateLocalStorage != null || stepRegisterValidateLocalStorage !== undefined) {
      this.stepRegisterValidate = JSON.parse(stepRegisterValidateLocalStorage);
      this.stepRegisterValidate.pageIndex = 1;
      localStorage.setItem("stepRegisterValidate", JSON.stringify(this.stepRegisterValidate));
    }

    this.getRegisterForm();
  }

  categoryChange(_index: any) {
    let readMe: ReadMe[] =
      [
        {
          name: "KVKK",
          confirm: Boolean(this.selectedCategories),
          index: Number(1),
          selectIndex: _index
        },
        {
          name: "Açık Rıza Metni",
          confirm: Boolean(this.selectedCategories1),
          index: Number(2),
          selectIndex: _index
        }
      ]
    localStorage.setItem("readMe", JSON.stringify(readMe));
  }

  categoryDefault() {
    let readMe: ReadMe[] =
      [
        {
          name: "KVKK",
          confirm: Boolean(false),
          index: Number(1),
          selectIndex: 0
        },
        {
          name: "Açık Rıza Metni",
          confirm: Boolean(false),
          index: Number(2),
          selectIndex: 0
        }
      ]
    localStorage.setItem("readMe", JSON.stringify(readMe));
  }

  categoryLoad(_index: any) {
    this.categoryChange(_index);
  }

  registerFormChange() {

    let getRegisterFormLocalStorage = localStorage.getItem("registerForm");
    let getRegisterForm: RegisterModel = JSON.parse(getRegisterFormLocalStorage);
    this.registerModel =
    {
      ad_soyad: getRegisterForm.ad_soyad,
      sifre: this.password.toString(),
      mail: this.email,
      telefon: getRegisterForm.telefon,
      dogum_tarihi: getRegisterForm.dogum_tarihi,
      baro_kayit_tarihi: getRegisterForm.baro_kayit_tarihi,
      baro_sicil_no: getRegisterForm.baro_sicil_no,
      kayitli_oldugu_baro: getRegisterForm.kayitli_oldugu_baro,
      uzmanlik_alani: getRegisterForm.uzmanlik_alani,
      profil_aciklamasi: getRegisterForm.profil_aciklamasi,
      profile_image: getRegisterForm.profile_image,
    }
    localStorage.setItem("registerForm", JSON.stringify(this.registerModel));
  }

  getRegisterForm() {
    let getRegisterFormLocalStorage = localStorage.getItem("registerForm");
    let getRegisterForm: RegisterModel = JSON.parse(getRegisterFormLocalStorage);

    if (!getRegisterFormLocalStorage)
      return;
    this.password = getRegisterForm.sifre;
    this.email = getRegisterForm.mail;
  }

  onSubmit() {
    this.getStepValidate();
    if (!this.validateForm()) {
      this.saveStepValidate(false);
      return;
    }
    else {
      this.saveStepValidate(true);
      this.registerFormChange();
      this.$emit('next-step');
    }
  }

  validateForm(): boolean {
    let isValid = true;

    if (!this.email) {
      this.emailError = 'Eposta alanı boş!';
      isValid = false;
    } else if (!isValidEmail(this.email)) {
      this.emailError = 'Eposta geçersiz!';
      isValid = false;
    } else {
      this.emailError = '';
    }

    if (!this.password) {
      this.passwordError = 'Şifre alanı boş!';
      isValid = false;
    } else if (this.password.length < 7) {
      this.passwordError = 'Şifre 7 karakterden büyük olmalı!';
      isValid = false;
    } else {
      this.passwordError = '';
    }

    if (Boolean(this.selectedCategories) == false)
      this.selectedCategoriesError = "***Lütfen KVKK alanını doldurun.";
    else {
      this.selectedCategoriesError = "";
    }
    if (Boolean(this.selectedCategories1) == false)
      this.selectedCategories1Error = "***Lütfen Açık Rıza Metnini doldurun.";
    else {
      this.selectedCategories1Error = "";
    }

    return (isValid);
  }

  saveStepValidate(validate: boolean) {
    this.getStepValidate();
    this.stepRegisterValidate.stepRegisterValidateModel[0].validate = validate;
    localStorage.setItem("stepRegisterValidate", JSON.stringify(this.stepRegisterValidate));
  }

  getStepValidate() {
    let stepRegisterValidate = localStorage.getItem("stepRegisterValidate");
    this.stepRegisterValidate = JSON.parse(stepRegisterValidate);
  }

  routeLink(url:string):void
  {
    this.$router.push({path:url})
  }
}

import { AuthService } from '@/services/authService';
import { jwtControllerService } from '@/services/jwtControllerService';
import { inject } from 'vue';

export default function loginGuard(to: any, from: any, next: any) {
  let token: string = localStorage.getItem('token');
  let role: string = "";
  if (token) {
    let decoded: any = jwtControllerService.decodeToken(token)
    let rolex = Object.keys(decoded).filter(x => x.startsWith("role"))[0];
    role = decoded[rolex];
    // console.log("role :", decoded[rolex]);
  }
  if (token !== null && role === 'user') {
    next()
  } else if (token === null) {
    next({ name: 'home' })
  }
}
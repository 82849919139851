
import { Options, Vue } from 'vue-class-component';
import FullText from '@/components/FullText.vue'
import LightingTextData from '@/data/static/LightingTextData.json'
import { FullTextModel } from '@/models/static/FullTextModel'
@Options({
    name: 'LightingTextView',
    components: {
        FullText
    },
    methods: {
        onload() {
            this.pageOnLoad();
        },
        mounted() {
            this.pageOnLoad();
        }
    },
})

export default class LightingTextView extends Vue {
    fullTextModels: FullTextModel[] = LightingTextData;
    pageOnLoad(): void {
        this.fullTextModels = LightingTextData;
    }
}

